import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  fixPricing: [{}],
};

export const fixPriceSlice = createSlice({
  name: 'fixpricing',
  initialState,
  reducers: {
    fixPricePush: (state, action) => {
      state.fixPricing = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { fixPricePush } = fixPriceSlice.actions;

export default fixPriceSlice.reducer;
