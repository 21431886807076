import { forwardRef } from "react";
import MuiAlert from '@mui/material/Alert'
import { Snackbar } from "@mui/material";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = ({ message, severity, open, setOpen }) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setOpen(false);
    }
    

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                { message }
            </Alert>
        </Snackbar> 
    )
}

export default SnackBar