import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { addData, deleteData, editData, getData } from '../api/data';

export const fetchWorkers = createAsyncThunk('workers/fetch', async () => {
  try {
    const data = await getData('user/all');

    return data.sort((a, b) =>
      a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1
    );
  } catch (e) {
    throw new Error(e.message);
  }
});

export const addWorker = createAsyncThunk('user/add', async (user) => {
  try {
    const data = await addData('user/new', user);
    return data;
  } catch (err) {
    throw err;
  }
});

export const editWorker = createAsyncThunk('user/edit', async (user) => {
  try {
    console.log(user);
    const data = await editData('user/edit', user);
    return data;
  } catch (err) {
    throw err;
  }
});

export const deleteUser = createAsyncThunk('user/delete', async (userId) => {
  try {
    const data = await deleteData('user/delete', userId);

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
});

const initialState = {
  loading: 'pending',
  data: [],
};

const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    addUser: (state, { payload }) => {
      return [...state, payload];
    },
  },
  extraReducers: {
    [fetchWorkers.pending]: (state) => {
      state.loading = 'pending';
    },
    [fetchWorkers.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = 'idle';
    },
    [fetchWorkers.rejected]: () => {
      console.error('fetch workers rejected!');
    },
    [addWorker.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload];
    },
    [addWorker.rejected]: (state) => {
      console.log('error in adding a worker');
    },
    [editWorker.fulfilled]: (state, { payload }) => {
      const newState = [...current(state.data)];

      const workerIndex = current(state.data).findIndex(
        (project) => project._id === payload._id
      );
      newState[workerIndex] = payload;
      state.data = newState;
    },
    [addWorker.rejected]: (state) => {
      console.log('error in adding a worker');
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      let newState = [...current(state.data)];
      const userIndex = current(state.data).findIndex(
        (user) => user._id === payload.id
      );
      newState.splice(userIndex, 1);
      state.data = newState;
    },
    [deleteUser.rejected]: () => {
      console.error('delete project rejected!');
    },
  },
});

const { reducer, actions } = workersSlice;

export const workersEntities = ({ workers }) => {
  const isLoading = workers.loading === 'pending';
  return { users: workers.data, isLoading };
};

export const { addUser } = actions;

export default reducer;
