import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData, editData } from '../api/data';

export const fetchMounth = createAsyncThunk('mounth/fetch', async (date) => {
	try {
		const data = await getData('mounth', { date });
		return data || { projects: [] };
	} catch (err) {
		console.log(err.response);
	}
});

export const updateMonth = createAsyncThunk(
	'month/update',
	async ({ month, date, days }) => {
		try {
			const data = await editData(
				'mounth',
				{ ...month, reportDate: date, days },
				{ id: month._id },
			);
			return data;
		} catch (err) {
			throw err;
		}
	},
);

const initialState = {
	date: new Date(Date.now()).toDateString(),
	month: null,
	days: 0,
	hours: 0,
	loading: 'idle',
};

const mounthSlice = createSlice({
	name: 'mounth',
	initialState,
	reducers: {
		updateDate: (state, { payload }) => {
			state.date = payload;
		},
		addProjectToMonth: (state, { payload: { projectId } }) => {
			let newState = { ...state.month };
			if (!state.month)
				newState = { projects: [], reportDate: state.date };
			newState.projects.push({ project: projectId, workers: [] });
			state.month = newState;
		},
		addWorkersToMonth: (state, { payload: { ids } }) => {
			const workers = [...(state?.month?.workers || [])];
			ids.forEach((workerId) => {
				const index = workers.findIndex(
					(worker) => worker.worker === workerId,
				);
				if (index === -1) workers.push({ worker: workerId });
			});
			const newState = { ...state.month };
			newState.workers = workers;
			state.month = newState;
		},
		updateProjectDetails: (
			state,
			{ payload: { projectId, value, name } },
		) => {
			let newState = { ...state.month };
			if (!state.month)
				newState = { projects: [], reportDate: state.date };
			if (
				newState.projects.findIndex(
					(project) => project.project === projectId,
				) === -1
			) {
				newState.projects.push({ project: projectId, workers: [] });
			}
			newState.projects = newState.projects.map((proj) => {
				const newProj = proj;
				if (proj.project === projectId)
					newProj[name] = parseInt(value || 0) || value;
				return newProj;
			});
			state.month = newState;
		},
		updateWorkerValue: (state, { payload: { projectId, value, id } }) => {
			let newState = { ...state.month };
			if (
				newState.projects.findIndex(
					(project) => project.project === projectId,
				) === -1
			) {
				newState.projects.push({ project: projectId, workers: [] });
			}
			newState.projects = newState.projects.map((proj) => {
				const newProj = { ...proj };
				if (proj.project === projectId) {
					if (
						newProj.workers.findIndex(
							(worker) => worker.worker === id,
						) === -1
					) {
						newProj.workers.push({
							worker: id,
							hours: parseInt(value || 0),
						});
					} else
						newProj.workers = proj.workers.map((worker) => {
							let hours = worker.hours;
							if (worker.worker === id) {
								hours = parseInt(value || 0);
							}
							return { ...worker, hours };
						});
				}
				return newProj;
			});
			state.month = newState;
		},
		updateSickDays: (state, { payload: { value, id: workerId } }) => {
			console.log(value, workerId);
			const workers = [...state?.month?.workers] || [];
			const index = workers.findIndex(
				(worker) => worker.worker === workerId,
			);
			if (index === -1)
				workers.push({ worker: workerId, sickDays: parseInt(value) });
			else workers[index].sickDays = parseInt(value);
			const newState = { ...state.month };
			newState.workers = workers;
			state.month = newState;
		},
		setTime: (state, { payload: { days } }) => {
			state.days = days;
			state.hours = days * 9;
		},
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
		removeWorker: (state, { payload }) => {
			const newState = { ...state.month };
			const workers = newState.workers;
			const workerIndex = workers.findIndex(
				(worker) => worker.worker === payload,
			);
			workers.splice(workerIndex, 1);
			const projects = newState.projects;
			projects.forEach((project) => {
				const index = project.workers.findIndex(
					(worker) => worker.worker === payload,
				);
				project.workers.splice(index, 1);
			});
			state.month = newState;
		},
	},
	extraReducers: {
		[fetchMounth.pending]: (state) => {
			state.loading = 'pending';
		},
		[fetchMounth.fulfilled]: (state, { payload }) => {
			state.month = payload;
			state.loading = 'idle';
			state.days = payload?.days || 0;
		},
		[fetchMounth.rejected]: () => {
			console.error('fetch mounth rejected!');
		},
		[updateMonth.fulfilled]: (state, { payload }) => {
			state.month = payload;
			console.log('month updated successfully !');
		},
		[updateMonth.rejected]: () => {
			console.error('faild to update month in DB!');
		},
	},
});

export const selectMonth = ({ mounth }) => {
	const date = mounth.date;
	const month = mounth.month;
	const days = mounth.days;
	const hours = mounth.hours;
	const workers = mounth.month?.workers;
	const isLoading = mounth.loading === 'pending';
	return { date, month, mounth, days, hours, workers, isLoading };
};

const { reducer, actions } = mounthSlice;

export const {
	updateDate,
	updateProjectDetails,
	addProjectToMonth,
	updateWorkerValue,
	setTime,
	updateSickDays,
	addWorkersToMonth,
	setLoading,
	removeWorker,
} = actions;

export default reducer;
