const Dropdown = ({ list, onChange }) => {
  return (
    <div className='dropdown'>
      <select onChange={(e) => onChange(e.target.value)} name='' id=''>
        {list.map((listItem) => (
          <option
            key={listItem}
            value={listItem}
            defaultValue={listItem === 'All time'}
          >
            {listItem}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
