import { Button, Input, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addUser, addWorker, editWorker } from '../../redux/workers';
import SnackBar from '../SnackBar';

export const positions = ['Developer', 'Designer', 'Manager', 'OWNER'];

const AddEmployee = ({
  isEditMode = false,
  employee = null,
  setIsOpen = null,
}) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState(positions[0]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: 'success',
  });
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (username === '' || email === '') {
      setShowAlert(true);
      setAlertMessage({
        message: 'Some details are missing or incorrect!',
        severity: 'error',
      });
      return;
    }

    const data = {
      username,
      role,
      email,
      password,
      isAdmin: role === positions[3],
    };

    let func = addWorker(data);
    if (isEditMode) {
      data.id = employee._id;
      if (data.password === '') delete data.passowrd;
      func = editWorker(data);
    }

    dispatch(func)
      .unwrap()
      .then(() => {
        setShowAlert(true);
        setAlertMessage({
          message: `The user was ${
            isEditMode ? 'added' : 'edited'
          } successfuly !`,
          severity: 'success',
        });
        setIsOpen(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (isEditMode) {
      setUsername(employee.username);
      setEmail(employee.email);
      setRole(employee.role);
    }
  }, []);

  return (
    <form onSubmit={onFormSubmit} className='form'>
      <div>
        <InputLabel id='name'>Username: </InputLabel>
        <Input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type='text'
          id='name'
          required
        />
      </div>
      <div>
        <InputLabel id='email'>Email: </InputLabel>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type='email'
          id='email'
        />
      </div>
      <div>
        <InputLabel id='password'>Password: </InputLabel>
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type='password'
          id='password'
        />
      </div>
      <div>
        <InputLabel id='role'>Role</InputLabel>
        <Select
          labelId='role'
          id='role-select'
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          {positions.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Button type='submit' variant='contained'>
        {isEditMode ? 'Save' : 'Add'}
      </Button>
      <SnackBar
        open={showAlert}
        setOpen={setShowAlert}
        message={alertMessage.message}
        severity={alertMessage.severity}
      />
    </form>
  );
};

export default AddEmployee;
