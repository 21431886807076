import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { companiesEntities } from '../../redux/companies'
import { selectMonth, updateProjectDetails, updateWorkerValue } from '../../redux/mounth'
import { workersEntities } from '../../redux/workers'
import { positions } from '../employees/AddEmployee'
import Cell from './Cell'

const Row = ({ project }) => {
    const { workers } = useSelector(selectMonth)
    const { users } = useSelector(workersEntities)
    const {companies} = useSelector(companiesEntities)
    const { month } = useSelector(selectMonth)
    const monthlyProject = useMemo(() => {
        return month?.projects?.find((proj) => proj.project === project._id)
    }, [month, project])
    const projectCompany = useMemo(() => {
         const company = companies.find((company) => company.projects.findIndex((proj) => proj._id === project._id) !== -1)
         return company?.name
    }, [project, companies])

    const workerValue = (id) => {
        let hours = 0
        monthlyProject?.workers.forEach((worker) => {
            if (worker.worker === id) return hours = worker.hours
        })
        return hours
    }

    const plannedValue = (role) => {
        let total = 0
        monthlyProject?.workers?.forEach((worker) => {
            const workerRole = (users.find((w) => w._id === worker.worker))?.role
            if (workerRole === role)
                total += worker.hours
            return total > 0 ? total : ""
        })
        return total
    }


  return (
    <div className='row'>
        <div className='project-name cell'>
            { projectCompany || "" }
        </div>
        <div className='project-name cell'>
            { project.name }
        </div>
        <Cell projectId={project._id} disabled value={plannedValue(positions[0]) + plannedValue(positions[2])} /> { /* total hours planned */}
        <Cell projectId={project._id} disabled value={plannedValue(positions[0])} /> { /* pland hours for development */}
        <Cell projectId={project._id} disabled value={plannedValue(positions[2])} /> { /* planned hours for qa & mang. */}
        <Cell projectId={project._id} disabled value={(monthlyProject?.developmentHours || 0) + (monthlyProject?.managmentHours || 0)} /> { /* total expected hours */}
        <Cell projectId={project._id} value={monthlyProject?.developmentHours} updateState={updateProjectDetails} name="developmentHours" /> { /* expected hours for development */}
        <Cell projectId={project._id} value={monthlyProject?.managmentHours} updateState={updateProjectDetails} name="managmentHours" /> { /*  expected hours for project managment and qa */}
        <Cell projectId={project._id} style={{ gridColumn: 'span 2', width: '99%' }} text value={monthlyProject?.projectManager} updateState={updateProjectDetails} name="projectManager" /> { /*  project manager */ }
        
        {
            !!workers && workers.map((worker) => (
                <Cell key={worker.worker} projectId={project._id} id={worker.worker} value={workerValue(worker.worker)} updateState={updateWorkerValue} />
            ))
        }

    </div>
  )
}

export default Row