import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Button,
  Box,
  Paper,
  TextField
} from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companiesEntities, fetchCompanies } from '../../redux/companies';
import { addProject, editProject } from '../../redux/projects';
import { workersEntities } from '../../redux/workers';
import SelectTeam from './SelectTeam';

const AddProject = ({ setIsOpen, isEditMode = false, project = null }) => {
  const dispatch = useDispatch();
  const { companies } = useSelector(companiesEntities);
  const { users } = useSelector(workersEntities);
  const regex = /^[^א-ת]*$/;

  const [company, setCompany] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectType, setProjectType] = useState('hourly');
  const [hoursBank, setHoursBank] = useState();
  const [ratePerHour, setRatePerHour] = useState();
  const [totalBudget, setTotalBudget] = useState();
  const [openSelect, setOpenSelect] = useState(false);

  const [isNewCompany, setIsNewCompany] = useState(false);

  const [designTeam, setDesignTeam] = useState([]);
  const [otherTeam, setOtherTeam] = useState([]);

  const onClickToggleIsNewComapny = () => {
    setCompany('');
    setIsNewCompany(!isNewCompany);
  };

  const onClickRemoveTeamMember = (id, team, setTeam) => {
    setOpenSelect(true)
    const newTeam = [...team];
    newTeam.splice(team.indexOf(id), 1);
    setTeam(newTeam);
    setOpenSelect(false)
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    let data;

    if (projectType === 'hourly') {
      data = {
        name: projectName,
        company,
        companyName,
        isNewCompany,
        isFixed: false,
        type: 'Design',
        hoursBank: [{ addedDate: new Date(), hours: hoursBank }],
        users: [...designTeam, ...otherTeam],
      };
    } else {
      data = {
        name: projectName,
        company,
        companyName,
        isNewCompany,
        isFixed: true,
        type: 'Design',
        ratePerHour,
        totalBudget,
        hoursBank: [
          { addedDate: new Date(), hours: totalBudget / ratePerHour },
        ],
        users: [...designTeam, ...otherTeam],
      };
    }

    if (isEditMode) {
      dispatch(editProject({ ...data, id: project._id }))
        .unwrap(unwrapResult)
        .then(() => {
          setIsOpen(false);
        });
    } else {
      dispatch(addProject(data))
        .unwrap(unwrapResult)
        .then(() => {
          setIsOpen(false);
        });
    }
    dispatch(fetchCompanies());
  };

  useEffect(() => {
    if (isEditMode) {
      setCompany(project.company._id);
      setProjectName(project.name);
      setProjectType(project.isFixed ? 'fixed' : 'hourly');
    }
  }, []);

  useEffect(() => {
    if (company) {
      setDesignTeam(
        companies
          .find((el) => el._id === company)
          .users.filter((user) => user.role === 'Designer')
          .map((user) => user._id)
      );
      setOtherTeam(
        companies
          .find((el) => el._id === company)
          .users.filter((user) => user.role !== 'Designer')
          .map((user) => user._id)
      );
    } else {
      setDesignTeam([]);
      setOtherTeam([]);
    }
  }, [company]);

  return (
    <Box component={Paper} elevation={4} mb={4} className='add-project'>
      <h2>{isEditMode ? 'Edit' : 'New'} project</h2>
      <form onSubmit={onSubmitForm}>
        <FormControl>
          <div className='company'>
            {isNewCompany ? (
              <TextField
                label='Compnay'
                id='project-name'
                value={companyName}
                onChange={(e) => {
                  if (e.target.value.match(regex))
                    setCompanyName(e.target.value);
                }}
              />
            ) : companies.length ? (
              <TextField
                select
                fullWidth
                label='Compnay'
                id='project-name'
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              >
                {companies.map((company) => (
                  <MenuItem key={company._id} value={company._id}>{company.name}</MenuItem>
                ))}
              </TextField>
            ) : (
              <div>No companies</div>
            )}
            {!isEditMode && (
              <Button onClick={onClickToggleIsNewComapny}>
                {isNewCompany ? 'Choose existing' : '+ Create new'}
              </Button>
            )}
          </div>
        </FormControl>
        <FormControl>
          <InputLabel id='project-name'>Project name</InputLabel>
          <Input
            value={projectName}
            onChange={(e) => {
              if (e.target.value.match(regex)) setProjectName(e.target.value);
            }}
          />
        </FormControl>
        {!isEditMode ? (
          <>
            <h3>Project type</h3>
            <div className='project-type'>
              <FormControl>
                <TextField
                  select
                  label='Project type'
                  id='project-type'
                  value={projectType}
                  onChange={(e) => setProjectType(e.target.value)}
                >
                  <MenuItem value='hourly'>Hourly</MenuItem>
                  <MenuItem value='fixed'>Fixed</MenuItem>
                </TextField>
              </FormControl>
              {projectType === 'hourly' ? (
                <FormControl>
                  <InputLabel htmlFor='hours-bank'>Hours bank</InputLabel>
                  <Input
                    id='hours-bank'
                    type='number'
                    value={hoursBank}
                    onChange={(e) => {
                      if (e.target.value >= 0) setHoursBank(e.target.value);
                    }}
                  />
                </FormControl>
              ) : (
                <>
                  <FormControl>
                    <InputLabel id='total-budget'>Total budget</InputLabel>
                    <Input
                      type='number'
                      value={totalBudget}
                      onChange={(e) => {
                        if (e.target.value >= 0) setTotalBudget(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel id='rate-per-hour'>Rate per hour</InputLabel>
                    <Input
                      type='number'
                      value={ratePerHour}
                      onChange={(e) => {
                        if (e.target.value >= 0) setRatePerHour(e.target.value);
                      }}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </>
        ) : (
          projectType === 'hourly' && (
            <>
              <h2>Add hours to bank</h2>
              <div className='project-type'>
                <FormControl>
                  <InputLabel id='rate-per-hour'>Number of hours</InputLabel>
                  <Input
                    type='number'
                    value={hoursBank}
                    onChange={(e) => {
                      if (e.target.value >= 0) setHoursBank(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </>
          )
        )}

        <h2>Company team</h2>
        <SelectTeam
          users={users}
          team={designTeam}
          setTeam={setDesignTeam}
          teamRole='Designer'
          onClickRemoveTeamMember={onClickRemoveTeamMember}
          openSelect={openSelect}
        />
        <SelectTeam
          users={users}
          team={otherTeam}
          setTeam={setOtherTeam}
          teamRole='Other'
          onClickRemoveTeamMember={onClickRemoveTeamMember}
          openSelect={openSelect}
        />

        <Button type='submit' variant='contained'>
          {isEditMode ? 'Update project' : 'Create new project'}
        </Button>
      </form>
    </Box>
  );
};

export default AddProject;
