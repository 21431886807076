import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import AddEmployee from '../components/employees/AddEmployee';
import { Table } from '../components/Table';
import { workersEntities } from '../redux/workers';
import { Box } from '@mui/material';
import Spinner from "../components/Spinner"

export default function Employees() {
  const { users, isLoading } = useSelector(workersEntities);
  const params = useParams();
  const navigate = useNavigate();


  const [isAddEmployeeOpen, setIsAddEmployeeOpen] = useState(false);

  const onClickToggleIsEmployeeProjectOpen = () => {
    setIsAddEmployeeOpen(!isAddEmployeeOpen);
  };

  const onClickNavigate = (id) => {
    navigate(id, { project: users });
  };

  if (isLoading) return <Spinner />;

  return !!params.id ? (
    <Outlet />
  ) : (
    <Box m={2} pb={4}>
      <h1>Users</h1>
      <Box mb={2}>
        <Button
          onClick={onClickToggleIsEmployeeProjectOpen}
          type='button'
          variant='contained'
        >
          + New user
        </Button>
      </Box>
      {isAddEmployeeOpen && <AddEmployee setIsOpen={setIsAddEmployeeOpen} />}
      <Table
        properties={Object.keys(users[0] || {})}
        // properties={['#', ...Object.keys(users[0] || {})]}
        values={users}
        onRowClicked={onClickNavigate}
      />
    </Box>
  );
}
