import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { addData } from '../api/data';
import {
  deleteUserOnCookie,
  getUserFromCookie,
  saveUserOnCookie,
} from '../cookies/cookies';

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }) => {
    try {
      const userData = await addData('user/admin-login', { email, password });

      return userData;
    } catch (err) {
      throw err;
    }
  }
);

export const logout = createAsyncThunk('user/logout', async (token) => {
  try {
    const userData = await addData('user/logout', null, token);

    return userData;
  } catch (err) {
    throw err;
  }
});

const initialState = { user: null, token: '' };

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginFromCookie: (state, { payload }) => {
      return payload;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      saveUserOnCookie(payload);
      return payload;
    },
    [logout.fulfilled]: (state, { payload }) => {
      deleteUserOnCookie();
      return initialState;
    },
  },
});

const { reducer, actions } = loginSlice;

export const { loginFromCookie } = actions;

export default reducer;
