import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  pPTC: [
    {
      serviceType: '',
      monthlyPayment: '',
      yearlyPayment: '',
      paymentType: '',
      chargeCustomer: 'לא',
      paymentOut: 'לא',
      paymentDone: 'לא',
      invoiceNumber: '',
    },
  ],
};

export const pPTCSlice = createSlice({
  name: 'pptc',
  initialState,
  reducers: {
    pPTCPUSH: (state, action) => {
      state.pPTC = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { pPTCPUSH } = pPTCSlice.actions;

export default pPTCSlice.reducer;
