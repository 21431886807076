import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fix,
  insidePricing,
  generalPush,
  jsonEPush,
  designdevPush,
} from '../../redux/mocks';
import {
  totalHumanMonthsPush,
  totalProjectHoursPush,
  baseHoursPush,
} from '../../redux/contracts';
import { dataElement, bH } from './AddContract';
import fixpricing, { fixPricePush } from '../../redux/fixpricing';
import { insidesPricesPush } from '../../redux/insidepricing';
import { generalDataPush } from '../../redux/general';
import { mileStonesPush } from '../../redux/milestones';
import { jsonElementPush } from '../../redux/mocks';
import { projectContractBudgetPush } from '../../redux/contracts';
import { designDevPush } from '../../redux/designdev';
export var iP = 0;

const PricingTables = () => {
  const dispatch = useDispatch();
  const insidePricingElement = useSelector((state) => state.insidepricing);
  const fixPricingElement = useSelector((state) => state.fixpricing);
  const mileStones = useSelector((state) => state.milestone);
  const jsonE = useSelector((state) => state.jsonElement);
  const generalData = useSelector((state) => state.generaldata);
  const designDev = useSelector((state) => state.designdev);

  const remove = (e, index) => {
    const insidePricingElementClone = insidePricingElement.insidePricing.map(
      (x) => {
        return { ...x };
      }
    );
    const fixPricingElementClone = fixPricingElement.fixPricing.map((x) => {
      return { ...x };
    });

    insidePricingElementClone.splice(index, 1);
    fixPricingElementClone.splice(index, 1);
    dispatch(insidesPricesPush(insidePricingElementClone));
    dispatch(fixPricePush(fixPricingElementClone));
  };

  const add = (name) => {
    if (name === 'fixElement') {
      const insidePricingElementClone = insidePricingElement.insidePricing.map(
        (x) => {
          return { ...x };
        }
      );
      const fixPricingElementClone = fixPricingElement.fixPricing.map((x) => {
        return { ...x };
      });
      fixPricingElementClone.push({
        staffMembers: '',
        peopleAmount: '',
        monthsAmount: '',
        hoursConversion: '',
        outsideHourPrice: '',
        estMonthlyPayment: '',
        outsideProjectTotal: '',
      });
      insidePricingElementClone.push({
        staffMembers: '',
        insidePrice: '',
        insidePricing: '',
        outsidePrice: '',
        outsidePricing: '',
        insideProjectTotal: '',
        outsideProjectTotal: '',
        monthly: '',
        totalForProject: '',
      });
      //setInsidePricingElement(insidePricingElementClone);
      // dispatch(insidePricing(insidePricingElementClone));
      dispatch(insidesPricesPush(insidePricingElementClone));
      dispatch(fixPricePush(fixPricingElementClone));
      // setFixElement(fixPricingElementClone);
      // dispatch(fix(clone));
    }
  };

  const MainFunction = (e, b, index) => {
    console.log('DESGINDEV: ', designDev.monthsAmount);
    var totalHoursConversionDesignDev = 0;
    let mileStonesClone = mileStones.milestones.map((x) => {
      if (x.designDev === 'עיצוב') {
        totalHoursConversionDesignDev = 1;
      }
      return { ...x };
    });

    let generalDataClone = { ...generalData.generalData };
    let designDevClone = { ...designDev.designDev };

    let fixPricingElementClone = fixPricingElement.fixPricing.map((x) => {
      return { ...x };
    });

    let insidePricingElementClone = insidePricingElement.insidePricing.map(
      (x) => {
        return { ...x };
      }
    );

    if (index === 'designDev') {
      if (b === 'insidePricing') {
        generalDataClone.designPrice = e;
      }

      designDevClone[b] = e;
      let mA = designDevClone.monthsAmount.toString(); //document.getElementById('monthsAmount' + index).value;
      if (mA.search('%') > 0) {
        mA = mA.replace('%', '') * 0.01;
      }

      designDevClone.insideProjectTotal =
        Number(designDevClone.insidePricing) *
        Number(generalData.generalData.baseHours) *
        Number(mA) *
        Number(designDevClone.peopleAmount);

      designDevClone.outsideProjectTotal =
        Number(designDevClone.outsidePricing) *
        Number(generalData.generalData.baseHours) *
        Number(mA) *
        Number(designDevClone.peopleAmount);

      designDevClone.estMonthlyPayment =
        Number(designDevClone.insidePricing) *
        Number(generalData.generalData.baseHours) *
        Number(mA) *
        Number(designDevClone.peopleAmount);

      designDevClone.hoursConversion =
        Number(generalData.generalData.baseHours) *
        Number(mA) *
        Number(designDevClone.peopleAmount);

      dispatch(designDevPush(designDevClone));
      dispatch(designdevPush(designDevClone));
      console.log('JSONE', jsonE);
    }

    var totalMonthsAmount = 0;
    var totalHoursConversion = 0;
    var totalEstMonthlyPayment = 0;

    if (b === 'staffMembers') {
      insidePricingElementClone[index][b] = e;
      fixPricingElementClone[index][b] = e;
    }

    // if (
    //   b === 'insidePricing' &&
    //   index !== 'designDev' &&
    //   insidePricingElementClone[index]['staffMembers'] === 'עיצוב'
    // ) {
    //   generalDataClone.designPrice = e;
    // }

    let array = ['peopleAmount', 'monthsAmount', 'outsidePrice'];
    let array2 = ['insidePricing', 'outsidePricing'];

    if (b === 'insidePricing' && index === 'designDev') {
      for (const [key, value] of Object.entries(mileStonesClone)) {
        if (value.designDev === 'עיצוב') {
          mileStonesClone[key].innerHoursToBeDone = Math.round(
            (Number(mileStonesClone[key].moneyWorth) /
              Number(generalDataClone.designPrice)) *
              0.7
          );
        }
      }
    }

    if (array2.includes(b) && index !== 'designDev') {
      insidePricingElementClone[index][b] = Number(e);
      iP += 1;
      let designPrice = 0;
      for (const [key, value] of Object.entries(insidePricingElementClone)) {
        if (value.staffMembers === 'עיצוב') {
          designPrice = generalDataClone.designPrice;
        }
      }
      for (const [key, value] of Object.entries(mileStonesClone)) {
        if (value.designDev === 'עיצוב') {
          mileStonesClone[key].innerHoursToBeDone = Math.round(
            (Number(value.moneyWorth) /
              Number(designDev.designDev.insidePricing)) *
              0.7
          );
        }
      }

      if (mileStonesClone[index] !== undefined) {
        //console.log('MMS', mileStonesClone[index]);
        if (mileStonesClone[index].designDev !== 'עיצוב') {
          mileStonesClone[index].innerHoursToBeDone = Math.round(
            (Number(mileStonesClone[index].moneyWorth) /
              Number(insidePricingElementClone[index].insidePricing)) *
              0.7
          );
        }
      }
    }

    if (array.includes(b) && index !== 'designDev') {
      fixPricingElementClone[index][b] = e;
    }

    if (
      index !== undefined &&
      index !== 'designDev' &&
      fixPricingElementClone[index].monthsAmount !== undefined
    ) {
      let mA = fixPricingElementClone[index].monthsAmount.toString(); //document.getElementById('monthsAmount' + index).value;
      if (mA.search('%') > 0) {
        mA = mA.replace('%', '') * 0.01;
      }

      insidePricingElementClone[index].insideProjectTotal =
        Number(insidePricingElementClone[index].insidePricing) *
        Number(generalData.generalData.baseHours) *
        Number(mA) *
        Number(fixPricingElementClone[index].peopleAmount);

      insidePricingElementClone[index].outsideProjectTotal =
        Number(insidePricingElementClone[index].outsidePricing) *
        Number(generalData.generalData.baseHours) *
        Number(mA) *
        Number(fixPricingElementClone[index].peopleAmount);

      fixPricingElementClone[index].outsideProjectTotal =
        insidePricingElementClone[index].outsideProjectTotal;

      fixPricingElementClone[index].hoursConversion =
        Number(fixPricingElementClone[index].peopleAmount) *
        Number(mA) *
        Number(generalData.generalData.baseHours);

      fixPricingElementClone[index].estMonthlyPayment =
        Number(insidePricingElementClone[index].insidePricing) *
        Number(generalData.generalData.baseHours) *
        Number(mA) *
        Number(fixPricingElementClone[index].peopleAmount);
    }

    let clone = fixPricingElementClone;
    let mA2 = 0;
    let ifc = 0;
    for (const key in clone) {
      if (clone.hasOwnProperty(key)) {
        if (clone[key].monthsAmount !== undefined) {
          if (clone[key].monthsAmount.toString().search('%') > 0) {
            mA2 = clone[key].monthsAmount.toString().replace('%', '') * 0.01;
            // alert(mA);
          } else {
            mA2 = clone[key].monthsAmount;
          }
        }

        if (mileStonesClone[key] !== undefined) {
          if (mileStonesClone[key].designDev === 'פיתוח') {
            totalHoursConversion += Number(clone[key].hoursConversion);
          }
          if (mileStonesClone[key].designDev === 'עיצוב') {
            //totalHoursConversionDesignDev = 1;
            totalHoursConversion += designDevClone.hoursConversion;
          }
        }

        console.log('designDevMonthhsAMOUNT:', designDevClone.monthsAmount);
        totalMonthsAmount += Number(mA2) + Number(designDevClone.monthsAmount);
        totalEstMonthlyPayment += Number(clone[key].estMonthlyPayment);
      }
    }
    // if (totalHoursConversionDesignDev === 1) {
    //   //console.log('ddhours conversion: ', designDevClone.hoursConversion);
    //   totalHoursConversion += Number(designDevClone.hoursConversion);
    // }

    generalDataClone.totalHumanMonths = totalMonthsAmount;
    generalDataClone.totalProjectHours = totalHoursConversion;
    console.log('TTT', totalHoursConversion - generalDataClone.totalHoursDone);
    generalDataClone.totalProjectHoursLeft =
      totalHoursConversion - generalDataClone.totalHoursDone;

    dispatch(insidesPricesPush(insidePricingElementClone));
    dispatch(insidePricing(insidePricingElementClone));
    dispatch(fixPricePush(fixPricingElementClone));
    dispatch(fix(fixPricingElementClone));

    if (totalHoursConversion > 0) {
      dispatch(totalProjectHoursPush(totalHoursConversion));
    }

    if (totalMonthsAmount > 0) {
      dispatch(totalHumanMonthsPush(totalMonthsAmount));
    }
    dispatch(projectContractBudgetPush(generalDataClone.projectContractBudget));
    dispatch(generalPush(generalDataClone));
    dispatch(generalDataPush(generalDataClone));
    dispatch(mileStonesPush(mileStonesClone));
    console.log('FixElement: ', fixPricingElementClone);
    console.log('insideElement: ', insidePricingElementClone);
    console.log('generalDataClone: ', generalDataClone);
  };

  return (
    <div className="PricingTables">
      <div className="topDiv higher">
        <div className="cols">
          <div>
            {fixPricingElement.fixPricing && (
              <div className="middleContainer1">
                <h3>הצעת מחיר</h3>
                <table id="fixElementTable">
                  <thead>
                    <tr>
                      <th>
                        <button
                          id="addLine"
                          className="adds"
                          onClick={(name) => add('fixElement')}
                        >
                          +
                        </button>
                      </th>
                      <th>אנשי צוות</th>
                      <th>מספר אנשים(*הזנה מהחוזה)</th>
                      <th>כמות חודשים(*הזנה מהחוזה)</th>
                      <th>המרה לשעות</th>

                      <th>תשלום חודשי משוערך</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fixPricingElement.fixPricing.map((el, index) => (
                      <tr key={index}>
                        <td>
                          <button
                            onClick={(e) => {
                              remove(el.staffMembers, index);
                            }}
                          >
                            X
                          </button>
                        </td>
                        <td>
                          <input
                            className="staffMembers"
                            value={el.staffMembers}
                            type="text"
                            onChange={function (e) {
                              MainFunction(
                                e.target.value,
                                'staffMembers',
                                index
                              );
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className="peopleAmount"
                            value={el.peopleAmount}
                            onChange={(e) =>
                              MainFunction(
                                e.target.value,
                                'peopleAmount',
                                index
                              )
                            }
                            type="text"
                          />
                        </td>
                        <td>
                          <input
                            className="monthsAmount"
                            id={'monthsAmount' + index}
                            value={el.monthsAmount}
                            onChange={function (e) {
                              MainFunction(
                                e.target.value,
                                'monthsAmount',
                                index
                              );
                            }}
                            type="text"
                          />
                        </td>
                        <td>
                          <input
                            className="hoursConversion"
                            value={el.hoursConversion}
                            onChange={function (e) {
                              MainFunction(
                                el.outsideHourPrice,
                                e.target.value,
                                'hoursConversion',
                                index
                              );
                            }}
                            type="text"
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="estMonthlyPayment"
                            value={el.outsideProjectTotal}
                            type="text"
                            disabled
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div>
            {insidePricingElement.insidePricing && (
              <div className="middleContainer2">
                <div className="cols">
                  <div>
                    <h3
                      style={{
                        position: 'relative',
                        /*top: '20px',*/
                      }}
                    >
                      תמחור פנימי
                    </h3>
                  </div>
                  <div>
                    <h3
                      style={{
                        position: 'relative',
                        top: '20px',
                      }}
                    >
                      תמחור חיצוני
                    </h3>
                  </div>
                </div>
                <table
                  id="insidePricing"
                  className="insidePricing"
                  style={{ top: '15px', position: 'relative' }}
                >
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>שם/אנשי צוות</th>
                      <th>שעתי</th>
                      <th>סה"כ לפרוייקט</th>
                      {/* <th></th>
                      <th></th> */}
                      <th>שעתי</th>
                      <th>סה"כ לפרוייקט</th>
                    </tr>
                  </thead>
                  <tbody>
                    {insidePricingElement.insidePricing.map((el, index) => (
                      <tr key={index}>
                        {/* <td></td> */}
                        <td>
                          <input
                            className="staffMembers"
                            value={el.staffMembers}
                            onChange={(e) =>
                              MainFunction(e.target, 'staffMembers', index)
                            }
                            type="text"
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="insidePricing"
                            defaultValue={el.insidePricing}
                            onChange={(e) =>
                              MainFunction(
                                e.target.value,
                                'insidePricing',
                                index
                              )
                            }
                            type="number"
                          />
                        </td>

                        <td>
                          <input
                            className="insideProjectTotal"
                            value={el.insideProjectTotal}
                            type="number"
                            disabled
                          />
                        </td>
                        {/* <td></td>
                        <td></td> */}
                        <td>
                          <input
                            className="outsidePricing"
                            defaultValue={el.outsidePricing}
                            onChange={(e) =>
                              MainFunction(
                                e.target.value,
                                'outsidePricing',
                                index
                              )
                            }
                            type="text"
                          />
                        </td>
                        <td>
                          <input
                            className="outsideProjectTotal"
                            value={el.outsideProjectTotal}
                            type="number"
                            disabled
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="cols">
          <div>
            {fixPricingElement.fixPricing && (
              <div className="middleContainer3">
                <h3>עיצוב</h3>
                <table id="fixElementTable">
                  <thead>
                    <tr>
                      <th>
                        <button
                          id="addLine"
                          className="adds"
                          //onClick={(name) => add('fixElement')}
                        >
                          &#x25A7;
                        </button>
                      </th>
                      <th>אנשי צוות</th>
                      <th>מספר אנשים(*הזנה מהחוזה)</th>
                      <th>כמות חודשים(*הזנה מהחוזה)</th>
                      <th>המרה לשעות</th>

                      <th>תשלום חודשי משוערך</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <button
                        // onClick={(e) => {
                        //   remove(el.staffMembers, index);
                        // }}
                        >
                          &#x25A7;
                        </button>
                      </td>
                      <td>
                        <input
                          className="staffMembers"
                          value="עיצוב"
                          type="text"
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          className="peopleAmount"
                          defaultValue={designDev.designDev.peopleAmount}
                          onChange={(e) =>
                            MainFunction(
                              e.target.value,
                              'peopleAmount',
                              'designDev'
                            )
                          }
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          className="monthsAmount"
                          //id={'monthsAmount' + index}
                          value={designDev.designDev.monthsAmount}
                          onChange={function (e) {
                            MainFunction(
                              e.target.value,
                              'monthsAmount',
                              'designDev'
                            );
                          }}
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          className="hoursConversion"
                          value={designDev.designDev.hoursConversion}
                          // onChange={function (e) {
                          //   MainFunction(
                          //     e.target.value,
                          //     'hoursConversion',
                          //     'designDev'
                          //   );
                          // }}
                          type="text"
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          className="estMonthlyPayment"
                          value={designDev.designDev.outsideProjectTotal}
                          type="text"
                          disabled
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div>
            {insidePricingElement.insidePricing && (
              <div className="middleContainer4">
                <div className="cols">
                  <div>
                    <h3
                      style={{
                        position: 'relative',
                        /*top: '20px',*/
                      }}
                    >
                      תמחור פנימי
                    </h3>
                  </div>
                  <div>
                    <h3
                      style={{
                        position: 'relative',
                        top: '20px',
                      }}
                    >
                      תמחור חיצוני
                    </h3>
                  </div>
                </div>
                <table
                  id="insidePricing"
                  className="insidePricing"
                  style={{ top: '15px', position: 'relative' }}
                >
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>שם/אנשי צוות</th>
                      <th>שעתי</th>
                      <th>סה"כ לפרוייקט</th>
                      {/* <th></th>
                      <th></th> */}
                      <th>שעתי</th>
                      <th>סה"כ לפרוייקט</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* <td></td> */}
                      <td>
                        <input
                          className="staffMembers"
                          value="עיצוב"
                          type="text"
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          className="insidePricing"
                          value={designDev.designDev.insidePricing}
                          onChange={(e) =>
                            MainFunction(
                              e.target.value,
                              'insidePricing',
                              'designDev'
                            )
                          }
                          type="number"
                        />
                      </td>

                      <td>
                        <input
                          className="insideProjectTotal"
                          value={designDev.designDev.insideProjectTotal}
                          type="number"
                          disabled
                        />
                      </td>
                      {/* <td></td>
                        <td></td> */}
                      <td>
                        <input
                          className="outsidePricing"
                          value={designDev.designDev.outsidePricing}
                          onChange={(e) =>
                            MainFunction(
                              e.target.value,
                              'outsidePricing',
                              'designDev'
                            )
                          }
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          className="outsideProjectTotal"
                          value={designDev.designDev.outsideProjectTotal}
                          type="number"
                          disabled
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTables;
