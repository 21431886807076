import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  jsonElement: [{}],
  generalData: {},
  insidePricingElement: [{}],
  fixElement: [{}],
  extraPayments: [{}],
  pPTC: [{}],
  designDev: {},
};

export const jsonSlice = createSlice({
  name: 'jsonElement',
  initialState,
  reducers: {
    // increment: (state) => {
    //   state[0].value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // insidesOut: (state, action) => {
    //   state.insidesOut = Number(action.payload);
    // },
    // insidesPaid: (state, action) => {
    //   state.insidesPaid = Number(action.payload);
    // },
    insidePricing: (state, action) => {
      state.insidePricingElement = action.payload;
    },
    jsonElementPush: (state, action) => {
      state.jsonElement = action.payload;
    },
    fix: (state, action) => {
      state.fixElement = action.payload;
    },
    extraPaymentsPush: (state, action) => {
      state.extraPayments = action.payload;
    },
    pPTCPush: (state, action) => {
      state.pPTC = action.payload;
    },
    jsonEPush: (state, action) => {
      state = action.payload;
    },
    generalPush: (state, action) => {
      state.generalData = action.payload;
    },
    baseHoursPush: (state, action) => {
      state.generalData.baseHours = action.payload;
    },
    designdevPush: (state, action) => {
      state.designDev = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  insidePricing,
  fix,
  jsonElementPush,
  extraPaymentsPush,
  pPTCPush,
  jsonEPush,
  generalPush,
  baseHoursPush,
  designdevPush,
} = jsonSlice.actions;

export default jsonSlice.reducer;
