import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insidesPaid } from '../../redux/contracts';
import { insidesOut } from '../../redux/contracts';
import { extrapaymentsPush } from '../../redux/extrapayments';
import { extraPaymentsPush } from '../../redux/mocks';
import { GOT, dataElement } from './AddContract';
import moment from 'moment';
import { EightK } from '@mui/icons-material';

const ExtraPaymentsTable = () => {
  const jsonE = useSelector((state) => state.jsonElement);
  const extraPaymentsElement = useSelector((state) => state.extrapayments);
  const dispatch = useDispatch();

  const [extraPayments, setExtraPayments] = useState(
    extraPaymentsElement.extraPayments
  );

  const insidePricingElement = useSelector((state) => state.insidepricing);
  // const [insidePricingElement, setInsidePricingElement] = useState(
  //   jsonE.insidePricingElement
  // );

  const yesNo = [{ val: 'כן' }, { val: 'לא' }];

  const dataPayment = [
    { name: 'אבן דרך' },
    { name: 'תשלום עוקב' },
    { name: 'תשלום קבוע' },
    { name: 'תשלום חד פעמי' },
  ];

  const [totalCustomerPriceSum, setTotalCustomerPriceSum] = useState(0);
  const [totalInnerHoursToBeDone, setTotalInnerHoursToBeDone] = useState(0);
  const [totalInsides, setTotalInsides] = useState(0);
  const [totalInsidesPaid, setTotalInsidesPaid] = useState(0);
  const [warningMessage, setWarningMessage] = useState();

  const iHTBD = (e, b, index) => {
    //console.log(e, b, index);
    let clone = extraPaymentsElement.extraPayments.map((x) => {
      return { ...x };
    });

    var allInnerHoursToBeDone = 0;
    var allCustomerPriceSum = 0;
    var allInsideOuts = 0;
    var allInsidePaid = 0;
    //console.log('clone ', clone, index);
    // if (
    //   index !== null &&
    //   clone[index].customerPriceSum !== null &&
    //   clone[index].customerPriceSum !== ''
    // ) {
    setWarningMessage('');
    document.getElementById('updateProject').style.color = 'white';
    document.getElementById('updateProject').disabled = false;

    if (b === 'customerPriceSum') {
      clone[index]['customerPriceSum'] = Number(e);

      // if (
      //   insidePricingElement.insidePricing[index] === undefined ||
      //   insidePricingElement.insidePricing[index].insidePricing === ''
      // ) {
      //   setWarningMessage('חובה למלא מחיר בשדה מחיר פנימי מקביל!');
      // } else {
      // setWarningMessage('');
      // }
      // if (
      //   Number(insidePricingElement.insidePricing[index].insidePricing) !==
      //   undefined
      // ) {
      clone[index]['innerHoursToBeDone'] =
        Number(e) / Number(insidePricingElement.insidePricing[0].insidePricing);
      // } else {
      //   alert('חובה למלא תמחורים קודם!');
      // }
    }

    let d = moment().format('YYYY-MM').toString();
    if (b !== null) {
      clone[index][b] = e;
    }
    if (index !== null) {
      clone[index]['updatedAt'] = d;
    }

    for (const key in clone) {
      if (clone.hasOwnProperty(key)) {
        allCustomerPriceSum += Number(clone[key].customerPriceSum);
        allInnerHoursToBeDone += Number(clone[key].innerHoursToBeDone);

        if (clone[key].insidesOut === 'כן') {
          allInsideOuts += Number(clone[key].customerPriceSum);
        }
        if (clone[key].insidesPaid === 'כן') {
          allInsidePaid += Number(clone[key].customerPriceSum);
        }
      }
    }

    if (allCustomerPriceSum > 0) {
      setTotalCustomerPriceSum(allCustomerPriceSum);
    } else {
      setTotalCustomerPriceSum('');
    }
    if (allInnerHoursToBeDone > 0) {
      setTotalInnerHoursToBeDone(allInnerHoursToBeDone);
    } else {
      setTotalInnerHoursToBeDone('');
    }
    // } else {
    console.log(e);
    // if (e === 'כן') {
    //   setWarningMessage(
    //     'לא ניתן לשמור - שדה סכום תמחור ללקוח חייב להיות מלא!'
    //   );
    //   clone[index].finished = 'לא';
    //   console.log(clone[index]);
    //   clone[index].innerHoursToBeDone = 0;
    //   clone[index].customerPriceSum = 0;
    //   document.getElementById('updateProject').style.color = 'red';
    //   document.getElementById('updateProject').disabled = true;
    // } else {
    //   setWarningMessage('');
    //   document.getElementById('updateProject').style.color = 'white';
    //   document.getElementById('updateProject').disabled = false;
    // }
    //}
    setExtraPayments(clone);
    setTotalInsidesPaid(allInsidePaid);
    setTotalInsides(allInsideOuts);
    dispatch(insidesPaid(allInsidePaid));
    dispatch(insidesOut(allInsideOuts));
    dispatch(extraPaymentsPush(clone));
    dispatch(extrapaymentsPush(clone));
    console.log('allCustomerPriceSum', allCustomerPriceSum);
  };

  const add = (name) => {
    if (name === 'extraPayments') {
      const extraPaymentsclone = extraPaymentsElement.extraPayments.map((x) => {
        return { ...x };
      });

      extraPaymentsclone.push({
        addonApproveDate: '',
        updatedAt: '',
        approveCustomerName: '',
        approveMailLink: '',
        customerPriceSum: '0',
        innerHoursToBeDone: '0',
        insidesOut: 'לא',
        insidesPaid: 'לא',
        nameOfAddon: '',
        paymentType: 'תשלום עוקב',
        finished: 'לא',
        invoiceNumber: '',
      });

      setExtraPayments(extraPaymentsclone);
      dispatch(extraPaymentsPush(extraPaymentsclone));
      dispatch(extrapaymentsPush(extraPaymentsclone));
    }
  };

  useEffect(() => {
    setTimeout(function () {
      //setInsidePricingElement(jsonE.insidePricingElement);
      // setExtraPayments(extraPaymentsElement.extraPayments);

      iHTBD(null, null, null);
    }, 1000);
  }, []);

  return (
    <div>
      <div className="topDiv">
        <div className="insideContainer">
          <label>
            <h3>תכולות נוספות/Retainer</h3>
          </label>
          <table id="insides">
            <thead>
              <tr>
                <th>תכולות שיצאו:</th>
                <th>תכולות ששולמו:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    className="totalInsides"
                    value={totalInsides}
                    type="text"
                    disabled
                  />
                </td>
                <td>
                  <input
                    className="totalInsidesPaid"
                    value={totalInsidesPaid}
                    type="text"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {extraPaymentsElement.extraPayments && (
          <table className="ExtraPaymentsTable">
            <thead>
              <tr>
                <th>
                  <button
                    id="addLine"
                    className="adds"
                    onClick={(name) => add('extraPayments')}
                  >
                    +
                  </button>
                </th>
                <th>שם תוספת</th>
                <th>אופי תשלום</th>
                <th>סכום תמחור ללקוח</th>
                <th>שעות פנימיות לביצוע</th>
                <th>תאריך אישור תוספת</th>
                <th>לינק למייל אישור</th>
                <th>שם הלקוח שאישר</th>
                <th>האם הסתיים</th>
                <th>תכולה יצאה</th>
                <th>תכולה שולמה</th>
              </tr>
            </thead>
            <tbody>
              {extraPaymentsElement.extraPayments.map((el, index) => (
                <tr key={index}>
                  <td></td>
                  <td>
                    <input
                      className="nameOfAddon"
                      value={el.nameOfAddon}
                      onChange={function (el) {
                        iHTBD(el.target.value, 'nameOfAddon', index);
                      }}
                      type="text"
                    />
                  </td>
                  <td>
                    <select
                      className="paymentType"
                      onChange={function (el) {
                        iHTBD(el.target.value, 'paymentType', index);
                      }}
                    >
                      <option key={index}>{el.paymentType}</option>
                      {dataPayment.map(function (elm, i) {
                        if (elm.name !== el.paymentType) {
                          return <option key={i}>{elm.name}</option>;
                        }
                      })}
                    </select>
                  </td>
                  <td>
                    <input
                      className="customerPriceSum"
                      value={el.customerPriceSum}
                      onChange={function (el) {
                        iHTBD(el.target.value, 'customerPriceSum', index);
                      }}
                      type="number"
                    />
                  </td>
                  <td>
                    <input
                      className="innerHoursToBeDone"
                      value={el.innerHoursToBeDone}
                      onChange={function (el) {
                        iHTBD(el.target.value, null, index);
                      }}
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="addonApproveDate"
                      value={el.addonApproveDate}
                      onChange={(e) => {
                        iHTBD(e.target.value, 'addonApproveDate', index);
                      }}
                      type="date"
                    />
                  </td>
                  <td>
                    <input
                      className="approveMailLink"
                      value={el.approveMailLink}
                      onChange={(e) => {
                        iHTBD(e.target.value, 'approveMailLink', index);
                      }}
                      type="text"
                    />
                  </td>
                  <td>
                    <input
                      className="approveCustomerName"
                      value={el.approveCustomerName}
                      onChange={(e) => {
                        iHTBD(e.target.value, 'approveCustomerName', index);
                      }}
                      type="text"
                    />
                  </td>
                  <td>
                    <select
                      className="finished"
                      name="finished"
                      onChange={function (e) {
                        iHTBD(e.target.value, 'finished', index);
                      }}
                    >
                      <option key={index}>{el.finished}</option>
                      {yesNo.map(function (elm, i) {
                        if (el.finished !== elm.val) {
                          return <option key={i}>{elm.val}</option>;
                        }
                        return false;
                      })}
                    </select>
                  </td>
                  <td>
                    <input
                      className="insidesOut"
                      value={el.insidesOut}
                      onChange={(e) => {
                        iHTBD(e.target.value, 'insidesOut', index);
                      }}
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="insidesPaid"
                      value={el.insidesPaid}
                      onChange={(e) => {
                        iHTBD(e.target.value, 'insidesPaid', index);
                      }}
                      type="text"
                      disabled
                    />
                    {/* <select
                      className="insidesPaid"
                      name="insidesPaid"
                      onChange={function (el) {
                        iHTBD(el.target.value, 'insidesPaid', index);
                      }}
                    >
                      <option key={index}>{el.insidesPaid}</option>
                      {yesNo.map(function (elm, i) {
                        if (el.insidesPaid != elm.val) {
                          return <option key={i}>{elm.val}</option>;
                        }
                      })}
                    </select> */}
                  </td>
                </tr>
              ))}
              <tr>
                <td>סך הכל</td>
                <td></td>
                <td className="warningMessage">{warningMessage}</td>
                <td>
                  <input
                    className="totalCustomerPriceSum"
                    value={totalCustomerPriceSum}
                    type="text"
                    disabled
                  />
                </td>
                <td>
                  <input
                    className="totalInnerHoursToBeDone"
                    value={totalInnerHoursToBeDone}
                    type="text"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ExtraPaymentsTable;
