import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  designDev: {
    staffMembers: '',
    peopleAmount: '',
    monthsAmount: '',
    hoursConversion: '',
    outsideHourPrice: '',
    estMonthlyPayment: '',
    insidePrice: '',
    insidePricing: '',
    outsidePrice: '',
    outsidePricing: '',
    insideProjectTotal: '',
    outsideProjectTotal: '',
    monthly: '',
    totalForProject: '',
  },
};

export const designDevSlice = createSlice({
  name: 'designdev',
  initialState,
  reducers: {
    designDevPush: (state, action) => {
      state.designDev = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { designDevPush } = designDevSlice.actions;

export default designDevSlice.reducer;
