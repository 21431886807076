import { Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Drawer from './Drawer';

const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(`calc(100vw - 76px)`);

  useEffect(() => {
    if (drawerOpen) setDrawerWidth(`calc(100vw - 240px)`);
    else setDrawerWidth(`calc(100vw - 76px)`);
  }, [drawerOpen]);

  return (
    <Paper style={{ height: '100vh', width: '100%', boxShadow: 'none' }}>
      <Drawer open={drawerOpen} setOpen={setDrawerOpen} />
      <div
        className='app'
        style={{ width: 'auto', marginLeft: `calc(100vw - ${drawerWidth})` }}
      >
        <Outlet />
      </div>
    </Paper>
  );
};

export default Layout;
