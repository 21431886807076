import React from 'react';
import AddMonthProject from '../components/mainTable/AddMonthProject';
import AddMonthWorkers from '../components/mainTable/AddMonthWorkers';
import DatePicker from '../components/mainTable/DatePicker';
import MainTable from '../components/mainTable/MainTable';

const Root = () => {
  return (
    <section style={{ margin: '10px 0' }}>
      <DatePicker />
      <MainTable />
    </section>
  );
};

export default Root;
