const ProgressBar = ({ values }) => (
  <div className='progress-wrapper'>
    <div>{values.value}</div>
    <div>
      <span>{Math.round(values.progressBarValue)}%</span>
      <progress id='file' value={values.progressBarValue} max='100'></progress>
    </div>
  </div>
);

export default ProgressBar;
