import { Typography, Grid, TextField, Button, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles';

const Input = styled('input')({
    display: 'none',
});

const EditBrand = ({
    brand,
    selectedLogo,
    selectedSignature,
    filePriceOne,
    filePriceTwo,
    inputHandler,
    editBrandHandler,
    cancel,
    currentBrand

}) => {
    const isBtnDisabled = false
    // brand.brandName.length < 1 || brand.brandID.length || brand.brandAddress.length < 1 || brand.brandPhoneNumber.length < 1 || brand.brandWebsite.length < 1 || !selectedLogo || !filePriceOne || !filePriceTwo

    return (
        <Grid
            container
            item
            direction='column'
            justifyContent='center'
            align='center'
            md={5}
            m={3}
            spacing={2}
        >
            <Typography variant="h4" my={2}>Edit information</Typography>
            <Grid
                container
                direction='row'
                justifyContent='space-around'
                align='center'
                component='form'
                onSubmit={editBrandHandler}
                spacing={{ xs: 2, md: 3 }}
                encType='multipart/form-data'
            >
                <Grid item xs={10} md={6}>
                    <TextField
                        required
                        id='brandName'
                        name='brandName'
                        label='Name'
                        type='text'
                        size='small'
                        fullWidth
                        value={brand.brandName}
                        onChange={inputHandler}
                    />
                </Grid>
                <Grid item xs={10} md={6}>
                    <TextField
                        required
                        id='brandID'
                        name='brandID'
                        label='ID'
                        type='number'
                        size='small'
                        fullWidth
                        value={brand.brandID}
                        onChange={inputHandler}
                    />
                </Grid>
                <Grid item xs={10} md={6}>
                    <TextField
                        required
                        id='brandAddress'
                        name='brandAddress'
                        label='Address'
                        type='text'
                        size='small'
                        fullWidth
                        value={brand.brandAddress}
                        onChange={inputHandler}
                    />
                </Grid>
                <Grid item xs={10} md={6}>
                    <TextField
                        required
                        id='brandEmail'
                        name='brandEmail'
                        label='Email'
                        type='email'
                        size='small'
                        fullWidth
                        value={brand.brandEmail}
                        onChange={inputHandler}
                    />
                </Grid>
                <Grid item xs={10} md={6}>
                    <TextField
                        required
                        id='brandPhoneNumber'
                        name='brandPhoneNumber'
                        label='Phone number'
                        type='number'
                        size='small'
                        fullWidth
                        value={brand.brandPhoneNumber}
                        onChange={inputHandler}
                    />
                </Grid>
                <Grid item xs={10} md={6}>
                    <TextField
                        required
                        id='brandWebsite'
                        name='brandWebsite'
                        label='Website'
                        type='text'
                        size='small'
                        fullWidth
                        value={brand.brandWebsite}
                        onChange={inputHandler}
                    />
                </Grid>
                <Grid item xs={10} md={12}>
                    <TextField
                        id='normalizeFactor'
                        name='normalizeFactor'
                        label='Normalize Factor'
                        type='number'
                        size='small'
                        fullWidth
                        onChange={inputHandler}
                    />
                </Grid>
                <Grid item xs={10} md={6}>
                    <Tooltip title="Select Image">
                        <label htmlFor='brandLogo'>
                            <Input
                                onChange={inputHandler}
                                accept='image/*'
                                id='brandLogo'
                                type='file'
                                name='brandLogo'
                                hidden

                            />
                            <Button variant='outlined' component='span' fullWidth>
                                Upload Logo
                            </Button>
                        </label>
                    </Tooltip>
                    <div>{selectedLogo ? selectedLogo.name : currentBrand.brandLogo}</div>
                </Grid>
                <Grid item xs={10} md={6}>
                    <Tooltip title="Select Image">
                        <label htmlFor='brandSignatureUrl'>
                            <Input
                                onChange={inputHandler}
                                accept='image/*'
                                id='brandSignatureUrl'
                                type='file'
                                name='brandSignatureUrl'
                                hidden
                            />
                            <Button variant='outlined' component='span' fullWidth>
                                Upload signature
                            </Button>
                        </label>
                    </Tooltip>
                    <div>{selectedSignature ? selectedSignature.name : currentBrand.brandSignature}</div>
                </Grid>

                <Grid item xs={10} md={6}>
                    <Tooltip title="Select File">
                        <label htmlFor='filePriceOne'>
                            <Input
                                onChange={inputHandler}
                                accept='.doc,.docx'
                                id='filePriceOne'
                                type='file'
                                name='filePriceOne'
                                hidden

                            />
                            <Button variant='outlined' component='span' fullWidth>
                                Upload File 1
                            </Button>
                        </label>
                    </Tooltip>
                    <div>{filePriceOne ? filePriceOne.name : currentBrand.filePriceOne}</div>
                </Grid>

                <Grid item xs={10} md={6}>
                    <Tooltip title="Select File">
                        <label htmlFor='filePriceTwo'>
                            <Input
                                onChange={inputHandler}
                                accept='.doc,.docx'
                                id='filePriceTwo'
                                type='file'
                                name='filePriceTwo'
                                hidden

                            />
                            <Button variant='outlined' component='span' fullWidth>
                                Upload File 2
                            </Button>
                        </label>
                    </Tooltip>
                    <div>{filePriceTwo ? filePriceTwo.name : currentBrand.filePriceTwo}</div>
                </Grid>

                <Grid item xs={10} md={6}>
                    <Grid container justifyContent='space-between'>
                        <Grid>
                            <Button variant='contained' type='submit' fullWidth disabled={isBtnDisabled}>Edit brand</Button>
                        </Grid>
                        <Grid>
                            <Button variant='outlined' type='button' fullWidth onClick={cancel}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EditBrand