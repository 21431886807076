import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pPTCPush } from '../../redux/mocks';
import {
  newAdva,
  updateAdva,
  getAdva,
  getDaysOff,
  setVacations,
} from '../../redux/advot';
import { getAdvot } from '../../redux/contracts';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { dataElement } from '../contracts/AddContract';
import { isLeapYear } from 'date-fns/esm';
import {
  updateContract,
  getContract,
  newContract,
} from '../../redux/contracts';

const BookKeeper = () => {
  const jsonE = useSelector((state) => state.jsonElement);
  //const Projects = useSelector((state) => state.projects);
  const advot = useSelector((state) => state.adva);
  const Vacations = useSelector((state) => state.adva.vacations);
  const [mainAdva, setMainAdva] = useState();
  const [milestones, setMilestones] = useState([]);
  const [followingPaymentsOrOneOffs, setFollowingPaymentsOrOneOffs] = useState(
    []
  );
  const [maintenance, setMaintenance] = useState([]);
  const [autoSave, setAutoSave] = useState('Auto Saving...');
  const [noSave, setNoSave] = useState('No Save - No Invoice #');
  const [currentElement, setCurrentElement] = useState(null);
  const [chosenMonth, setChosenMonth] = useState(null);

  const dispatch = useDispatch();
  const yesNo = [{ val: 'כן' }, { val: 'לא' }];
  //console.log(Projects);
  const GetAdvot = (e, month) => {
    console.log(month);
    let reqDate = moment()
      .format(month + '-01T00:00:00')
      .toString();
    console.log('Request Date: ', reqDate);
    dispatch(getDaysOff(reqDate))
      .unwrap(unwrapResult)
      .then((vacations) => {
        dispatch(setVacations(vacations));
        console.log('VACATIONS!: ', vacations);
        console.log('VACATIONS from redux!: ', Vacations);
        Vacations.map((x) => {
          console.log(x);
        });
      });
    dispatch(getAdvot(e))
      .unwrap(unwrapResult)
      .then((advotGot) => {
        setCurrentElement(advotGot);
        // dispatch(jsonEPush(adva));
        // setNewJsonE(adva);
        // setJsonElement(adva.jsonElement);
        console.log('response Advot', advotGot);
        const Advot = {
          milestones: [],
          followingPaymentsOrOneOffs: [],
          maintenance: [],
        };

        let advotClone = advotGot.map((x) => {
          let is = 0;
          let pPTCClone = x.pPTC.map((t) => {
            // console.log('theLine', t.updatedAt);
            let currentType = '';
            if (t.chargeCustomer === 'כן' && t.updatedAt === month) {
              if (t.paymentType === 'אבן דרך') {
                currentType = 'milestones';
              }
              if (t.paymentType === 'תשלום עוקב') {
                currentType = 'followingPaymentsOrOneOffs';
              }
              if (t.paymentType === 'תשלום חד פעמי') {
                currentType = 'followingPaymentsOrOneOffs';
              }
              if (t.paymentType === 'תשלום קבוע') {
                currentType = 'maintenance';
              }
              let accountingAffiliation = '';
              let ProjectName = '';
              let companyName;
              if (x.generalData) {
                accountingAffiliation = x.generalData.accountingAffiliation;
                ProjectName = x.generalData.projectName;
                companyName = x.generalData.companyName;
              }
              const newLine = {
                lineId: is,
                date: moment().format('YYYY-MM-DD'),
                updatedAt: t.updatedAt,
                //customerName: t.serviceType,
                customerName: companyName,
                projectName: ProjectName,
                projectId: x.project,
                action: 'תחזוקה',
                whatWasDone: t.serviceType,
                paymentType: t.paymentType,
                moneyWorth: t.monthlyPayment,
                hours: 'חודשי',
                projectPercentage: '100',
                accountingAffiliation: accountingAffiliation,
                invoiceOut: t.paymentOut,
                invoiceNumber: t.invoiceNumber,
                invoicePaid: t.paymentDone,
              };

              Advot[currentType]?.push(newLine);
            }

            ++is;
          });
          let i = 0;
          let jsonElementClone = x.jsonElement.map((t) => {
            // console.log(i);
            // console.log('t', t);
            let currentType = '';
            if (t.finished === 'כן' && t.updatedAt === month) {
              if (t.paymentType === 'אבן דרך') {
                currentType = 'milestones';
              }
              if (t.paymentType === 'תשלום עוקב') {
                currentType = 'followingPaymentsOrOneOffs';
              }
              if (t.paymentType === 'תשלום חד פעמי') {
                currentType = 'followingPaymentsOrOneOffs';
              }
              if (t.paymentType === 'תשלום קבוע') {
                currentType = 'maintenance';
              }
              let accountingAffiliation = '';
              let ProjectName = '';
              let companyName;
              if (x.generalData) {
                accountingAffiliation = x.generalData.accountingAffiliation;
                ProjectName = x.generalData.projectName;
                companyName = x.generalData.companyName;
              }
              const newLine = {
                lineId: i,
                date: moment().format('YYYY-MM-DD'),
                updatedAt: t.updatedAt,
                customerName: companyName,
                projectName: ProjectName,
                projectId: x.project,
                whatWasDone: t.month, //'אבן דרך/תשלום',
                action: 'אבן דרך/תשלום',
                paymentType: t.paymentType,
                moneyWorth: t.moneyWorth,
                hours: t.devHoursContract,
                projectPercentage: t.projectPercentage,
                accountingAffiliation: accountingAffiliation,
                invoiceOut: t.paymentOut,
                invoiceNumber: t.invoiceNumber,
                invoicePaid: t.paymentDone,
              };

              Advot[currentType].push(newLine);
              // console.log('nLAdvot', Advot);
            }
            ++i;
          });

          i = 0;
          let extraPaymentsclone = x.extraPayments.map((t) => {
            // console.log(i);
            // console.log('t', t);
            let currentType = '';
            if (t.finished === 'כן' && t.updatedAt === month) {
              if (t.paymentType === 'אבן דרך') {
                currentType = 'milestones';
              }
              if (t.paymentType === 'תשלום עוקב') {
                currentType = 'followingPaymentsOrOneOffs';
              }
              if (t.paymentType === 'תשלום חד פעמי') {
                currentType = 'followingPaymentsOrOneOffs';
              }
              if (t.paymentType === 'תשלום קבוע') {
                currentType = 'maintenance';
              }
              let accountingAffiliation = '';
              let ProjectName = '';
              let companyName;
              if (x.generalData) {
                accountingAffiliation = x.generalData.accountingAffiliation;
                ProjectName = x.generalData.projectName;
                companyName = x.generalData.companyName;
              }
              const newLine = {
                lineId: i,
                date: moment().format('YYYY-MM-DD'),
                updatedAt: t.updatedAt,
                customerName: companyName,
                projectName: ProjectName,
                projectId: x.project,
                whatWasDone: t.approveCustomerName + ' | ' + t.nameOfAddon,
                action: 'תכולות',
                paymentType: t.paymentType,
                moneyWorth: t.customerPriceSum,
                hours: t.innerHoursToBeDone,
                projectPercentage: 'לא ידוע',
                accountingAffiliation: accountingAffiliation,
                invoiceOut: t.insidesOut,
                invoiceNumber: t.invoiceNumber,
                invoicePaid: t.insidesPaid,
              };

              Advot[currentType].push(newLine);
            }
            ++i;
          });
        });
        setMainAdva(Advot);

        setMilestones([]);
        setMaintenance([]);
        setFollowingPaymentsOrOneOffs([]);
        setMilestones(Advot.milestones);
        setMaintenance(Advot.maintenance);
        setFollowingPaymentsOrOneOffs(Advot.followingPaymentsOrOneOffs);

        check(Advot.milestones, 'milestones');
        check(Advot.maintenance, 'maintenance');
        check(Advot.followingPaymentsOrOneOffs, 'followingPaymentsOrOneOffs');
      });

    //  console.log('advot', advot);
  };
  const GetMonthData = (e) => {
    console.log('MONTH: ', e);
    GetAdvot(
      {
        $or: [
          {
            'jsonElement.finished': 'כן',
            'jsonElement.updatedAt': e,
          },
          { 'pPTC.chargeCustomer': 'כן', 'pPTC.updatedAt': e },
          {
            'extraPayments.finished': 'כן',
            'extraPayments.updatedAt': e,
          },
        ],
      },
      e
    );
    // console.log(e);
    //PushAdva(e);
    // GetAdva(e);
    // console.log(advot);

    setChosenMonth(e);
  };

  useEffect(() => {
    console.log('vacations after get: ', Vacations);
    // const xhttp = new XMLHttpRequest();
    // xhttp.onload = function () {
    //   console.log(this.responseText);
    // };
    // xhttp.open('GET', 'http://yna-hours.test.com/index2.php');
    // xhttp.send();
  }, [Vacations]);

  const [defaultMonth, setDefaultMonth] = useState(
    moment().format('YYYY-MM').toString()
  );
  const loader = () => {
    setDefaultMonth(moment().format('YYYY-MM').toString());
    // console.log(moment().format('YYYY-MM').toString());
    // console.log(defaultMonth);
  };
  const updater = (e, b, index, lineId, projectId, whatWasDone) => {
    //   console.log('Updater', e, b, index, lineId, projectId);

    let warning = 0;
    dispatch(getContract(projectId))
      .unwrap(unwrapResult)
      .then((projectToUpdate) => {
        // console.log('lineUpdate', lineId);
        // console.log('PPP', projectToUpdate);
        var elementName = '';
        var elementOut = '';
        var elementDone = '';
        if (whatWasDone === 'תחזוקה') {
          elementName = 'pPTC';
          elementOut = 'paymentOut';
          elementDone = 'paymentDone';
        }
        if (whatWasDone === 'אבן דרך/תשלום') {
          elementName = 'jsonElement';
          elementOut = 'paymentOut';
          elementDone = 'paymentDone';
        }
        if (whatWasDone === 'תכולות') {
          elementName = 'extraPayments';
          elementOut = 'insidesOut';
          elementDone = 'insidesPaid';
        }

        if (b === 'invoiceOut') {
          projectToUpdate[elementName][lineId][elementOut] = e;
          if (projectToUpdate[elementName][lineId].invoiceNumber.length === 0) {
            warning = 1;
            projectToUpdate[elementName][lineId][elementOut] = 'לא';
          }
        }
        if (b === 'invoicePaid') {
          projectToUpdate[elementName][lineId][elementDone] = e;
          if (projectToUpdate[elementName][lineId].invoiceNumber.length === 0) {
            warning = 1;
            projectToUpdate[elementName][lineId][elementDone] = 'לא';
          }
        }
        if (b === 'invoiceNumber') {
          projectToUpdate[elementName][lineId].invoiceNumber = e;
        }

        //   if (b === 'invoiceOut') {
        //     projectToUpdate.pPTC[lineId].paymentOut = e;
        //     if (projectToUpdate.pPTC[lineId].invoiceNumber.length === 0) {
        //       warning = 1;
        //       projectToUpdate.pPTC[lineId].insidesOut = 'לא';
        //     }
        //   }
        //   if (b === 'invoicePaid') {
        //     projectToUpdate.pPTC[lineId].paymentDone = e;
        //   }
        //   if (b === 'invoiceNumber') {
        //     projectToUpdate.pPTC[lineId].invoiceNumber = e;
        //   }
        // }
        // if (whatWasDone === 'אבן דרך/תשלום') {
        //   elementName = 'jsonElement';
        //   if (b === 'invoiceOut') {
        //     projectToUpdate.jsonElement[lineId].paymentOut = e;
        //     if (
        //       projectToUpdate.jsonElement[lineId].invoiceNumber.length === 0
        //     ) {
        //       warning = 1;
        //       projectToUpdate.jsonElement[lineId].insidesOut = 'לא';
        //     }
        //   }
        //   if (b === 'invoicePaid') {
        //     projectToUpdate.jsonElement[lineId].paymentDone = e;
        //   }
        //   if (b === 'invoiceNumber') {
        //     projectToUpdate.jsonElement[lineId].invoiceNumber = e;
        //   }
        // }

        // if (whatWasDone === 'תכולות') {
        //   elementName = 'extraPayments';
        //   console.log('B', b);
        //   if (b === 'invoiceOut') {
        //     projectToUpdate.extraPayments[lineId].insidesOut = e;
        //     if (
        //       projectToUpdate.extraPayments[lineId].invoiceNumber.length === 0
        //     ) {
        //       warning = 1;
        //       projectToUpdate.extraPayments[lineId].insidesOut = 'לא';
        //     }
        //   }
        //   if (b === 'invoicePaid') {
        //     projectToUpdate.extraPayments[lineId].insidesPaid = e;
        //     if (
        //       projectToUpdate.extraPayments[lineId].invoiceNumber.length === 0
        //     ) {
        //       warning = 1;
        //       projectToUpdate.extraPayments[lineId].insidesPaid = 'לא';
        //     }
        //   }
        //   if (b === 'invoiceNumber') {
        //     projectToUpdate.extraPayments[lineId].invoiceNumber = e;
        //   }
        // }

        if (warning !== 1) {
          document.getElementsByClassName('autoSave')[0].style.display =
            'block';
          setTimeout(function () {
            document.getElementsByClassName('autoSave')[0].style.display =
              'none';
          }, 1900);
          dispatch(
            updateContract({
              ...projectToUpdate,
              ...{ project: projectId },
            })
          )
            .unwrap(unwrapResult)
            .then((contract) => {
              //console.log('updatedContract:', contract);
              //dispatch(jsonEPush(contract));
            });
        } else {
          document.getElementsByClassName('noSave')[0].style.display = 'block';
          setTimeout(function () {
            document.getElementsByClassName('noSave')[0].style.display = 'none';
          }, 1900);
          GetMonthData(chosenMonth);
        }
      });
  };

  const check = (something, name) => {
    console.log(name, something);
    console.log('Maintenance ', maintenance);
    console.log('followingPaymentsOrOneOffs ', followingPaymentsOrOneOffs);
    console.log('milestones ', milestones);
    if (something[0] === undefined) {
      document.getElementsByClassName(name)[0].style.display = 'none';
    } else {
      document.getElementsByClassName(name)[0].style.display =
        'table-header-group';
    }
  };
  return (
    <div className="rtl">
      <label className="aligned">
        <h2> בחר/י חודש </h2>
      </label>

      <input type="text" className="autoSave" value={autoSave} disabled />
      <input type="text" className="noSave" value={noSave} disabled />
      <input
        type="month"
        className="selectMonth aligned"
        // defaultValue={defaultMonth}
        onChange={function (e) {
          GetMonthData(e.target.value);
        }}
      />
      {chosenMonth && (
        <div>
          {/* <input
            type="button"
            className="selectProject"
            onClick={function (e) {
              GetMonthData(chosenMonth);
            }}
          /> */}
        </div>
      )}

      {mainAdva && (
        <div className="mainDiv">
          <div className="topDiv">
            <label>
              <h2>אבני דרך</h2>
            </label>
            <br></br>
            <table>
              <thead className="milestones">
                <tr>
                  <th>שם לקוח</th>
                  <th>שם פרוייקט</th>
                  <th>מה בוצע</th>
                  <th>אופי תשלום</th>
                  <th>סכום</th>
                  <th>שעות</th>
                  <th>אחוז מהפרוייקט</th>
                  <th>שיוך חשבונאי</th>
                  <th>חשבונית יצאה</th>
                  <th>מספר חשבונית</th>
                  <th>חשבונית שולמה</th>
                </tr>
              </thead>
              <tbody>
                {milestones.map((el, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        className="customerName"
                        defaultValue={el.customerName}
                        type="text"
                        disabled
                      />
                    </td>

                    <td>
                      <input
                        className="projectName"
                        defaultValue={el.projectName}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="whatWasDone"
                        value={el.whatWasDone}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="paymentType"
                        value={el.paymentType}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="moneyWorth"
                        value={el.moneyWorth}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="hours"
                        value={el.hours}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="projectPercentage"
                        value={el.projectPercentage + '%'}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="accountingAffiliation"
                        value={el.accountingAffiliation}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <select
                        className="invoiceOut"
                        name="invoiceOut"
                        onChange={function (e) {
                          updater(
                            e.target.value,
                            'invoiceOut',
                            index,
                            el.lineId,
                            el.projectId,
                            el.action
                          );
                        }}
                      >
                        <option key={index}>{el.invoiceOut}</option>
                        {yesNo.map(function (elm, i) {
                          if (el.invoiceOut !== elm.val) {
                            return <option key={i}>{elm.val}</option>;
                          }
                          return false;
                        })}
                      </select>
                    </td>
                    <td>
                      <input
                        className="invoiceNumber"
                        defaultValue={el.invoiceNumber}
                        type="text"
                        onChange={function (e) {
                          updater(
                            e.target.value,
                            'invoiceNumber',
                            index,
                            el.lineId,
                            el.projectId,
                            el.action
                          );
                        }}
                      />
                    </td>
                    <td>
                      <select
                        className="invoicePaid"
                        name="invoicePaid"
                        onChange={function (e) {
                          updater(
                            e.target.value,
                            'invoicePaid',
                            index,
                            el.lineId,
                            el.projectId,
                            el.action
                          );
                        }}
                      >
                        <option key={index}>{el.invoicePaid}</option>
                        {yesNo.map(function (elm, i) {
                          if (el.invoicePaid !== elm.val) {
                            return <option key={i}>{elm.val}</option>;
                          }
                          return false;
                        })}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="topDiv">
            <br></br>
            <label>
              <h2>תשלומים עוקבים/חד פעמי</h2>
            </label>
            <br></br>
            <table>
              <thead className="followingPaymentsOrOneOffs">
                <tr>
                  <th>שם לקוח</th>
                  <th>שם פרוייקט</th>
                  <th>מה בוצע</th>
                  <th>אופי תשלום</th>
                  <th>סכום</th>
                  <th>שעות</th>
                  <th>אחוז מהפרוייקט</th>
                  <th>שיוך חשבונאי</th>
                  <th>חשבונית יצאה</th>
                  <th>מספר חשבונית</th>
                  <th>חשבונית שולמה</th>
                </tr>
              </thead>
              <tbody>
                {followingPaymentsOrOneOffs.map((el, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        className="customerName"
                        defaultValue={el.customerName}
                        type="text"
                        disabled
                      />
                    </td>

                    <td>
                      <input
                        className="projectName"
                        defaultValue={el.projectName}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="whatWasDone"
                        value={el.whatWasDone}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="paymentType"
                        value={el.paymentType}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="moneyWorth"
                        value={el.moneyWorth}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="hours"
                        value={el.hours}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="projectPercentage"
                        value={el.projectPercentage + '%'}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="accountingAffiliation"
                        value={el.accountingAffiliation}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <select
                        className="invoiceOut"
                        name="invoiceOut"
                        onChange={function (e) {
                          updater(
                            e.target.value,
                            'invoiceOut',
                            index,
                            el.lineId,
                            el.projectId,
                            el.action
                          );
                        }}
                      >
                        <option key={index}>{el.invoiceOut}</option>
                        {yesNo.map(function (elm, i) {
                          if (el.invoiceOut !== elm.val) {
                            return <option key={i}>{elm.val}</option>;
                          }
                          return false;
                        })}
                      </select>
                    </td>
                    <td>
                      <input
                        className="invoiceNumber"
                        defaultValue={el.invoiceNumber}
                        type="text"
                        onChange={function (e) {
                          updater(
                            e.target.value,
                            'invoiceNumber',
                            index,
                            el.lineId,
                            el.projectId,
                            el.action
                          );
                        }}
                      />
                    </td>
                    <td>
                      <select
                        className="invoicePaid"
                        name="invoicePaid"
                        onChange={function (e) {
                          updater(
                            e.target.value,
                            'invoicePaid',
                            index,
                            el.lineId,
                            el.projectId,
                            el.action
                          );
                        }}
                      >
                        <option key={index}>{el.invoicePaid}</option>
                        {yesNo.map(function (elm, i) {
                          if (el.invoicePaid !== elm.val) {
                            return <option key={i}>{elm.val}</option>;
                          }
                          return false;
                        })}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="topDiv">
            <label>
              <h2>תחזוקות</h2>
            </label>
            <br></br>
            <table>
              <thead className="maintenance">
                <tr>
                  <th>שם לקוח</th>
                  <th>שם פרוייקט</th>
                  <th>מה בוצע</th>
                  <th>אופי תשלום</th>
                  <th>סכום</th>
                  <th>שעות</th>
                  <th>אחוז מהפרוייקט</th>
                  <th>שיוך חשבונאי</th>
                  <th>חשבונית יצאה</th>
                  <th>מספר חשבונית</th>
                  <th>חשבונית שולמה</th>
                </tr>
              </thead>
              <tbody>
                {maintenance.map((el, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        className="customerName"
                        defaultValue={el.customerName}
                        type="text"
                        disabled
                      />
                    </td>

                    <td>
                      <input
                        className="projectName"
                        defaultValue={el.projectName}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="whatWasDone"
                        value={el.whatWasDone}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="paymentType"
                        value={el.paymentType}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="moneyWorth"
                        value={el.moneyWorth}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="hours"
                        value={el.hours}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="projectPercentage"
                        value={el.projectPercentage + '%'}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        className="accountingAffiliation"
                        value={el.accountingAffiliation}
                        type="text"
                        disabled
                      />
                    </td>
                    <td>
                      <select
                        className="invoiceOut"
                        name="invoiceOut"
                        onChange={function (e) {
                          updater(
                            e.target.value,
                            'invoiceOut',
                            index,
                            el.lineId,
                            el.projectId,
                            el.action
                          );
                        }}
                      >
                        <option key={index}>{el.invoiceOut}</option>
                        {yesNo.map(function (elm, i) {
                          if (el.invoiceOut !== elm.val) {
                            return <option key={i}>{elm.val}</option>;
                          }
                          return false;
                        })}
                      </select>
                    </td>
                    <td>
                      <input
                        className="invoiceNumber"
                        defaultValue={el.invoiceNumber}
                        type="text"
                        onChange={function (e) {
                          updater(
                            e.target.value,
                            'invoiceNumber',
                            index,
                            el.lineId,
                            el.projectId,
                            el.action
                          );
                        }}
                      />
                    </td>
                    <td>
                      <select
                        className="invoicePaid"
                        name="invoicePaid"
                        onChange={function (e) {
                          updater(
                            e.target.value,
                            'invoicePaid',
                            index,
                            el.lineId,
                            el.projectId,
                            el.action
                          );
                        }}
                      >
                        <option key={index}>{el.invoicePaid}</option>
                        {yesNo.map(function (elm, i) {
                          if (el.invoicePaid !== elm.val) {
                            return <option key={i}>{elm.val}</option>;
                          }
                          return false;
                        })}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {Vacations[0] && mainAdva && (
        <div class="topDiv">
          <h2>חופשות/ימי מחלה ל {chosenMonth}</h2>
          <table>
            <thead>
              <tr>
                <th>עובד</th>
                <th>מחלה</th>
                <th>חופשה</th>
              </tr>
            </thead>
            <tbody>
              {Vacations.map((e, index) => (
                <tr>
                  <td>
                    <input value={e.user} disabled />
                  </td>
                  <td>
                    <input value={e.sick} disabled />
                  </td>
                  <td>
                    <input value={e.vacation} disabled />
                  </td>
                </tr>
              ))}
              ;
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BookKeeper;
