import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  milestones: [
    {
      devHoursContract: '',
      finished: 'לא',
      updatedAt: '',
      innerHoursToBeDone: '',
      moneyWorth: '',
      month: '',
      paymentDone: '',
      paymentOut: '',
      paymentType: '',
      designDev: '',
      projectPayment: '',
      projectPercentage: '',
      invoiceNumber: '',
    },
  ],
};

export const mileStoneSlice = createSlice({
  name: 'milestone',
  initialState,
  reducers: {
    mileStonesPush: (state, action) => {
      state.milestones = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { mileStonesPush } = mileStoneSlice.actions;

export default mileStoneSlice.reducer;
