import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../api/data";

export const fetchTasks = createAsyncThunk('tasks/fetch',
async () => {
    try {
        const data = await getData('tasks');
        return data
    } catch (err) {

    }
})

export const initialState = {
	loading: "idle",
	data: [],
};

const tasksSlice = createSlice({
	name: "tasks",
	initialState,
	reducers: {},
    extraReducers: {
        [fetchTasks.pending]: (state) => {
            state.loading = 'pending'
        },
        [fetchTasks.fulfilled]: (state, { payload }) => {
            state.loading = 'idle'
            state.data = payload
        },
        [fetchTasks.rejected]: (state) => {
            state.loading = 'idle'
            console.log("error fetching tasks")
        }
    }
});

export const selectTasks = ({ tasks }) => {
    const isLoading = tasks.loading === 'pending'
    return { isLoading, tasks: tasks.data }
}

const { reducer, actions } = tasksSlice;

export default reducer;
