import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Spinner({ style }) {
	return (
		<Box
			sx={{
				display: "flex",
				width: "100%",
				justifyContent: "center",
				left: 0,
				margin: '50px 0',
			}}
			style={style}
		>
			<CircularProgress />
		</Box>
	);
}
