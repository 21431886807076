import React, { useState, useEffect } from 'react';
import NewTable from './TopTable';
import { useDispatch, useSelector } from 'react-redux';
import ExtraPaymentsTable from './ExtraPaymentsTable';
import PricingTables from './PricingTables';
import moment from 'moment';
import PermanentPaymentsToCollect from './pPaymentsToCollect';
import {
  extraPaymentsPush,
  jsonEPush,
  initialState,
  jsonElementPush,
  generalPush,
  pPTCPush,
  fix,
  insidePricing,
  baseHoursPush,
  designdevPush,
} from '../../redux/mocks';
import { mileStonesPush } from '../../redux/milestones';
import { insidesPricesPush } from '../../redux/insidepricing';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  updateContract,
  getContract,
  newContract,
  projectContractBudgetPush,
} from '../../redux/contracts';
import { newAdva, getAdva, updateAdva } from '../../redux/advot';
import { EightK, InfoSharp } from '@mui/icons-material';
import { projectsEntities } from '../../redux/projects';
import { fixPricePush } from '../../redux/fixpricing';
import { generalDataPush } from '../../redux/general';
import { extrapaymentsPush } from '../../redux/extrapayments';
import { pPTCPUSH } from '../../redux/pptc';
import {
  totalProjectHoursPush,
  totalHumanMonthsPush,
} from '../../redux/contracts';
import { designDevPush } from '../../redux/designdev';
import { iP } from './PricingTables';
export var bH = null;
export var dataElement = null;
export var GOT = 0;

const AddContract = () => {
  const Counter = useSelector((state) => state.counter);
  const jsonE = useSelector((state) => state.jsonElement);
  //const Projects = useSelector((state) => state.projects);
  const mileStones = useSelector((state) => state.milestone);
  const pptc = useSelector((state) => state.pptc);
  const extrapayments = useSelector((state) => state.extrapayments);
  const fixPricing = useSelector((state) => state.fixpricing);
  const insidePrice = useSelector((state) => state.insidepricing);
  const generalData = useSelector((state) => state.generaldata);
  const { projects } = useSelector(projectsEntities);
  const designDevElement = useSelector((state) => state.designdev);
  const dispatch = useDispatch();

  const [totalInsides, setTotalInsides] = useState(0);
  const [totalInsidesPaid, setTotalInsidesPaid] = useState(0);
  const [jsonElement, setJsonElement] = useState(mileStones.milestones);
  const [generalElement, setGeneralElement] = useState(jsonE.generalData);
  const [extraPayments, setExtraPayments] = useState(
    extrapayments.extraPayments
  );

  // const [insidePricingElement, setInsidePricingElement] = useState(
  //   jsonE.insidePricingElement
  // );
  const [milestones, setMilestones] = useState(mileStones);
  const [fixElement, setFixElement] = useState(jsonE.fixElement);
  const [projectContractBudget, setInputP] = useState();
  const [totalPaymentsGiven, setInputT] = useState();
  const [totalProjectHoursLeft, setTotalProjectHoursLeft] = useState();
  const [totalPercentages, setTotalPercentages] = useState();
  const [totalPayments, setTotalPayments] = useState();
  const [totalMoneyWorth, setTotalMoneyWorth] = useState();
  const insidePricingElement = useSelector((state) => state.insidepricing);
  const [totalDevHoursContract, setTotalDevHoursContract] = useState();
  const [totalInnerHoursToBeDone, seTotalInnerHoursToBeDone] = useState();
  const [totalFinished, setTotalFinished] = useState();
  const [totalPaymentDone, seTotalPaymentDone] = useState();
  const [totalProjectHours, setTotalProjectHours] = useState();
  const [totalPaymentOut, setTotalPaymentOut] = useState();
  const [selectedProject, setSelectedProject] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [totalHumanMonths, setTotalHumanMonths] = useState();
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);
  const [totalHoursDone, setTotalHoursDone] = useState(null);
  const [totalHoursLeft, setTotalHoursLeft] = useState(null);
  const [newJsonE, setNewJsonE] = useState(null);
  const [accountingAffiliation, setAccountingAffiliation] =
    useState('PITANGO UX');
  const [baseHours, setBaseHours] = useState(jsonE.generalData.baseHours);
  const [designPrice, setDesignPrice] = useState();

  const dataPayment = [
    { name: 'אבן דרך' },
    { name: 'תשלום עוקב' },
    { name: 'תשלום קבוע' },
    { name: 'תשלום חד פעמי' },
  ];

  const designDev = [{ name: 'עיצוב' }, { name: 'פיתוח' }];

  const yesNo = [{ val: 'כן' }, { val: 'לא' }];

  const [count, setCount] = useState(0);
  //console.log(projects);

  const add = (name) => {
    // console.log(name);
    setCount((c) => c + 1);
    let clone = mileStones.milestones.map((x) => {
      return { ...x };
    });

    if (name === 'mainTable') {
      clone.push({
        devHoursContract: '',
        finished: 'לא',
        updatedAt: '',
        innerHoursToBeDone: '',
        moneyWorth: '',
        month: '',
        paymentDone: '',
        paymentOut: '',
        paymentType: '',
        designDev: '',
        projectPayment: '',
        projectPercentage: '',
        invoiceNumber: '',
      });
    }
    dispatch(mileStonesPush(clone));
    setJsonElement(clone);
  };

  const payments = (e, b, index) => {
    let extraPaymentsElementClone = extrapayments.extraPayments.map((x) => {
      return { ...x };
    });

    let proPercentages = 0;
    //let mileStonesCounter = 0;

    let clone = mileStones.milestones.map((x) => {
      proPercentages += Number(x.projectPercentage);
      if (b === 'projectPercentage' && Number(e) > 0) {
        proPercentages += Number(e);
      }
      // if(x.designDev === 'פיתוח'){
      //   ++totalHoursConversionDesignDev;
      // }

      //++mileStonesCounter;
      return { ...x };
    });

    if (
      index !== undefined &&
      // index !== null &&
      b === 'projectPercentage' &&
      Number(e) > 0
    ) {
      clone[index][b] = Number(e);
    }

    let ic = 0;

    if (b === null && index === null) {
    }

    let insidePricingElementClone = jsonE.insidePricingElement.map((x) => {
      if (b === 'designDev' && e === 'עיצוב') {
        console.log(e, b, index);
        // setDesignPrice(Number(x.insidePricing));
        console.log(generalData.generalData.designPrice);
        // clone[index].innerHoursToBeDone = Math.round(
        //   Number(clone[index].moneyWorth) / Number(x.insidePricing)
        // );
        clone[index].innerHoursToBeDone = Math.round(
          (Number(clone[index].moneyWorth) /
            Number(generalData.generalData.designPrice)) *
            0.7
        );
      }
      if (b === 'designDev' && e === 'פיתוח') {
        // console.log(index, ic);
        if (index === ic) {
          clone[index].innerHoursToBeDone = Math.round(
            (Number(clone[index].moneyWorth) / Number(x.insidePricing)) * 0.7
          );
        }
      }
      ++ic;
      return { ...x };
    });

    var allOutPayments = 0;
    var allOutPaymentsDone = 0;
    var allInnerHoursToBeDone = 0;
    var allDevHoursContract = 0;
    var allPercents = 0;
    var allMoneyWorth = 0;

    if (e !== null && index !== null && b !== null) {
      clone[index][b] = e;

      if (e.toString().search('%') > 0) {
        e = e.replace('%', '') * 0.01;
      }

      if (b === 'projectPercentage') {
        if (projectContractBudget !== undefined) {
          console.log((e / 100) * projectContractBudget);
          clone[index].moneyWorth = (e / 100) * projectContractBudget;
        }
      }

      if (b === 'moneyWorth') {
        clone[index]['moneyWorth'] = e;
        if (clone[index].designDev === 'עיצוב') {
          //console.log(generalData.generalData.designPrice);
          if (generalData.generalData.designPrice !== undefined) {
            clone[index]['innerHoursToBeDone'] = Math.round(
              (e / Number(generalData.generalData.designPrice)) * 0.7
            );
          } else {
            clone[index]['innerHoursToBeDone'] =
              'לחישוב זה יש למלא את תמחור פנימי שעתי';
          }
        } else {
          if (insidePricingElementClone[index] === undefined) {
            setWarningMessage(
              'חובה למלא שדה מחיר מקביל - בהצעת מחיר ותמחור פנימי!'
            );
            clone[index]['innerHoursToBeDone'] = '';
          }
          if (insidePricingElementClone[index].insidePricing !== undefined) {
            clone[index]['innerHoursToBeDone'] = Math.round(
              (e / Number(insidePricingElementClone[index].insidePricing)) * 0.7
            );
          } else {
            clone[index]['innerHoursToBeDone'] =
              'לחישוב זה יש למלא את תמחור פנימי שעתי';
          }
        }
      }
    }

    let generalClone = { ...generalData.generalData };

    let totalProjectHours = 0;
    let ifc = 0;
    let totalHoursConversionDesignDev = 0;
    let someclone = mileStones.milestones.map((x) => {
      console.log('IFC: ', ifc);
      if (clone[ifc] !== undefined) {
        if (clone[ifc].designDev === 'פיתוח') {
          if (fixPricing.fixPricing[ifc] !== undefined) {
            totalProjectHours += Number(
              fixPricing.fixPricing[ifc].hoursConversion
            );
            setWarningMessage('');
          } else {
            totalProjectHours += Number(
              fixPricing.fixPricing[0].hoursConversion
            );
            // setWarningMessage('אין שורה עם ערך מתאים משתמש בראשונה');
          }
        }
        // console.log('fix: ', fixPricing.fixPricing[ifc]);
        // console.log('DESIGN!!!L', clone[ifc].designDev);
        if (clone[ifc].designDev === 'עיצוב') {
          totalHoursConversionDesignDev = 1;
          totalProjectHours += designDevElement.designDev.hoursConversion;
        }
      }

      ifc++;

      return { ...x };
    });
    if (totalHoursConversionDesignDev === 1) {
    }
    // console.log('ifc: ', ifc);
    // if (clone[ifc] !== undefined) {
    //   if (clone[ifc].designDev === 'עיצוב') {
    //     totalProjectHours += designDevElement.designDev.hoursConversion;
    //     console.log('plusDesign', totalProjectHours);
    //   }
    // }
    //console.log('totalProjectHour', totalProjectHours);
    //console.log('totalProjectHours: ', totalProjectHours);
    setTotalProjectHoursLeft(totalProjectHours - totalHoursDone);
    generalClone.totalProjectHoursLeft = totalProjectHours - totalHoursDone;
    generalClone.totalProjectHours = totalProjectHours;

    setTotalProjectHours(totalProjectHours);

    if (proPercentages === 0) {
      if (projectContractBudget !== undefined) {
        for (let i = 0; i < clone.length; i++) {
          clone[i].moneyWorth = projectContractBudget / clone.length;
          if (clone[i].designDev === 'עיצוב') {
            clone[i].innerHoursToBeDone = Math.round(
              (Number(clone[i].moneyWorth) / Number(generalClone.designPrice)) *
                0.7
            );
          }
          if (clone[i].designDev === 'פיתוח') {
            clone[i].innerHoursToBeDone = Math.round(
              (Number(clone[i].moneyWorth) /
                Number(insidePricingElementClone[i].insidePricing)) *
                0.7
            );
          }
          clone[i].projectPercentage = 100 / Number(clone.length);
        }
      }
    } else {
      //for (let i = 0; i < clone.length; i++) {
      if (index !== null) {
        if (
          i !== index &&
          index !== undefined &&
          // index !== null &&
          clone[index].projectPercentage === ''
        ) {
          clone[index].moneyWorth = 0;
        } else {
          clone[index].moneyWorth =
            (Number(clone[index].projectPercentage) / 100) *
            projectContractBudget;
        }
        if (clone[index].designDev === 'עיצוב') {
          clone[index].innerHoursToBeDone = Math.round(
            (Number(clone[index].moneyWorth) /
              Number(generalClone.designPrice)) *
              0.7
          );
        }
        if (clone[index].designDev === 'פיתוח') {
          if (insidePricingElementClone[index] !== undefined) {
            clone[index].innerHoursToBeDone = Math.round(
              (Number(clone[index].moneyWorth) /
                Number(insidePricingElementClone[index].insidePricing)) *
                0.7
            );
          } else {
            if (insidePricingElementClone[index] === undefined) {
              clone[index].innerHoursToBeDone = Math.round(
                (Number(clone[index].moneyWorth) /
                  Number(insidePricingElementClone[0].insidePricing)) *
                  0.7
              );
              //setWarningMessage('אין שורה עם ערך מתאים משתמש בראשונה');
            }
            //
          }
        }
        // }
      }
    }

    for (const key in clone) {
      if (clone.hasOwnProperty(key)) {
        allInnerHoursToBeDone += Number(clone[key].innerHoursToBeDone);
        allDevHoursContract += Number(clone[key].devHoursContract);
        allPercents += Number(clone[key].projectPercentage);
        allMoneyWorth += Number(clone[key].moneyWorth);

        if (clone[key].paymentOut === 'כן') {
          allOutPayments += Number(clone[key].moneyWorth);
        }
        if (clone[key].paymentDone === 'כן') {
          allOutPaymentsDone += Number(clone[key].moneyWorth);
        }
      }
    }

    projects.map((p) => {
      if (p._id === selectedProject) {
        setSelectedProjectName(p.name);
        setSelectedCompanyName(p.company?.name);
        setTotalHoursDone(p.projectTableData.totalHours);
        generalClone.totalHoursDone = totalHoursDone;
      }
    });

    if (b === 'finished') {
      let currentType = '';
      clone.map((x) => {
        if (x.finished === 'כן') {
          if (x.paymentType === 'אבן דרך') {
            currentType = 'milestones';
          }
          if (x.paymentType === 'תשלום עוקב') {
            currentType = 'followingPaymentsOrOneOffs';
          }
          if (x.paymentType === 'תשלום חד פעמי') {
            currentType = 'followingPaymentsOrOneOffs';
          }
          if (x.paymentType === 'תשלום קבוע') {
            currentType = 'maintenance';
          }

          let currentMonth = moment().format('YYYY-MM');
          clone[index]['updatedAt'] = currentMonth;
        }
      });
    }

    var all = document.getElementsByClassName('projectPayment');
    var allPayments = 0;
    for (var i = 0; i < all.length; i++) {
      allPayments = allPayments + 1;
    }

    if (baseHours === undefined) {
      setBaseHours(180);
      dispatch(baseHoursPush(180));
      generalClone.baseHours = 180;
      bH = 180;
    }

    if (b === 'baseHours') {
      dispatch(baseHoursPush(Number(e)));
      generalClone.baseHours = Number(e);
      bH = e;
    }

    if (b === 'accountingAffiliation') {
      setAccountingAffiliation(e);
      generalClone.accountingAffiliation = e;
    }
    if (b === 'projectContractBudget') {
      // console.log('CLOMNE', clone);
      // for (const [key, value] of Object.entries(clone)) {
      //   if (value.designDev === 'עיצוב') {
      //     clone[key].innerHoursToBeDone = Math.round(
      //       (Number(clone[key].moneyWorth) / Number(generalClone.designPrice)) *
      //         0.7
      //     );
      //   }
      //   if (value.designDev === 'פיתוח') {
      //     console.log(insidePricingElementClone);
      //     clone[key].innerHoursToBeDone = Math.round(
      //       (Number(clone[key].moneyWorth) /
      //         Number(insidePricingElementClone[key].insidePricing)) *
      //         0.7
      //     );
      //     console.log(
      //       Number(clone[key].moneyWorth),
      //       ' / ',
      //       Number(insidePricingElementClone[key].insidePricing),
      //       ' * ',
      //       0.7
      //     );
      //   }
      // }
      setInputP(Number(e));
      generalClone.projectContractBudget = Number(e);
      dispatch(projectContractBudgetPush(generalClone.projectContractBudget));
    }

    if (generalClone.accountingAffiliation === undefined) {
      //alert('Pitango');
      setAccountingAffiliation('PITANGO UX');
      generalClone.accountingAffiliation = 'PITANGO UX';
    }

    generalClone.projectName = selectedProjectName;
    generalClone.companyName = selectedCompanyName;

    setGeneralElement(generalClone);
    //clone.generalData = generalClone;

    if (allMoneyWorth > 0) {
      setTotalMoneyWorth(Math.floor(allMoneyWorth) + ' ש"ח');
    } else {
      setTotalMoneyWorth('');
    }
    if (allPercents > 0) {
      setTotalPercentages(allPercents + '%');
      if (allPercents > 100) {
        setWarningMessage('Exceeds 100%');
        document.getElementById('updateProject').disabled = true;
        document.getElementById('updateProject').style.color = 'red';
      } else {
        document.getElementById('updateProject').disabled = false;
        document.getElementById('updateProject').style.color = 'white';
      }
    } else {
      setTotalPercentages('');
    }
    if (allDevHoursContract > 0) {
      setTotalDevHoursContract(allDevHoursContract);
    } else {
      setTotalDevHoursContract('');
    }
    if (allInnerHoursToBeDone > 0) {
      seTotalInnerHoursToBeDone(allInnerHoursToBeDone);
    } else {
      setWarningMessage('אין מחיר פנימי מקביל לחישוב!');
      seTotalInnerHoursToBeDone('');
    }
    if (allPayments > 0) {
      setTotalPayments(allPayments);
    } else {
      setTotalPayments('');
    }
    setTotalPaymentOut(allOutPayments);
    seTotalPaymentDone(allOutPaymentsDone);
    setJsonElement(clone);

    setMilestones(clone);
    dispatch(mileStonesPush(clone));
    const clonedObj = JSON.parse(JSON.stringify(clone));
    dispatch(extrapaymentsPush(extraPaymentsElementClone));
    dispatch(extraPaymentsPush(extraPaymentsElementClone));
    dispatch(pPTCPUSH(pptc.pPTC));
    dispatch(pPTCPush(pptc.pPTC));
    setExtraPayments(extraPaymentsElementClone);
    dispatch(generalDataPush(generalClone));
    dispatch(generalPush(generalClone));
    dispatch(designdevPush(designDevElement.designDev));
    dispatch(jsonElementPush(clonedObj));
    dispatch(jsonEPush(jsonE));
  };

  useEffect(() => {
    payments(null, null, null);

    // devHoursContract();
    setInputT(Counter['insidesOut'] + totalPaymentOut);
    setTotalFinished(Counter['insidesPaid'] + totalPaymentDone);
    setTotalInsides(Counter['insidesOut']);
    setTotalInsidesPaid(Counter['insidesPaid']);
    setTotalHumanMonths(Counter['totalHumanMonths']);
    // setTotalProjectHours(Counter['totalProjectHours']);
    if (Counter['projectContractBudget'] > 0) {
      setInputP(Counter['projectContractBudget']);
    }
    //console.log(designDev.designDev);
  }, [
    Counter,
    totalPaymentOut,
    totalPaymentDone,
    totalInsides,
    totalInsidesPaid,
    GOT,
    iP,
    generalData.generalData.designPrice,
  ]);

  const pushProject = (e) => {
    setTimeout(console.log('Creating: ', jsonE), 1000);

    //alert(selectedProject);
    dispatch(
      newContract({
        ...jsonE,
        ...{ project: selectedProject },
      })
    )
      .unwrap(unwrapResult)
      .then((contract) => {
        if (contract === 'Exists!') {
          alert('Contract already exists!');
        } else {
          dispatch(jsonEPush(contract));
          dispatch(fix(contract.fixElement));
          dispatch(pPTCPush(contract.pPTC));
          dispatch(pPTCPUSH(jsonE.pPTC));
          dispatch(insidePricing(contract.insidePricingElement));
          dispatch(extrapaymentsPush(jsonE.extraPayments));
          dispatch(mileStonesPush(jsonE.jsonElement));
          setNewJsonE(contract);
          setJsonElement(contract.jsonElement);
          setExtraPayments(contract.extraPayments);
          setFixElement(contract.fixElement);
          setAccountingAffiliation(contract.generalData.accountingAffiliation);
          dataElement = contract;
          document.getElementById('updateProject').style.display =
            'inline-grid';
          document.getElementById('finishedTop').style.display = 'none';
          document.getElementById('getProject').style.display = 'none';
          document.getElementById('getOtherProject').style.display =
            'inline-grid';
          document.getElementById('saveProject').style.display = 'none';
        }
      });
  };

  const GetAdvaData = (e, callback) => {
    dispatch(getContract(selectedProject))
      .unwrap(unwrapResult)
      .catch((error) => {
        console.log(error);
        alert('No contract found, you may create one.');
        document.getElementById('saveProject').style.display = 'inline-grid';
      })
      .then((contract) => {
        // console.log('GOTForAdva', contract);
        // console.log('mileStones', mileStones.milestones);
        // console.log('JJJJ', jsonE);

        let newJsonClone = JSON.parse(JSON.stringify(jsonE));

        let mileStonesClone = jsonE.jsonElement.map((x) => {
          return { ...x };
        });

        let pPTCClone = jsonE.pPTC.map((x) => {
          return { ...x };
        });

        let extraPaymentsClone = jsonE.extraPayments.map((x) => {
          return { ...x };
        });

        let i = 0;
        contract.jsonElement.forEach((element) => {
          mileStonesClone[i].paymentOut = element.paymentOut;
          mileStonesClone[i].paymentDone = element.paymentDone;
          mileStonesClone[i].invoiceNumber = element.invoiceNumber;
          ++i;
        });
        i = 0;
        contract.pPTC.forEach((element) => {
          pPTCClone[i].paymentOut = element.paymentOut;
          pPTCClone[i].paymentDone = element.paymentDone;
          pPTCClone[i].invoiceNumber = element.invoiceNumber;
          ++i;
        });
        i = 0;
        contract.extraPayments.forEach((element) => {
          extraPaymentsClone[i].insidesOut = element.insidesOut;
          extraPaymentsClone[i].insidesPaid = element.insidesPaid;
          extraPaymentsClone[i].invoiceNumber = element.invoiceNumber;
          ++i;
        });
        // console.log('mileStonesClone', mileStonesClone);
        // console.log('pPTCClone', pPTCClone);
        // console.log('extraPaymentsClone', extraPaymentsClone);
        newJsonClone.jsonElement = mileStonesClone;
        newJsonClone.pPTC = pPTCClone;
        newJsonClone.extraPayments = extraPaymentsClone;
        // console.log('NEWJSONCLONE', newJsonClone);

        callback(newJsonClone);
        // payments(null, null, null);
      });
  };

  const checkAll = (e) => {
    projects.map((p) => {
      dispatch(getContract(p._id))
        .unwrap(unwrapResult)
        .catch((error) => {
          // console.log(error);
          console.log('No contract found, you may create one.');
          // document.getElementById('saveProject').style.display = 'inline-grid';
        })
        .then((contract) => {
          if (contract.project === p._id) {
            console.log(document.querySelector('select'));
            console.log('GOTCHA!');
            document.getElementById(contract.project).textContent +=
              ' | Has Contract!';
          }
          console.log('GOT', contract);
          console.log('gotcha', p._id);
        });
    });
  };

  const GetProject = (e) => {
    //alert(selectedProject);
    dispatch(getContract(selectedProject))
      .unwrap(unwrapResult)
      .catch((error) => {
        console.log(error);
        alert('No contract found, you may create one.');
        document.getElementById('saveProject').style.display = 'inline-grid';
      })
      .then((contract) => {
        console.log('GOT', contract);
        //  console.log('designDev', designDevElement);
        GOT += 1;
        dispatch(jsonEPush(contract));
        dispatch(fix(contract.fixElement));
        dispatch(jsonElementPush(contract.jsonElement));
        dispatch(pPTCPush(contract.pPTC));
        dispatch(pPTCPUSH(contract.pPTC));
        dispatch(designDevPush(contract.designDev));
        dispatch(designdevPush(contract.designDev));
        dispatch(insidePricing(contract.insidePricingElement));
        dispatch(extraPaymentsPush(contract.extraPayments));
        //setTotalFinished(contract.generalData.totalFinished);
        setMilestones(contract.jsonElement);
        dispatch(mileStonesPush(contract.jsonElement));
        //setFixPricing(contract.fixElement);
        dispatch(generalPush(contract.generalData));
        dispatch(generalDataPush(contract.generalData));
        setGeneralElement(contract.generalData);
        dispatch(fixPricePush(contract.fixElement));
        dispatch(extrapaymentsPush(contract.extraPayments));
        setSelectedProjectName(contract.generalData.projectName);
        setBaseHours(contract.generalData.baseHours);
        setTotalProjectHours(contract.generalData.totalProjectHours);
        dispatch(totalProjectHoursPush(contract.generalData.totalProjectHours));
        dispatch(totalHumanMonthsPush(contract.generalData.totalHumanMonths));
        dispatch(
          projectContractBudgetPush(
            contract.generalData.projectContractBudgetPush
          )
        );
        setInputP(contract.generalData.projectContractBudget);

        setNewJsonE(contract);
        dispatch(insidesPricesPush(contract.insidePricingElement));

        setJsonElement(contract.jsonElement);
        // setInsidePricingElement(contract.insidePricingElement);
        setExtraPayments(contract.extraPayments);
        setFixElement(contract.fixElement);
        setAccountingAffiliation(contract.generalData.accountingAffiliation);
        dataElement = contract;
        document.getElementById('getProject').style.display = 'none';
        document.getElementById('getOtherProject').style.display =
          'inline-grid';
        document.getElementById('updateProject').style.display = 'inline-grid';
        document.getElementById('finishedTop').style.display = 'none';
        document.getElementById('saveProject').style.display = 'none';
      });
  };

  const updateProject = (e) => {
    GetAdvaData(selectedProject, function (newJson) {
      dispatch(pPTCPUSH(newJson.pPTC));
      dispatch(pPTCPush(newJson.pPTC));
      dispatch(mileStonesPush(newJson.jsonElement));
      dispatch(jsonElementPush(newJson.jsonElement));
      dispatch(extraPaymentsPush(newJson.extraPayments));
      dispatch(extrapaymentsPush(newJson.extraPayments));
      dispatch(jsonEPush(newJson));
      if (Number(totalPercentages.replace('%', '')) > 100) {
        alert('Saving is not allowed with more than 100%');
        return false;
      }

      alert('מעדכן פרוייקט');
      console.log('THE JSON TO UPDATE: ', newJson);
      dispatch(
        updateContract({
          ...newJson,
          ...{ project: selectedProject },
        })
      )
        .unwrap(unwrapResult)
        .then((contract) => {
          console.log('Project ID:', selectedProject);
          // console.log('ToUpdate: ', newJson);

          //dispatch(jsonEPush(contract));
        });
    });
  };

  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        // console.log('click');
        break;
      case 2:
        // console.log('double click');
        e.target.disabled = false;
        break;
      case 3:
        // console.log('triple click');
        break;
      default:
        return;
    }
  };

  return (
    // console.log('Projects: ',projects),
    <div className="app-container" id="contracts">
      <button onClick={(e) => checkAll(e.target.value)}>button</button>
      <select
        onChange={(e) => setSelectedProject(e.target.value)}
        id="finishedTop"
        className="finishedTop"
        name="finished"
      >
        <option className="finishedTop">Select Project</option>
        {projects.map(function (elm, i) {
          console.log('Project: ', elm);
          console.log('JSONE: ', jsonE);
          let elmCompany;
          if (elm.company === null) {
            elmCompany = 'NoName';
          } else {
            elmCompany = elm.company?.name;
          }
          return (
            <option className="finishedTop" id={elm.id} value={elm.id} key={i}>
              {elm.name} | {elmCompany}
            </option>
          );
        })}
      </select>

      <input
        type="submit"
        id="saveProject"
        value="Create Contract"
        onClick={function (e) {
          pushProject(e.target.value);
        }}
      />
      <input
        type="submit"
        id="getProject"
        value="GET Contract"
        onClick={(e) => GetProject(e.target.value)}
      />
      <input
        type="submit"
        id="getOtherProject"
        value="Get a different contract"
        onClick={function (e) {
          // setInputP('00');
          //setJsonElement([{}]);
          // setInsidePricingElement([{}]);
          // setFixElement([{}]);
          // dispatch(fix([{}]));
          // dispatch(insidePricing([{}]));
          // dispatch(jsonEPush([{}]));
          // console.log(jsonE);
          // setNewJsonE([{}]);
          //etProject(e.target.value);
          window.location.reload();
        }}
      />
      <input
        type="submit"
        id="updateProject"
        value="Update Contract"
        onClick={(e) => updateProject(e.target.value)}
      />
      <br></br>
      {newJsonE && (
        <div className="topDivs">
          <label>
            <h2>
              {selectedProjectName} | {selectedCompanyName}
            </h2>
          </label>
          <div className="rowS">
            <div className="inputDiv">
              <label>תקציב פרוייקט בכסף - הסכום שנסגר בחוזה:</label>
              <br></br>
              <input
                type="number"
                className="projectContractBudget"
                value={projectContractBudget}
                onChange={(e) =>
                  payments(e.target.value, 'projectContractBudget', null)
                }
              />
            </div>
            <div className="inputDiv">
              <label>סך הכל חודשי אדם:</label>
              <br></br>
              <input
                className="totalHumanMonths"
                value={totalHumanMonths}
                type="text"
                disabled
              />
            </div>
            <div className="inputDiv">
              <label>סך הכל השעות של הפרוייקט:</label>
              <br></br>
              <input
                className="totalProjectHours"
                value={totalProjectHours}
                type="text"
                disabled
              />
            </div>
            <div className="inputDiv">
              <label>סך הכל השעות שבוצעו</label>
              <br></br>
              <input
                className="totalHoursDone"
                value={totalHoursDone}
                type="text"
                disabled
              />
            </div>
            <div className="inputDiv">
              <label>סך הכל השעות שנותרו לפרוייקט</label>
              <br></br>
              <input
                className="totalProjectHoursLeft"
                value={totalProjectHoursLeft}
                type="text"
                disabled
              />
            </div>
            <div className="inputDiv">
              <label>סך הכל תשלומים שיצאו:</label>
              <br></br>
              <input
                className="totalPaymentsGiven"
                type="number"
                name="totalPaymentsGiven"
                value={totalPaymentsGiven}
                disabled
              />
            </div>
            <div className="inputDiv">
              <label>סך הכל תשלומים שבוצעו:</label>
              <br></br>
              <input
                className="totalFinished"
                type="number"
                name="totalFinished"
                value={totalFinished}
                disabled
              />
            </div>
            <div className="inputDiv">
              <label>שעות בחודש בממוצע:</label>
              <br></br>
              <input
                className="baseHours"
                defaultValue={baseHours}
                onChange={function (e) {
                  payments(e.target.value, 'baseHours', null);
                }}
                type="text"
              />
            </div>
            <div className="inputDiv">
              <label>שיוך חשבונאי</label>
              <br></br>
              <select
                className="accountingAffiliation"
                value={accountingAffiliation}
                onChange={function (e) {
                  payments(e.target.value, 'accountingAffiliation', null);
                }}
              >
                <option>PITANGO UX</option>
                <option>YNA</option>
              </select>
            </div>
          </div>
          <PricingTables />
          <div className="topDiv">
            <label>
              <h3>אבני דרך</h3>
            </label>
            <table className="mainTable">
              <thead>
                <tr className="w3-green">
                  <th>
                    <button
                      id="addLine"
                      className="adds"
                      onClick={(name) => add('mainTable')}
                    >
                      +
                    </button>
                  </th>
                  <th>אבן דרך/תשלום חודשי</th>
                  <th>עיצוב/פיתוח</th>
                  <th>אופי תשלום</th>
                  <th>אחוז מהפרוייקט</th>
                  <th>תשלום מהפרוייקט</th>
                  <th>שווי כספי</th>
                  <th>שעות פנימיות לביצוע</th>
                  <th>שעות פיתוח ללקוח על פי חוזה</th>
                  <th>האם הסתיים</th>
                  <th>תשלום יצא?</th>
                  <th>תשלום בוצע</th>
                </tr>
              </thead>
              <tbody>
                {mileStones.milestones.map((el, index) => (
                  <tr key={index}>
                    <td></td>

                    <td>
                      <input
                        value={el.month}
                        className="month"
                        onChange={function (e) {
                          payments(e.target.value, 'month', index);
                        }}
                        type="text"
                      />
                    </td>
                    <td>
                      <select
                        className="designDev"
                        onChange={(e) =>
                          payments(e.target.value, 'designDev', index)
                        }
                      >
                        <option key={index}>{el.designDev}</option>
                        {designDev.map(function (elm, i) {
                          if (elm.name !== el.designDev) {
                            return <option key={i}>{elm.name}</option>;
                          }
                          return false;
                        })}
                      </select>
                    </td>
                    <td>
                      <select
                        className="paymentType"
                        onChange={(e) =>
                          payments(e.target.value, 'paymentType', index)
                        }
                      >
                        <option key={index}>{el.paymentType}</option>
                        {dataPayment.map(function (elm, i) {
                          if (elm.name !== el.paymentType) {
                            return <option key={i}>{elm.name}</option>;
                          }
                          return false;
                        })}
                      </select>
                    </td>

                    <td>
                      <input
                        value={el.projectPercentage}
                        className="projectPercentage"
                        onChange={(e) =>
                          payments(e.target.value, 'projectPercentage', index)
                        }
                        type="number"
                      />
                    </td>
                    <td>
                      <input
                        value={el.projectPayment}
                        className="projectPayment"
                        onChange={(e) =>
                          payments(e.target.value, 'projectPayment', index)
                        }
                        type="text"
                      />
                    </td>
                    <td>
                      <input
                        value={el.moneyWorth}
                        className="moneyWorth"
                        onChange={function (el) {
                          payments(el.target.value, 'moneyWorth', index);
                        }}
                        type="text"
                        disabled
                      />
                    </td>
                    <td
                      onClick={function (el) {
                        handleClick(el);
                      }}
                    >
                      <input
                        value={el.innerHoursToBeDone}
                        className="innerHoursToBeDone"
                        onChange={function (el) {
                          payments(
                            el.target.value,
                            'innerHoursToBeDone',
                            index
                          );
                        }}
                        type="number"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        value={el.devHoursContract}
                        className="devHoursContract"
                        onChange={function (el) {
                          payments(el.target.value, 'devHoursContract', index);
                        }}
                        type="text"
                      />
                    </td>
                    <td>
                      <select
                        className="finished"
                        name="finished"
                        onChange={function (e) {
                          payments(e.target.value, 'finished', index);
                        }}
                      >
                        <option key={index}>{el.finished}</option>
                        {yesNo.map(function (elm, i) {
                          if (el.finished !== elm.val) {
                            return <option key={i}>{elm.val}</option>;
                          }
                          return false;
                        })}
                      </select>
                    </td>
                    <td>
                      <input
                        value={el.paymentOut}
                        className="paymentOut"
                        onChange={function (el) {
                          payments(el.target.value, 'paymentOut', index);
                        }}
                        type="text"
                        disabled
                      />
                      {/* <select
                      className="paymentOut"
                      name="paymentOut"
                      onChange={function (e) {
                        payments(e.target.value, 'paymentOut', index);
                      }}
                    >
                      <option key={index}>{el.paymentOut}</option>
                      {yesNo.map(function (elm, i) {
                        if (el.paymentOut !== elm.val) {
                          return <option key={i}>{elm.val}</option>;
                        }
                        return false;
                      })}
                    </select> */}
                    </td>
                    <td>
                      <input
                        value={el.paymentDone}
                        className="paymentDone"
                        onChange={function (el) {
                          payments(el.target.value, 'paymentDone', index);
                        }}
                        type="text"
                        disabled
                      />
                    </td>
                  </tr>
                ))}

                <tr>
                  <td>סיכום</td>
                  <td></td>
                  <td></td>
                  <td id="warningMessage" style={{ color: 'red' }}>
                    {warningMessage}
                  </td>
                  <td>
                    <input
                      className="totalPercenages"
                      value={totalPercentages}
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="totalPayments"
                      value={totalPayments}
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="totalMoneyWorth"
                      value={totalMoneyWorth}
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="totalInnerHoursToBeDone"
                      value={totalInnerHoursToBeDone + ' שעות'}
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="totalDevHoursContract"
                      value={totalDevHoursContract + ' שעות'}
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                    {/* <input
                className="totalFinished"
                value={totalFinished}
                type="text"
                disabled
              /> */}
                  </td>
                  <td>
                    <input
                      className="totalPaymentOut"
                      value={totalPaymentOut + ' ש"ח'}
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="totalPaymentDone"
                      value={totalPaymentDone + ' ש"ח'}
                      type="text"
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="insideContainer">
            <table id="insides">
              <thead>
                <tr>
                  <th>תכולות שיצאו:</th>
                  <th>תכולות ששולמו:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="totalInsides"
                      value={totalInsides}
                      type="text"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="totalInsidesPaid"
                      value={totalInsidesPaid}
                      type="text"
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <PermanentPaymentsToCollect />

          {/* <NewTable /> */}
          <ExtraPaymentsTable />
        </div>
      )}
    </div>
  );
};

export default AddContract;
