import moment from 'moment';
import { unwrapResult } from '@reduxjs/toolkit';
import { projectTotalsPush } from '../../redux/projectstotals';
import { projectsEntities } from '../../redux/projects';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  updateContract,
  getContract,
  newContract,
  projectContractBudgetPush,
} from '../../redux/contracts';
import { parseSecondsToTime, timeSpent, timeEst } from '../../utils/projects';
var axios = require('axios');
var rendrered = 0;

const ContractCalculator = ({ designTasks, developmentTasks }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectsTotals = useSelector((state) => state.projecttotal);
  const { projects } = useSelector(projectsEntities);
  const [TotalInnerCalc, setTotalInnerCalc] = useState(0);
  const [TotalInnerHours, setTotalInnerHours] = useState(0);
  const [TotalInnerHoursDev, setTotalInnerHoursDev] = useState(0);
  const [TotalInnerHoursDesign, setTotalInnerHoursDesign] = useState(0);
  const [TotalInnerHoursBank, setTotalInnerHoursBank] = useState(0);
  const [TotalInnerHoursBankSpent, setTotalInnerHoursBankSpent] = useState(0);
  const [TotalDesignHours, setTotalDesignHours] = useState(0);
  const [sumInnerHoursDev, setSumInnerHoursDev] = useState(0);
  const [sumInnerHoursDesign, setSumInnerHoursDesign] = useState(0);
  const [InnerHoursTotal, setInnerHoursTotal] = useState(0);
  const [TotalEstimated, setTotalEstimated] = useState(0);
  const [DevHoursTotal, setDevHoursTotal] = useState(0);
  const [fetchDesignTotals, setFetchDesignTotals] = useState('');
  const [DesignTotals, setDesignTotals] = useState('nothing');
  var c = 0;
  const [TotalHoursConversions, setTotalHoursConversions] = useState();
  const [Pnl, setPnl] = useState(0);

  const project = projects.find((el) => el._id === id);

  var totalDevHoursSpentTime = 0;
  var totalDesignHoursSpentTime = 0;
  var totalEstimated = 0;

  const GetDesginTotals = () => {
    // if (fetchDesignTotals[0] === undefined) {
    var config = {
      method: 'get',
      url: 'https://crm-server.ynadev.com/design/projects',
      headers: {},
    };

    axios(config)
      .then(function (response) {
      //  console.log(response.data);
        setFetchDesignTotals(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    // }
  };

  const GetProject = (
    e,
    totalDevHoursSpentTime,
    totalDesignHoursSpentTime,
    totalEstimated
  ) => {
    let totalInnerHoursToBeDone = 0;
    let totalDevHoursContract = 0;
    let totalInnerCalc = 0;
    let totalHoursConversion = 0;
    let c = 0;
    let totalInnerHoursDev = 0;
    let totalInnerHoursDesign = 0;

    dispatch(getContract(e))
      .unwrap(unwrapResult)
      .catch((error) => {
        console.log(error);
        // alert('No contract found, you may create one.');
        // document.getElementById('saveProject').style.display = 'inline-grid';
      })
      .then((contract) => {
        contract.jsonElement.map((x) => {
          totalInnerHoursToBeDone += x.innerHoursToBeDone;
          totalDevHoursContract += x.devHoursContract;
          if (x.designDev === 'עיצוב') {
            ++c;
            totalInnerHoursDesign += x.innerHoursToBeDone;
          } else {
            totalInnerHoursDev += x.innerHoursToBeDone;
          }
        });
        contract.fixElement.map((x) => {
          totalHoursConversion += x.hoursConversion;
        });

        setTotalInnerHoursDev(totalInnerHoursDev);
        setTotalInnerHoursDesign(totalInnerHoursDesign);
        setTotalInnerHours(totalInnerHoursDev + totalInnerHoursDesign);
        let sumIHDev =
          Number(totalEstimated.split(':')[0]) -
          Number(totalDevHoursSpentTime.split(':')[0]);
        setSumInnerHoursDev(sumIHDev);

        let iHT =
          Number(totalDevHoursSpentTime.split(':')[0]) -
          Number(totalDesignHoursSpentTime.split(':')[0]);
        setInnerHoursTotal(iHT);

        if (document.getElementById('sumIHDev') !== null) {
          if (sumIHDev > 0) {
            document.getElementById('sumIHDev').style.backgroundColor =
              '#72d772';
          } else {
            document.getElementById('sumIHDev').style.backgroundColor = 'red';
          }
        }

        setTotalInnerHoursBank(totalDevHoursContract);

        let sumIHDesign =
          Number(TotalDesignHours) -
          Number(totalDesignHoursSpentTime.split(':')[0]);
        setSumInnerHoursDesign(sumIHDesign);
        if (document.getElementById('sumIHDesign') !== null) {
          if (sumIHDesign > 0) {
            document.getElementById('sumIHDesign').style.backgroundColor =
              '#72d772';
          } else {
            document.getElementById('sumIHDesign').style.backgroundColor =
              'red';
          }
        }

        for (let y = 0; y < c; y++) {
          totalHoursConversion += contract.designDev.hoursConversion;
        }

        setTotalDesignHours(contract.designDev.hoursConversion);

        totalInnerCalc =
          Number(totalDevHoursContract) -
          Number(totalDevHoursSpentTime.split(':')[0]);
        setTotalInnerCalc(totalInnerCalc);
        setTotalHoursConversions(totalHoursConversion);
        let pnl =
          Number(TotalHoursConversions) -
          (Number(totalDevHoursSpentTime.split(':')[0]) +
            Number(totalDesignHoursSpentTime.split(':')[0]));
        setPnl(pnl);
      });
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function convertMsToHM(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    minutes = seconds >= 30 ? minutes + 1 : minutes;

    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
  }

  const getSelectDesignProject = (name) => {
    let i = 0;
    fetchDesignTotals.map((x) => {
      //console.log(x);
      if(x.projectName === name){
        setTotalDesignHours(x.totalHours);
        let spent = x.spent.split(":");
        if(spent[1] > 30){
          spent[0] += 1;
        }
        setSumInnerHoursDesign(spent[0]);
        if (spent[0] < x.totalHours) {
          document.getElementById('sumIHDesign').style.backgroundColor =
            '#72d772';
        } else {
          document.getElementById('sumIHDesign').style.backgroundColor =
            'red';
        }
        //alert(i);
      }
      ++i;
    });
  }

  const calculator = (c) => {
    let hours = 0;
    let minutes = 0;
    let whole;

    // if (rendrered < 60) {
    //   ++rendrered;
    developmentTasks.map((x) => {
      whole = convertMsToHM(x.timeEstimate).split(':');
      hours += parseInt(whole[0]);
      minutes += parseInt(whole[1]);
      if (minutes >= 60) {
        hours += 1;
        minutes -= 60;
      }
      //  totalEstimated += Number(x.timeEstimate);
    });

    totalDevHoursSpentTime = parseSecondsToTime(timeSpent(developmentTasks));
    totalDesignHoursSpentTime = parseSecondsToTime(timeSpent(designTasks));
    totalEstimated = hours + ':' + minutes;
    setTotalEstimated(hours);
    setDevHoursTotal(totalDevHoursSpentTime.split(':')[0]);

    GetProject(
      project.id,
      totalDevHoursSpentTime,
      totalDesignHoursSpentTime,
      totalEstimated
    );
  };

  useEffect(() => {
    GetDesginTotals();

    calculator();
  }, [DevHoursTotal, TotalEstimated, TotalHoursConversions]);

  return (
    
    <div className="contractCalculator">
      {fetchDesignTotals[0] !== undefined && (
        <div className="designTotals">
          <select className="designTotalsChoose" onChange={(e) => setDesignTotals(e.target.value)}>
            {fetchDesignTotals.map((x, i) => (
              <option key={i} >{x.projectName}</option>
            ))}
          </select>
          <button className="designTotalsApprove" onClick={(e) => {getSelectDesignProject(DesignTotals)}}>הוסף פרוייקט עיצוב</button>
        </div>
      )}
      
      {TotalInnerHours <= 0 && <div className="noContract">No contract!</div>}
      {!!TotalInnerHours && (
        <div className="projectContainer">
          <div className="dashboard-top">
            {/* <div className="dashboard-item">
                <p>Total Time Tracked:</p>
                <span>{totalDevHoursSpentTime}</span>
              </div> */}
            <div className="dashboard-item">
              <p>סה"כ עבודה פנימית:</p>
              <span>{InnerHoursTotal}</span>
            </div>
            {/* <div className="dashboard-item">
                <p>Total Inner Calc:</p>
                <span>{TotalInnerCalc}</span>
              </div> */}
            <div className="dashboard-item">
              <p>שעות לפי הצעת מחיר</p>
              <span>{TotalHoursConversions}</span>
            </div>
            <br></br>
            <div className="dashboard-item">
              <p>יתרה:</p>
              <span>{Pnl}</span>
            </div>
          </div>
          <div className="tableDiv">
            <div className="table1">
              <h2>פיתוח</h2>
              <table className="topTable">
                <thead>
                  <tr>
                    <th>שעות פיתוח מתוכננות פנימי</th>
                    <th>שעות פיתוח בפועל פנימי</th>
                    <th>יתרה/חוסר שעות פנימי</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{TotalEstimated}</td>
                    <td>{DevHoursTotal}</td>
                    <td className="totals" id="sumIHDev">
                      {sumInnerHoursDev}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table2">
              <h2>עיצוב</h2>
              <table className="topTable">
                <thead>
                  <tr>
                    <th>סה"כ שעות עיצוב</th>
                    <th>יתרה/חוסר שעות פנימי</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{TotalDesignHours}</td>
                    <td className="totals" id="sumIHDesign">
                      {sumInnerHoursDesign}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractCalculator;
