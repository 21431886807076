import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companiesEntities } from '../../redux/companies';
import { addProjectToMonth } from '../../redux/mounth';
import { projectsEntities } from '../../redux/projects';

const AddMonthProject = () => {
	const { companies } = useSelector(companiesEntities);
	const [selectedCompany, setSelectedCompany] = useState('');
	const { projects } = useSelector(projectsEntities);
	const dispatch = useDispatch();
	const relatedProjects = useMemo(() => {
		if (!selectedCompany) return [];
		return projects.filter(
			(project) => project?.company?._id === selectedCompany,
		);
	}, [selectedCompany, companies, projects]);
	const [selectedProject, setSelectedProject] = useState('');

	const addProject = () => {
		dispatch(addProjectToMonth({ projectId: selectedProject }));
		setSelectedCompany('');
		setSelectedProject('');
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<FormControl sx={{ m: 1, minWidth: 180 }}>
				<InputLabel id="company-label">Add Company</InputLabel>
				<Select
					labelId="company-label"
					id="demo-simple-select"
					value={selectedCompany}
					label="Add Company"
					onChange={(e) => setSelectedCompany(e.target.value)}
				>
					{companies.map((company) => (
						<MenuItem key={company._id} value={company._id}>
							{company.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{!!selectedCompany && (
				<FormControl sx={{ m: 1, minWidth: 180 }}>
					<InputLabel id="project-label">Add Project</InputLabel>
					<Select
						labelId="project-label"
						id="demo-simple-select"
						value={selectedProject}
						label="Add Project"
						onChange={(e) => setSelectedProject(e.target.value)}
					>
						{relatedProjects.map((project) => (
							<MenuItem key={project._id} value={project._id}>
								{project.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
			{!!selectedProject && <Button onClick={addProject}>Add</Button>}
		</div>
	);
};

export default AddMonthProject;
