import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pPTCPush } from '../../redux/mocks';
import { dataElement } from './AddContract';
import moment from 'moment';
import { GOT } from './AddContract';

const PermanentPaymentsToCollect = () => {
  const jsonE = useSelector((state) => state.jsonElement);
  const dispatch = useDispatch();
  const dataPayment = [
    { name: 'אבן דרך' },
    { name: 'תשלום עוקב' },
    { name: 'תשלום קבוע' },
    { name: 'תשלום חד פעמי' },
  ];

  const yesNo = [{ val: 'כן' }, { val: 'לא' }];

  const [pPTC, setPPTC] = useState(jsonE.pPTC);

  const yearlyPayment = (e, b, index) => {
    let totalYearlyPayments = 0;
    let totalMonthlyPayments = 0;

    let clone = pPTC.map((x) => {
      if (b === null) {
        totalMonthlyPayments += Number(x.monthlyPayment);
        totalYearlyPayments += Number(x.yearlyPayment);
      }
      return { ...x };
    });

    if (e !== null && index !== null && b === 'monthlyPayment') {
      clone[index]['yearlyPayment'] = Number(e) * 12;
      clone[index]['monthlyPayment'] = Number(e);
    }
    if (b !== null) {
      clone[index][b] = e;
      let currentMonth = moment().format('YYYY-MM');
      clone[index]['updatedAt'] = currentMonth;
      for (const key in clone) {
        if (clone.hasOwnProperty(key)) {
          totalMonthlyPayments += Number(clone[key].monthlyPayment);
          totalYearlyPayments += Number(clone[key].yearlyPayment);
        }
      }
    }

    if (totalMonthlyPayments > 0) {
      setTotalMonthlyPayments(totalMonthlyPayments);
    }
    if (totalMonthlyPayments > 0) {
      setTotalYearlyPayments(totalYearlyPayments);
    }

    setPPTC(clone);
    dispatch(pPTCPush(clone));
  };

  const [totalMonthlyPayments, setTotalMonthlyPayments] = useState(0);
  const [totalYearlyPayments, setTotalYearlyPayments] = useState(0);

  const add = (name) => {
    const clone = pPTC.map((x) => {
      return { ...x };
    });
    if (name === 'maintenance') {
      clone.push({
        serviceType: '',
        monthlyPayment: '0',
        yearlyPayment: '0',
        paymentType: 'תשלום חד פעמי',
        chargeCustomer: 'לא',
        paymentOut: 'לא',
        paymentDone: 'לא',
        invoiceNumber: '',
      });
    }
    setPPTC(clone);
    dispatch(pPTCPush(clone));
  };

  useEffect(() => {
    setTimeout(() => {
      yearlyPayment(null, null, null);
    }, 1000);
  }, [GOT]);

  return (
    <div className="topDiv">
      <label>
        <h3>שירותי תחזוקה/Retainer</h3>
      </label>
      <table className="pPTC">
        <thead>
          <tr>
            <th>
              <button
                id="addLine"
                className="adds"
                onClick={(name) => add('maintenance')}
              >
                +
              </button>
            </th>
            <th>סוג שירות</th>
            <th>תשלום חודשי</th>
            <th>תשלום שנתי</th>
            <th>אופי תשלום</th>
            <th>האם לחייב את הלקוח</th>
          </tr>
        </thead>
        <tbody>
          {pPTC.map((el, index) => (
            <tr key={index}>
              <td></td>
              <td>
                <input
                  className="serviceType"
                  defaultValue={el.serviceType}
                  onChange={function (e) {
                    yearlyPayment(e.target.value, 'serviceType', index);
                  }}
                  type="text"
                />
              </td>
              <td>
                <input
                  className="monthlyPayment"
                  defaultValue={el.monthlyPayment}
                  onChange={function (e) {
                    yearlyPayment(e.target.value, 'monthlyPayment', index);
                  }}
                  type="text"
                />
              </td>
              <td>
                <input
                  className="yearlyPayment"
                  value={el.yearlyPayment}
                  type="text"
                  disabled
                />
              </td>
              <td>
                <select
                  className="paymentType"
                  onChange={function (e) {
                    yearlyPayment(e.target.value, 'paymentType', index);
                  }}
                >
                  <option key={index}>{el.paymentType}</option>
                  {dataPayment.map(function (elm, i) {
                    if (elm.name !== el.paymentType) {
                      return <option key={i}>{elm.name}</option>;
                    }
                  })}
                </select>
              </td>
              <td>
                <select
                  className="chargeCustomer"
                  name="chargeCustomer"
                  onChange={function (e) {
                    yearlyPayment(e.target.value, 'chargeCustomer', index);
                  }}
                >
                  <option key={index}>{el.chargeCustomer}</option>
                  {yesNo.map(function (elm, i) {
                    if (el.chargeCustomer !== elm.val) {
                      return <option key={i}>{elm.val}</option>;
                    }
                    return false;
                  })}
                </select>
              </td>
            </tr>
          ))}
          <tr>
            <td>סך הכל</td>
            <td></td>
            <td>
              <input
                className="totalMonthlyPayments"
                value={totalMonthlyPayments}
                type="text"
                disabled
              />
            </td>
            <td>
              <input
                className="totalYearlyPayments"
                value={totalYearlyPayments}
                type="text"
                disabled
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PermanentPaymentsToCollect;
