import moment from 'moment';

export const parseSecondsToTime = (seconds) => {
	let res = `${('0' + Math.floor(seconds / 3600)).slice(
		Math.floor(seconds / 3600) >= 100 ? -3 : -2,
	)}:${('0' + Math.floor((seconds / 60) % 60)).slice(-2)}:${(
		'0' +
		(seconds % 60)
	).slice(-2)}`;

	return res[0] === '0' ? res.substring(1) : res;
};

export const totalHours = (hoursBank) =>
	hoursBank?.length
		? hoursBank.reduce((a, b) => ({
				hours: a.hours + b.hours,
		  })).hours
		: 0;

export const timeSpent = (tasks) =>
	tasks?.length
		? tasks.reduce((a, b) => ({
				timeInSeconds: a.timeInSeconds + b.timeInSeconds,
		  })).timeInSeconds
		: 0;

export const timeEst = (tasks) =>
	tasks?.length
		? tasks.reduce((a, b) => ({
				timeEstimate: a.timeEstimate + b.timeEstimate,
		  })).timeEstimate
		: 0;

const totalDevelopmentHours = (intervals) => {
	const tasks = [];
	let total = 0;
	intervals.forEach((interval) => {
		if (!tasks.includes(interval.taskID)) {
			tasks.push(interval.taskID);
			total += interval.timeEstimate;
		}
	});
	return total / 1000 / 3600;
};

export const addProjectTableData = (project) => {
	const totalHoursValue =
		totalHours(project.hoursBank) || totalDevelopmentHours(project.tasks);
	const timeSpentValue = timeSpent(project.tasks);

	const projectTableData = {
		_id: project._id,
		companyName: project.company?.name || '',
		projectName: project.name,
		totalHours: totalHoursValue || 0,
		spent: {
			value: parseSecondsToTime(timeSpentValue),
			type: 'progress',
			progressBarValue: totalHoursValue
				? (timeSpentValue / (totalHoursValue * 3600)) * 100
				: 0,
		},
		remaining: totalHoursValue
			? parseSecondsToTime(totalHoursValue * 3600 - timeSpentValue)
			: 0,
	};

	return { ...project, projectTableData };
};

export const convertTasksToCsvData = (
	project,
	users,
	designTasks,
	developmentTasks,
	designNormalizeFactor,
	developmentNormalizeFactor,
) => {
	let csvData = [{ Company: project.company.name, Project: project.name }];

	const createData = (tasks, normalizeFactor) => {
		const res = tasks.map((task) => ({
			Username: users.find((user) => user._id === task.user).username,
			Date: moment(task.date).format('DD-MM-YYYY'),
			TaskName: task.assignmentType,
			Description: task.taskDescription,
			TimeTracked: parseSecondsToTime(task.timeInSeconds),
			TimeTrackedDecimal: task.timeInSeconds / 3600,
			NormalizedTimeTracked: parseSecondsToTime(
				task.timeInSeconds * normalizeFactor,
			),
			NormalizedTimeTrackedDecimal:
				(task.timeInSeconds / 3600) * normalizeFactor,
		}));
		res.push({
			TotalHours: parseSecondsToTime(timeSpent(tasks)),
			TotalHoursDescimal: timeSpent(tasks) / 3600,
			NormalizedTotalHours: parseSecondsToTime(
				timeSpent(tasks) * normalizeFactor,
			),
			NormalizedTotalHoursDescimal:
				(timeSpent(tasks) / 3600) * normalizeFactor,
		});

		return res;
	};

	csvData.push(['Design']);
	csvData = csvData.concat(createData(designTasks, designNormalizeFactor));
	csvData.push(['Development']);
	csvData = csvData.concat(
		createData(developmentTasks, developmentNormalizeFactor),
	);

	return csvData;
};

export const filterByLast = (project, value) => {
	let filterValue, filterUnits;

	switch (value) {
		case 'Last week':
			filterValue = 1;
			filterUnits = 'weeks';
			break;
		case 'Last two weeks':
			filterValue = 2;
			filterUnits = 'weeks';
			break;
		case 'Last month':
			filterValue = 1;
			filterUnits = 'months';
			break;
		case 'Last quarter':
			filterValue = 4;
			filterUnits = 'months';
			break;
		case 'Last year':
			filterValue = 1;
			filterUnits = 'year';
			break;
		default:
			return project.tasks;
	}

	return project.tasks.filter(
		(task) =>
			moment(task.date).isAfter(
				moment().subtract(filterValue, filterUnits).subtract(1, 'days'),
			) && moment(task.date).isSameOrBefore(moment()),
	);
};
export const filterByThis = (project, value) => {
	let from, to;

	switch (value) {
		case 'This week':
			from = moment().startOf('week');
			to = moment().endOf('week');
			break;
		case 'This two weeks':
			from = moment().startOf('week').subtract(1, 'week');
			to = moment().endOf('week');
			break;
		case 'This month':
			from = moment().startOf('month');
			to = moment().endOf('month');
			break;
		case 'This quarter':
			from = moment().startOf('quarter');
			to = moment().endOf('quarter');
			break;
		case 'This year':
			from = moment().startOf('year');
			to = moment().endOf('year');
			break;
		default:
			return project.tasks;
	}

	return project.tasks.filter(
		(task) =>
			moment(task.date).isSameOrAfter(from) &&
			moment(task.date).isSameOrBefore(to),
	);
};
