import {
  Button,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ListItem,
} from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { newCompany } from '../../redux/companies';
import { addProjects } from '../../redux/projects';
import SnackBar from '../SnackBar';

const AddCompany = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [project, setProject] = useState('');
  const [projects, setProjects] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const addProject = (e) => {
    e.preventDefault();
    if (project !== '') {
      const newProjects = [...projects];
      newProjects.push(project);
      setProjects(newProjects);
      setProject('');
    }
  };

  const removeProject = (project) => {
    const newProjects = [...projects];
    setProjects(newProjects.filter((proj) => proj !== project));
  };

  const onFormSubmit = () => {
    const allProjects = [...projects];
    if (project !== '') {
      allProjects.push(project);
    }
    if (name === '' || allProjects.length === 0) {
      setShowAlert(true);
      return;
    }

    dispatch(addProjects(allProjects))
      .unwrap(unwrapResult)
      .then((projects) => {
        dispatch(newCompany({ name, projects }));
        setProjects([]);
        setName('');
        setProject('');
      });
  };

  return (
    <form onSubmit={addProject} className="form" style={{ margin: '0 auto' }}>
      <FormControl>
        <InputLabel id="name">Name</InputLabel>
        <Input value={name} onChange={(e) => setName(e.target.value)} />
      </FormControl>
      <FormControl style={{ position: 'relative', width: '50%' }}>
        <InputLabel id="projects">Projects</InputLabel>
        <Input value={project} onChange={(e) => setProject(e.target.value)} />
        <ListItem
          style={{
            position: 'absolute',
            top: '100%',
            flexWrap: 'wrap',
            gap: 5,
          }}
        >
          {projects.map((project, i) => (
            <Chip
              key={i}
              label={project}
              onDelete={() => removeProject(project)}
            />
          ))}
        </ListItem>
      </FormControl>
      <button hidden></button>
      <Button
        type="button"
        variant="contained"
        style={{ marginLeft: '10px' }}
        onClick={onFormSubmit}
      >
        ADD
      </Button>
      <SnackBar
        open={showAlert}
        setOpen={setShowAlert}
        message="Some details are missing or incorrect!"
        severity="error"
      />
    </form>
  );
};

export default AddCompany;
