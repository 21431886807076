import { TableCell, IconButton } from '@mui/material';
import { publicUrl } from '../../utils/helpers';
import { Edit, Visibility, VisibilityOff } from '@mui/icons-material';

const BrandTableCell = ({ type, val, editHandler, activeClickHandler, brandIsActive }) => {
    if (type === 'brandLogo' || type === 'brandSignature') {
        return (
            <TableCell align='center'>
                <img style={{ height: '40px' }} src={publicUrl(val)} alt='' />
            </TableCell>
        )
    } else if (type === 'filePriceOne' || type === 'filePriceTwo') {
        const name = val.replace('uploads/', '')
        return (
            <TableCell align='center'>
                <span>{name}</span>
            </TableCell>
        )
    } else if (type === 'brandWebsite') {
        return (
            <TableCell align='center'>
                <a href={val} target='_blank' rel='noopener noreferrer'>{val}</a>
            </TableCell>
        )
    } else if (type === 'edit') {
        return (
            <TableCell align={'center'}>
                <IconButton size="small" onClick={() => editHandler()}>
                    <Edit />
                </IconButton>
            </TableCell>
        )
    } else if (type === 'active') {
        return (
            <TableCell align={'center'}>
                <IconButton size="small" onClick={() => activeClickHandler()}>
                    {brandIsActive ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </TableCell>
        )
    }
    else {
        return (
            <TableCell align={type !== 'brandName' ? 'center' : 'left'}>{val}</TableCell>
        );
    }
}

export default BrandTableCell;
