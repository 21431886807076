import { Box, Typography, Modal, IconButton, Grid, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ModalForm = ({ currentBrand, close, toggleActiveBrand }) => {
    return (
        <div>
            <Modal
                open={true}
                onClose={close}
                aria-labelledby='modal-modal-title'
            >
                <Box sx={style}>
                    <Grid container justifyContent={'space-between'}>
                        <Typography id='modal-modal-title' variant='h6' component='h2'>
                            Toggle {currentBrand[0].brandName} to {currentBrand[0].brandIsActive ? 'inactive' : 'active'}
                        </Typography>
                        <IconButton size="small" onClick={close}>
                            <CloseIcon />
                        </IconButton>
                        <Grid container spacing={2} justifyContent='center' my={2}>
                            <Grid item xs={4}>
                                <Button variant='contained' type='button' fullWidth onClick={toggleActiveBrand}>Yes</Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant='outlined' type='button' fullWidth onClick={close}>No</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}

export default ModalForm
