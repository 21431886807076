import axios from 'axios';

const url = process.env.REACT_APP_SERVER_URL;

export const getData = async (query, params) => {
  const { data } = await axios.get(`${url}/${query}`, {
    params,
  });
  return data;
};

export const addData = async (query, data, token = null, headersData = {}) => {
  try {
    const result = await axios.post(`${url}/${query}`, data, {
      headers: { Authorization: `Bearer ${token}`, ...headersData },
    });
    return result.data;
  } catch (err) {
    throw err;
  }
};

export const editData = async (query, data, params, token = null) => {
  try {
    const result = await axios.put(`${url}/${query}`, data, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err) {
    console.log(err.response.data.error);
    throw err;
  }
};

export const deleteData = async (query, data) => {
  try {
    const result = await axios.delete(`${url}/${query}`, { data });
    return result.data;
  } catch (err) {
    console.log(err.message);
  }
};
