import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  insidePricing: [
    {
      staffMembers: '',
      insidePrice: '',
      insidePricing: '',
      outsidePrice: '',
      outsidePricing: '',
      insideProjectTotal: '',
      outsideProjectTotal: '',
      monthly: '',
      totalForProject: '',
    },
  ],
};

export const insidePriceSlice = createSlice({
  name: 'insidepricing',
  initialState,
  reducers: {
    insidesPricesPush: (state, action) => {
      state.insidePricing = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { insidesPricesPush } = insidePriceSlice.actions;

export default insidePriceSlice.reducer;
