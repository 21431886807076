import { Button, Input } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { workersEntities } from '../../redux/workers';
import { parseSecondsToTime, timeSpent } from '../../utils/projects';

const ProjectTable = ({
  title,
  titles,
  tasks,
  normalizeFactor,
  setNormalizeFactor,
  handeUpdateNormalizeFactor,
}) => {
  const regex = /^[\.0-9]*$/;

  return (
    <>
      <h4>{title}</h4>
      {/* <div className='normalize-factor'>
        Normalize factor:
        <Input
          variant='outlined'
          type='number'
          style={{ width: '50px', margin: '0 8px' }}
          value={normalizeFactor}
          onChange={(e) => {
            if (e.target.value.match(regex))
              setNormalizeFactor(Number(e.target.value));
          }}
        />
        <Button onClick={() => handeUpdateNormalizeFactor(title === 'Design')}>
          Update
        </Button>
      </div> */}
      <div className="table project-table">
        <div className="table__header">
          {titles.map((title, i) => (
            <h4 key={i}>{title}</h4>
          ))}
        </div>
        {tasks.map((task, i) => (
          <TableRow index={i} key={i} task={task} />
        ))}
        <div className="table__header total">
          <div>Total time tracked</div>
          <div>{parseSecondsToTime(timeSpent(tasks))}</div>
        </div>
      </div>
    </>
  );
};

const TableRow = ({ task, index }) => {
  const { users } = useSelector(workersEntities);

  return (
    <div className="table-row">
      <div>{index + 1}</div>
      <div>{users.find((user) => user._id === task.user)?.username}</div>
      <div>{moment(task.date).format('DD/MM/YYYY')}</div>
      <div>{task.assignmentType}</div>
      <div>{task.taskDescription}</div>
      <div>
        {moment
          .utc(
            moment.duration(task.timeInSeconds, 'seconds').as('milliseconds')
          )
          .format('HH:mm:ss')}
      </div>
      {
        <div>
          {!!task.timeEstimate &&
            moment
              .utc(
                moment
                  .duration(task.timeEstimate, 'milliseconds')
                  .as('milliseconds')
              )
              .format('hh:mm:ss')}
        </div>
      }
    </div>
  );
};

export default ProjectTable;
