import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/login';
import { unwrapResult } from '@reduxjs/toolkit';

const LogoutListItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(({ login }) => login);

  const handleLogout = () => {
    dispatch(logout(userData.token))
      .unwrap(unwrapResult)
      .then(() => {
        console.log('Logout successfully');
        navigate('/login');
      })
      .catch((err) => {
        console.log('Logout failed');
      });
  };

  return (
    <ListItem button onClick={handleLogout}>
      <ListItemIcon>
        <Logout />
      </ListItemIcon>
      <ListItemText primary='Logout' />
    </ListItem>
  );
};

export default LogoutListItem;
