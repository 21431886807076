import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../components/Table';
import { companiesEntities } from '../redux/companies';
import { projectsEntities } from '../redux/projects';
import { workersEntities } from '../redux/workers';
import Spinner from '../components/Spinner';
import { getData } from '../api/data';
import InfiniteScroll from 'react-infinite-scroll-component';
import TasksFilter from '../components/tasksFilter/TasksFilter';
import CsvDownload from 'react-json-to-csv';

const tableHeaders = [
	'Company',
	'Project',
	'task name',
	'working time',
	'time estimated',
	'status',
	'User',
	'last updated',
];

const Tasks = () => {
	const { companies, isLoading: isCompaniesLoading } =
		useSelector(companiesEntities);
	const { projects, isLoading: isProjectsLoading } =
		useSelector(projectsEntities);
	const { users, isLoading: isUsersLoading } = useSelector(workersEntities);
	const [tasks, setTasks] = useState([]);
	const [skip, setSkip] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [csvData, setCsvData] = useState(null);
	const [firstLoad, setFirstLoad] = useState(true);
	const [filters, setFilters] = useState({});
	const [totalHours, setTotalHours] = useState(0);
	const limit = useMemo(() => 20, []);

	const fetchTasks = async (noLimit) => {
		const { tasks, done, totalTime } = await getData('tasks', {
			skip: !!noLimit ? 0 : skip,
			limit: !!noLimit ? 0 : limit,
			filters,
		});

		if (!noLimit) {
			setTotalHours(totalTime);
			setSkip(skip + limit);
			setHasMore(!done);
			setCsvData(null);
		}

		return tasks;
	};

	const createTaskObject = (task) => {
		const company = companies.find(
			(company) => company._id === task.company,
		);
		const project = projects.find(
			(project) => project._id === task.project,
		);
		const user = users.find((user) => user._id === task.user);
		return {
			_id: task._id,
			company: company?.name,
			project: project?.name,
			['task name']: task.assignmentType,

			['working time']: moment
				.utc(
					moment
						.duration(task.timeInSeconds, 'seconds')
						.as('milliseconds'),
				)
				.format('HH:mm:ss'),
			['time estimate']: task?.timeEstimate
				? moment
						.utc(
							moment
								.duration(task.timeEstimate, 'milliseconds')
								.as('milliseconds'),
						)
						.format('hh:mm:ss')
				: 'None',
			status: task.status,
			user: user?.username,
			['last updated']: moment(task.date).format('DD/MM/YYYY'),
		};
	};

	const arrangeTable = async () => {
		const dbTasks = await fetchTasks();
		if (!dbTasks) return [];
		const newTasks = dbTasks.map(createTaskObject);
		setTasks([...tasks, ...newTasks]);
	};

	const prepareCSV = async () => {
		const dbTasks = await fetchTasks(true);
		if (!dbTasks) {
			setCsvData([]);
			return;
		}
		let totalWorkingTime = 0;
		let totalEstimatedTime = 0;
		const taskIDs = [];
		dbTasks.forEach((task) => {
			totalWorkingTime += task.timeInSeconds;
			if (task.taskID) {
				if (taskIDs.includes(task.taskID)) return;
				taskIDs.push(task.taskID);
			}

			totalEstimatedTime += task.timeEstimate || 0;
		});
		console.log(totalWorkingTime);

		totalWorkingTime = moment
			.duration(totalWorkingTime, 'seconds')
			.as('hours')
			.toFixed(3);
		totalEstimatedTime = moment
			.duration(totalEstimatedTime)
			.asHours()
			.toFixed(3);
		const sum = {
			['total working time']: totalWorkingTime,
			['total estimated time']: totalEstimatedTime,
		};
		let csvTasks = dbTasks.map(createTaskObject);
		setCsvData([sum, ...csvTasks]);
	};

	useEffect(() => {
		if (
			isCompaniesLoading ||
			isUsersLoading ||
			isProjectsLoading ||
			!firstLoad
		)
			return;
		arrangeTable();
		setFirstLoad(false);
	}, [isCompaniesLoading, isUsersLoading, isProjectsLoading, firstLoad]);

	return (
		<div>
			<TasksFilter
				setFilters={setFilters}
				setFirstLoad={setFirstLoad}
				setSkip={setSkip}
				setTasks={setTasks}
			/>
			{!csvData ? (
				<button onClick={prepareCSV}>prepare CSV</button>
			) : (
				<CsvDownload
					type="button"
					data={csvData.map(({ _id, ...rest }) => rest)}
					target="_blank"
				>
					Export to CSV
				</CsvDownload>
			)}
			<h3 style={{ textAlign: 'center' }}>Total hours: {totalHours}</h3>
			{!!tasks && (
				<InfiniteScroll
					dataLength={tasks.length}
					next={arrangeTable}
					hasMore={hasMore}
					loader={<Spinner />}
				>
					<Table properties={tableHeaders} values={tasks} />
				</InfiniteScroll>
			)}
		</div>
	);
};

export default Tasks;
