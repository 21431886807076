import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData, addData } from '../api/data';

export const fetchCompanies = createAsyncThunk('companies/fetch', async () => {
  try {
    const data = await getData('company/all');
    return data.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  } catch (e) {
    throw new Error(e.message);
  }
});

export const newCompany = createAsyncThunk('companies/add', async (company) => {
  try {
    const data = await addData('company/new', company);
    return { data, projects: company.projects };
  } catch (err) {
    throw new Error(err.message);
  }
});

const initialState = {
  loading: 'pending',
  data: [],
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    addCompany: (state, { payload }) => {
      return [...state, payload];
    },
  },
  extraReducers: {
    [fetchCompanies.pending]: (state, { payload }) => {
      state.loading = 'pending';
    },
    [fetchCompanies.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = 'idle';
    },
    [fetchCompanies.rejected]: () => {
      console.error('fetch companies rejected!');
    },
    [newCompany.fulfilled]: (state, { payload: { data, projects } }) => {
      state.data = [...state, { ...data, projects }];
    },
    [newCompany.rejected]: () => {
      console.error('faild to add company!');
    },
  },
});

const { reducer, actions } = companiesSlice;

export const companiesEntities = ({ companies }) => {
  const isLoading = companies.loading === 'pending';
  return { companies: companies.data, isLoading };
};

export const { addCompany } = actions;

export default reducer;
