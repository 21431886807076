import { Chip, ListItem } from '@mui/material';
import { useMemo } from 'react';
import { TableContainer, Paper } from '@mui/material';

export const Table = ({ properties, values, className, onRowClicked }) => {

  return (
    <TableContainer component={Paper} elevation={4} mt={2} className={`muitable table ${className}`}>
      <div className='table__header'>
        <div>#</div>
        {properties.map((prop, i) => {
          if (
            prop === '_id' ||
            prop === 'companies' ||
            prop === 'clickupID' ||
            prop === 'id' ||
            prop === 'isAdmin'
          )
            return null;
          return <div key={i}>{prop}</div>;
        })}
      </div>
      <div className='table__content'>
        {values.map((value, index) => (
          <Row counter={index} key={value._id} row={value} onRowClicked={onRowClicked} />
        ))}
      </div>
    </TableContainer>
  );
};

const Row = ({
  row: { _id, clickupID, companies, isAdmin, ...rest },
  counter,
  onRowClicked
}) => {
  const values = useMemo(() => Object.values(rest), [rest]);

  return (
    <div onClick={() => !!onRowClicked && onRowClicked(_id)} className='table-row'>
      <div>{counter + 1}</div>
      {values.map((value, index) => {
        if (typeof value === 'object')
          return <MappedCell key={index} values={value} />;
        return <div key={index}>{value}</div>;
      })}
    </div>
  );
};

const MappedCell = ({ values }) => {
  if (values.type === 'progress') {
    return (
      <div className='progress-wrapper'>
        <div>{values.value}</div>
        <div>
          <span>{Math.round(values.progressBarValue || 0)}%</span>
          <progress
            id='file'
            value={values.progressBarValue || 0}
            max='100'
          ></progress>
        </div>
      </div>
    );
  }

  return (
    <ListItem style={{ margin: 0, padding: 0 }}>
      {values.map((value, i) => (
        <Chip key={i} label={value.name} style={{ marginRight: '7px' }} />
      ))}
    </ListItem>
  );
};
