import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  generalData: {
    accountingAffiliation: 'YNA',
    baseHours: 180,
    insidesOut: '',
    insidesPaid: '',
    projectContractBudget: '',
    projectCost: '',
    projectName: '',
    companyName: '',
    totalHumanMonths: '',
    totalProjectHours: '',
    totalHoursDone: '',
    totalProjectHoursLeft: '',
    designPrice: '',
  },
};

export const GeneralDataSlice = createSlice({
  name: 'generaldata',
  initialState,
  reducers: {
    generalDataPush: (state, action) => {
      state.generalData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { generalDataPush } = GeneralDataSlice.actions;

export default GeneralDataSlice.reducer;
