import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import BrandTableCell from './BrandTableCell';

const titles = [
    { brandName: 'Brand name' },
    { brandID: 'Brand ID' },
    { brandLogo: 'Logo' },
    { brandAddress: 'Address' },
    { brandEmail: 'Email' },
    { brandPhoneNumber: 'Phone number' },
    { brandWebsite: 'Website' },
    { brandSignature: 'Signature/Stamp' },
    { filePriceOne: 'File Price 1' },
    { filePriceTwo: 'File Price 2' },
    { edit: 'Edit' },
    { active: 'Active' }
];

const BrandsTable = ({ settings, editHandler, activeClickHandler }) => {
    return (
        <TableContainer component={Paper} elevation={4}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {titles.map((title, index) => <TableCell key={index} align={index !== 0 ? 'center' : 'left'}>{Object.entries(title)[0][1]}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {settings.map((row, index) => (
                        <TableRow
                            key={`${row._id}_${index}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className={!row.brandIsActive ? 'disabled' : ''}
                        >
                            {titles.map(title => {
                                return (
                                    Object.entries(row).map(val => {
                                        if (Object.keys(title)[0] === val[0]) {
                                            return <BrandTableCell key={`${val[0]}_${row._id}`} type={val[0]} val={val[1]} />
                                        }
                                        return null;
                                    })
                                )

                            })}
                            <BrandTableCell align='center' type='edit' editHandler={() => editHandler(row._id)} key={`edit_${row._id}`} />
                            <BrandTableCell align='center' type='active' activeClickHandler={() => activeClickHandler(row._id)} key={`isActive_${row._id}`} brandIsActive={row.brandIsActive} />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    );
}

export default BrandsTable;
