import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { addData, getData, editData } from '../api/data';

export const fetchSettings = createAsyncThunk('settings/fetch', async () => {
    try {
        const data = await getData('settings');
        return data;
    } catch (e) {
        throw new Error(e.message);
    }
});

export const newBrand = createAsyncThunk('settings/add', async ({ brand, selectedLogo = null, selectedSignature = null, filePriceOne = null, filePriceTwo = null }) => {
    try {
        let formData = new FormData();

        for (const key in brand) {
            formData.append(key, brand[key]);
        }

        formData.append('brandLogo', selectedLogo);
        formData.append('brandSignature', selectedSignature);
        formData.append('filePriceOne', filePriceOne);
        formData.append('filePriceTwo', filePriceTwo);

        const data = await addData('settings/new', formData, { 'Content-Type': 'multipart/form-data' });
        return { data };
    } catch (e) {
        throw new Error(e.message);
    }
});

export const updateBrand = createAsyncThunk('settings/edit', async ({ brand, selectedLogo, selectedSignature, filePriceOne, filePriceTwo }) => {
    try {
        let formData = new FormData();

        for (const key in brand) {
            formData.append(key, brand[key]);
        }

        formData.append('brandLogo', selectedLogo);
        formData.append('brandSignature', selectedSignature);
        formData.append('filePriceOne', filePriceOne);
        formData.append('filePriceTwo', filePriceTwo);

        const data = await editData('settings/edit', formData, { 'Content-Type': 'multipart/form-data' });

        return data;
    } catch (err) {
        throw new Error('edit brand error', err.message);
    }
});

const initialState = [];

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        addBrand: (state, { payload }) => {
            return [...state, payload];
        },
    },
    extraReducers: {
        [fetchSettings.fulfilled]: (state, { payload }) => {
            return payload;
        },
        [fetchSettings.rejected]: () => {
            console.error('fetch settings rejected!');
        },
        [newBrand.fulfilled]: (state, { payload: { data } }) => {
            return [...state, { ...data }];
        },
        [newBrand.rejected]: () => {
            console.error('faild to add a new brand!');
        },
        [updateBrand.fulfilled]: (state, { payload }) => {
            const newState = [...current(state)];

            const brandIndex = current(state).findIndex(
                (brand) => brand._id === payload._id
            );
            newState[brandIndex] = payload;
            state = newState;

            return state
        },
        [updateBrand.rejected]: () => {
            console.error('edit brand settings rejected!');
        },
    },
});

const { reducer, actions } = settingsSlice;

export const { addBrand } = actions;

export default reducer;
