import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { editData, getData, addData } from '../api/data';

export const newAdva = createAsyncThunk('adva/new', async (adva) => {
	try {
		const data = await addData('adva/new', adva);

		console.log(data);

		return data;
	} catch (err) {
		throw new Error(err.message);
	}
});

export const getAdva = createAsyncThunk('adva/find', async (project) => {
	try {
		const data = await getData(`adva/find/${project}`);
		return data;
	} catch (e) {
		throw new Error(e.message);
	}
});

export const updateAdva = createAsyncThunk('adva/update', async (adva) => {
	try {
		await editData(`adva/update/${adva.project}`, adva);
	} catch (err) {
		throw err;
	}
});

export const getDaysOff = createAsyncThunk('dayoff/month', async (reqMonth) => {
	try {
		const data = await getData(`dayoff/month/?date=${reqMonth}`);
		return data;
	} catch (e) {
		throw new Error(e.message);
	}
});

const initialState = {
	milestones: [{}],
	followingPaymentsOrOneOffs: [{}],
	maintenance: [{}],
	vacations: [{}],
};

export const advaSlice = createSlice({
	name: 'adva',
	initialState,
	reducers: {
		increment: (state) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			state[0].value += 1;
		},
		decrement: (state) => {
			state.value -= 1;
		},
		insidesOut: (state, action) => {
			state.insidesOut = Number(action.payload);
		},
		insidesPaid: (state, action) => {
			state.insidesPaid = Number(action.payload);
		},
		baseHoursPush: (state, action) => {
			state.baseHours = Number(action.payload);
		},
		totalProjectHoursPush: (state, action) => {
			state.totalProjectHours = Number(action.payload);
		},
		totalHumanMonthsPush: (state, action) => {
			state.totalHumanMonths = Number(action.payload);
		},
		setVacations: (state, action) => {
			state.vacations = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	increment,
	decrement,
	insidesOut,
	insidesPaid,
	baseHoursPush,
	totalProjectHoursPush,
	totalHumanMonthsPush,
	setVacations,
} = advaSlice.actions;

export default advaSlice.reducer;
