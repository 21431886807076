import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../../api/data';
import { selectMonth, setLoading, updateSickDays } from '../../../redux/mounth';
import { workersEntities } from '../../../redux/workers';
import HoursRow from './HoursRow';

const WorkersHours = () => {
	const { users: workers } = useSelector(workersEntities);
	const {
		hours,
		month,
		workers: activeWorkers,
		date,
	} = useSelector(selectMonth);
	const [demandHours, setDemandHours] = useState(
		Array(workers.length).fill(0),
	);
	const [actualHours, setActualHours] = useState([]);
	const [workingHoursNEW, setWorkingHoursNEW] = useState([]);
	const [plannedHoursNEW, setPlannedHoursNEW] = useState([]);
	const [newDemandHours, setNewDemandHours] = useState([]);
	const [HR, setHR] = useState([]);
	const [sickDays, setSickDays] = useState([]);
	const sumVsWH = [];
	const dispatch = useDispatch();

	const workingHours = useMemo(() => {
		let workingHoursNew = [];
		workingHoursNew.color = [];
		return demandHours.map((hour, i) => {
			workingHoursNew.color.push('green');
			if (!!sickDays[i]) {
				workingHoursNew.push(hour - sickDays[i] * 9);
			} else {
				workingHoursNew.push(hour);
			}

			if (!!sickDays[i]) return hour - sickDays[i] * 9;

			setWorkingHoursNEW(workingHoursNew);

			return hour;
		});
	}, [demandHours, sickDays]);
	const hoursRemaining = useMemo(() => {
		let HR = [];
		HR.color = [];
		return actualHours.map((hour, i) => {
			HR.push(Math.round(workingHours[i] - hour));
			if (Math.round(workingHours[i] - hour) > 0) {
				HR.color.push('green');
			} else {
				HR.color.push('red');
			}
			setHR(HR);
			return Math.round(workingHours[i] - hour);
		});
	}, [actualHours, workingHours]);
	const plannedHours = useMemo(() => {
		let sumHours = [];
		sumHours.color = [];
		activeWorkers?.forEach((worker, i) => {
			let sumPerWorker = 0;
			month?.projects.forEach((project) => {
				project?.workers.forEach((projectWorker) => {
					if (projectWorker.worker === worker.worker)
						sumPerWorker += projectWorker.hours;
				});
			});
			if (sumPerWorker >= workingHours[i]) {
				sumHours.color[i] = 'green';
			}
			if (sumPerWorker < workingHours[i]) {
				sumHours.color[i] = 'red';
			}
			if (sumPerWorker === 0) {
				sumHours.color[i] = 'white';
			}
			if (sumPerWorker === workingHours[i]) {
				sumHours.color[i] = 'green';
			}
			// if (sumPerWorker === 0 || workingHours[i] === 0) {
			//   sumHours.color.push('white');
			// }

			sumHours.push(sumPerWorker);
		});
		setPlannedHoursNEW(sumHours);
		return sumHours;
	}, [month, activeWorkers, workingHours]);

	const updateActualHours = async () => {
		let newData = [];
		newData.color = [];
		const users = activeWorkers.map((worker) => worker.worker);
		dispatch(setLoading('pending'));
		const data = await getData('users/total-hours', { date, users });
		dispatch(setLoading('idle'));
		data.map((x) => {
			newData.push(x);
			if (x > 0) {
				newData.color.push('green');
			} else {
				newData.color.push('white');
			}
		});
		setActualHours(newData);
	};

	// const updateSickDays = async () => {
	// 	const result = await getData(`dayoff/month/?date=${date}`);
	// 	const sickDays = [];
	// 	sickDays.color = [];
	// 	result.forEach((user) => {
	// 		const userIndex = activeWorkers.findIndex(
	// 			(worker) => worker.worker === user.id,
	// 		);
	// 		if (userIndex !== -1)
	// 			sickDays[userIndex] = user.sick || 0 + user.vacation || 0;
	// 		if (sickDays[userIndex] > 0) {
	// 			sickDays.color[userIndex] = 'green';
	// 		}
	// 	});
	// 	setSickDays(sickDays);
	// };

	const hoursCompare = () => {
		workingHours.map((hours, i) => {
			if (hours >= plannedHours[i]) {
				sumVsWH[i] = 'Green';
			}
			if (hours < plannedHours[i]) {
				sumVsWH[i] = 'Red';
			}
			if (plannedHours[i] === 0 || plannedHours[i] === 0) {
				sumVsWH[i] = 'white';
			}
			return { ...hours };
		});
	};

	useEffect(() => {
		let newDemandHours = [];
		newDemandHours.color = [];
		if (!month || !activeWorkers) return;
		setDemandHours(Array(activeWorkers.length).fill(hours));
		if (demandHours.length > 0) {
			demandHours.map((x) => {
				newDemandHours.push(x);
				if (x > 0) {
					newDemandHours.color.push('green');
				}
			});
			setNewDemandHours(newDemandHours);
			const sickDays = [];
			activeWorkers.forEach((worker, i) => {
				sickDays[i] = worker.sickDays || 0;
			});
			setSickDays(sickDays);
		}
	}, [workers, hours, month]);

	useEffect(() => {
		if (!!activeWorkers && activeWorkers.length > 0) {
			updateActualHours();
		}
	}, [activeWorkers]);

	useEffect(() => {
		hoursCompare();
	}, []);

	return (
		<div>
			<HoursRow
				title="demand"
				disabled
				value={newDemandHours}
				type="hours"
				cols={sumVsWH}
			/>
			<HoursRow
				title="sick days / vacation days - total days off work"
				type="sickDays"
				value={sickDays}
				updateState={updateSickDays}
			/>
			<HoursRow
				title="working hours"
				type="workingHours"
				disabled
				value={workingHoursNEW}
			/>
			<HoursRow title="hours remaining (0 - 200)" disabled value={HR} />
			<HoursRow
				title="sum planned hours"
				disabled
				value={plannedHoursNEW}
			/>
			<HoursRow
				title="actual working hours - until now"
				disabled
				text={['Planned Hours', 'Expected Hours']}
				value={actualHours}
			/>
		</div>
	);
};

export default WorkersHours;
