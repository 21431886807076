import moment from 'moment';
import ProgressBar from '../ProgressBar';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AlertDialog from '../AlertDialog';
import { useState } from 'react';

const Dashboard = ({ project }) => {
  const [isDeleteHoursDialogOpen, setIsDeleteHoursDialogOpen] = useState(false);
  const [selectedHoursIndex, setSelectedHoursIndex] = useState(0);

  const handleDeleteProject = () => {
    console.log('delete', selectedHoursIndex);
  };

  return (
    <div className="dashboard">
      <div className="dashboard-item">
        <p>Total hours:</p>
        <span>{project.projectTableData.totalHours}</span>
      </div>
      <div className="dashboard-item">
        <p>Spent:</p>
        <ProgressBar values={project.projectTableData.spent} />
      </div>
      <div className="dashboard-item">
        <p>Remaning:</p>
        <span>{project.projectTableData.remaining}</span>
      </div>
      <div className="dashboard-item">
        <p>Hours bank:</p>
        {project.hoursBank.map((el, i) => (
          <div className="hours-bank-item" key={i}>
            <span>{moment(el.addedDate).format('DD-MM-YYYY')} - </span>
            <span>{el.hours}</span>
            <DriveFileRenameOutlineIcon style={{ width: '20px' }} />
            <DeleteOutlineIcon
              style={{ width: '20px' }}
              onClick={() => {
                setSelectedHoursIndex(i);
                setIsDeleteHoursDialogOpen(true);
              }}
            />
            <AlertDialog
              isOpen={isDeleteHoursDialogOpen}
              setIsOpen={setIsDeleteHoursDialogOpen}
              title={'Delete hours'}
              text={`Are you sure you want to delete hours from bank?`}
              handleYes={(yes) => handleDeleteProject(yes)}
            />
          </div>
        ))}
      </div>

      {project.isFixed && (
        <>
          <div className="dashboard-item">
            <p>Total Budget:</p>
            <span>{project.totalBudget}</span>
          </div>
          <div className="dashboard-item">
            <p>Hourly Rate:</p>
            <span>{project.ratePerHour}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
