import React from 'react';
import { useSelector } from 'react-redux';
import AddContract from '../components/contracts/AddContract';
import { Table } from '../components/Table';

const Contracts = () => {
  // const contracts = useSelector(({ contracts }) => contracts);
  // console.log(contracts);
  // if (contracts.length === 0) return <div>Loading...</div>;
  return (
    <>
      {/* <Table properties={Object.keys(contracts[0])} values={contracts} /> */}
      <AddContract />
    </>
  );
};

export default Contracts;
