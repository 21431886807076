import { Button } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AlertDialog from '../components/AlertDialog';
import AddEmployee from '../components/employees/AddEmployee';
import { deleteUser, workersEntities } from '../redux/workers';

const Employee = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { users } = useSelector(workersEntities);
  const user = users.find((el) => el._id === id);

  const [isEditEmployeeOpen, setIsEditEmployeeOpen] = useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);

  const onClickToggleIsDeleteUserOpen = () => {
    setIsDeleteUserDialogOpen(!isDeleteUserDialogOpen);
  };

  const onClickToggleIsEditEmployeeOpen = () => {
    setIsEditEmployeeOpen(!isEditEmployeeOpen);
  };

  const handleDeleteUser = () => {
    setIsDeleteUserDialogOpen(false);

    dispatch(deleteUser({ id: user._id }))
      .unwrap(unwrapResult)
      .then(() => {
        navigate('/employees');
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='employee'>
      <h1>User details</h1>
      <div className='delete-user'>
        <Button
          onClick={onClickToggleIsEditEmployeeOpen}
          type='button'
          variant='contained'
        >
          Edit user
        </Button>
        <Button
          onClick={onClickToggleIsDeleteUserOpen}
          type='button'
          variant='contained'
          style={{
            backgroundColor: 'red',
          }}
        >
          Delete user
        </Button>

        {isEditEmployeeOpen && (
          <AddEmployee
            isEditMode={true}
            employee={user}
            setIsOpen={setIsEditEmployeeOpen}
          />
        )}
        {isDeleteUserDialogOpen && (
          <AlertDialog
            isOpen={isDeleteUserDialogOpen}
            setIsOpen={setIsDeleteUserDialogOpen}
            title='Delete User'
            text={`Are you sure you want to delete  ${user.username}`}
            handleYes={handleDeleteUser}
          />
        )}
      </div>
      {user && (
        <div className='user-details'>
          <div className='user-details-item'>
            <h4> Name: </h4> <span>{user.username}</span>
          </div>
          <div className='user-details-item'>
            <h4> Role: </h4> <span>{user.role}</span>
          </div>
          <div className='user-details-item'>
            <h4> Email: </h4> <span>{user.email}</span>
          </div>
          <div className='user-details-item'>
            <h4> Realated companies: </h4>
            {user.companies.length ? (
              user.companies.map((company) => (
                <p key={company._id}>{company.name}</p>
              ))
            ) : (
              <p>No related companies</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Employee;
