import { useDispatch, useSelector } from "react-redux"
import TableContent from "./TableContent"
import TableHeader from "./TableHeader"
import WorkersHours from "./hoursTable/WorkersHours"
import { fetchMounth, selectMonth } from "../../redux/mounth"
import Spinner from "../Spinner"
import { useEffect } from "react"


const MainTable = () => {
    const { workers, date } = useSelector(selectMonth)
    const dispatch = useDispatch()

    const style = {
        width: `${(10 + (workers?.length || 0)) * 100 + 1}px`
    }

    async function getMonth() {
        if (!date) return
        await dispatch(fetchMounth(date))
    }

    useEffect(() => {
        getMonth()
    }, [date])

    return (
        <div className="main_table" style={style}>
            <WorkersHours />
            <TableHeader />
            <TableContent />
        </div>
    )
}

export default MainTable