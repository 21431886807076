import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectMonth } from '../../redux/mounth'
import { workersEntities } from '../../redux/workers'

const TableHeader = () => {
    const {users} = useSelector(workersEntities)
    const { workers } = useSelector(selectMonth)
    const dispatch = useDispatch()
    const style = {
    }

    // const removeWorker = (id) => {
    //   dispatch(removeWorkerFromMonth(id))
    // }

  return (
    <div className='main-table__header' style={style}>
        <div className='long-text' style={{ borderTop: '1px solid #ccc', borderLeft: '1px solid #ccc' }}>Company Name</div>
        <div className='long-text' style={{ borderTop: '1px solid #ccc' }}>Project Name</div>
        <div className='long-text'>Total</div>
        <div className='long-text'>Development</div>
        <div className='long-text'>Qa & Mang</div>
        <div className='long-text'>Total</div>
        <div className='long-text'>Development</div>
        <div className='long-text'>Qa & Mang</div>
        <div className='long-text' style={{gridColumn: 'span 2', border: '1px solid #ccc' }}>Project manager</div>
        {
            !!users && users.length > 0 && !!workers && workers.map((employee) => (
                <div key={employee.worker} style={{border: '1px solid #ccc'}}>
                  <span>{ (users.find((user) => user._id === employee.worker))?.username }</span>
                  <span className='sub-name'>{ (users.find((user) => user._id === employee.worker))?.role }</span>
                  {/* <button onClick={() => removeWorker(employee.worker)}>X</button> */}
                </div>
            ))
        }
    </div>
  )
}

export default TableHeader