import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  projectsTotal: {
    totalHoursTimeSpent: '11',
    totalMinutesTimeSpent: '',
    otherTotal: '',
  },
};

export const projecTotalsSlice = createSlice({
  name: 'projecttotal',
  initialState,
  reducers: {
    projectTotalsPush: (state, action) => {
      state.projectsTotal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { projectTotalsPush } = projecTotalsSlice.actions;

export default projecTotalsSlice.reducer;
