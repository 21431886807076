import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/lab";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchMounth,
	selectMonth,
	setTime,
	updateDate,
	updateMonth,
} from "../../redux/mounth";
import SnackBar from "../SnackBar";
import AddMonthProject from "./AddMonthProject";
import AddMonthWorkers from "./AddMonthWorkers";
import Spinner from "../Spinner";

export default function CustomDatePicker() {
	const {
		date,
		month,
		days: initialDate,
		hours,
		isLoading,
	} = useSelector(selectMonth);
	const [days, setDays] = useState(initialDate);
	const [showSnackBar, setSnackBar] = useState(false);
	const dispatch = useDispatch();

	const handleChange = (newDate) => {
		if (moment(new Date(newDate)).isSame(new Date(date), "month")) {
			return;
		}
		dispatch(updateDate(new Date(newDate).toDateString()));
	};

	const saveChanges = async () => {
		await dispatch(updateMonth({ month, date: date, days })).unwrap();
		setSnackBar(true);
	};

	useEffect(() => {
		setDays(initialDate);
	}, [initialDate]);

	useEffect(() => {
		dispatch(setTime({ days }));
	}, [days, dispatch]);

	return (
		<>
			<Box
				component="form"
				sx={{
					"& > :not(style)": { m: 1, width: "100%" },
				}}
				noValidate
				autoComplete="off"
				style={{
					position: "relative",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<div
					style={{
						width: "70%",
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							views={["year", "month"]}
							label="Year and Month"
							minDate={new Date("2012-03-01")}
							maxDate={new Date("2023-06-01")}
							value={date || Date.now()}
							onChange={handleChange}
							renderInput={(params) => (
								<TextField {...params} helperText={null} />
							)}
						/>
					</LocalizationProvider>
					<div className="days-grid">
						<div>Numbers of Days</div>
						<input
							type="number"
							value={days}
							onChange={(e) => setDays(e.target.value)}
						/>
						<div>Total hours to work this month</div>
						<input type="number" disabled value={hours} />
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						position: "relative",
					}}
				>
					<AddMonthProject />
					<AddMonthWorkers />
					{isLoading && (
						<Spinner
							style={{
								width: "fit-content",
								position: "absolute",
								left: "250px",
							}}
						/>
					)}
				</div>
				<Button
					type="button"
					onClick={saveChanges}
					variant="contained"
					style={{
						position: "fixed",
						right: "0",
						top: "10px",
						width: "120px",
					}}
				>
					Save Table
				</Button>
			</Box>

			<SnackBar
				open={showSnackBar}
				setOpen={setSnackBar}
				severity="success"
				message="Table was updated !"
			/>
		</>
	);
}
