import { Button, FormControl, InputLabel, MenuItem, Select, Chip, Stack } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addWorkersToMonth, selectMonth } from '../../redux/mounth'
import { workersEntities } from '../../redux/workers'

const AddMonthWorkers = () => {
  const { users } = useSelector(workersEntities)
  const { workers } = useSelector(selectMonth)
  const availableUsers = useMemo(() => {
    return users.filter((user) => !workers || workers?.findIndex((worker) => worker.worker === user._id) === -1)
  }, [users, workers])
  const [addedUsers, setAddedUsers] = useState([])
  const dispatch = useDispatch()

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAddedUsers(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const removeUser = (id) => {
    const users = [...addedUsers]
    users.splice(users.indexOf(id), 1)
    setAddedUsers(users)
  }

  const addWorkers = () => {
    dispatch(addWorkersToMonth({ ids: addedUsers }))
    setAddedUsers([])
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControl sx={{ m: 1, width: 180 }}>
        <InputLabel id="worker-label">Add Workers</InputLabel>
        <Select
          labelId="worker-label"
          id="demo-simple-select"
          multiple
          value={addedUsers}
          label="Add Workers"
          onChange={handleChange}
        >
          {availableUsers.map((user) => (
            <MenuItem key={user._id} value={user._id}>{user.username}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {
        addedUsers.length > 0 &&
        <Button onClick={addWorkers}>Add</Button>
      }

      <Stack direction="row" style={{flexWrap: 'wrap'}}>
        {
          addedUsers.map((user) =>
            <Chip style={{ margin: '5px' }} key={user} label={users.find((u) => u._id === user).username} onDelete={() => removeUser(user)} />
          )
        }
      </Stack>
    </div>
  )
}

export default AddMonthWorkers