import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectMonth } from '../../redux/mounth'
import { projectsEntities } from '../../redux/projects'
import Row from './Row'

const TableContent = () => {
    const {projects} = useSelector(projectsEntities)
    const { month } = useSelector(selectMonth)
    const monthlyProjects = useMemo(() => {
      if (!month) return []
      return projects?.filter((proj) => month?.projects.findIndex((project) => project.project === proj._id) !== -1)
  }, [month, projects])

  return (
    <div className='main-table__content'>
        {
            monthlyProjects.map((project) => (
                <Row key={project._id} project={project} />
            ))
        }
    </div>
  )
}

export default TableContent