import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { editData, getData, addData } from '../api/data';

export const newContract = createAsyncThunk(
	'contract/new',
	async (contract) => {
		try {
			const data = await addData('contract/new', contract);

			console.log(data);

			return data;
		} catch (err) {
			throw new Error(err.message);
		}
	},
);

export const getContract = createAsyncThunk(
	'contract/find',
	async (project) => {
		try {
			const data = await getData(`contract/find/${project}`);
			// console.log(data);
			return data;
		} catch (e) {
			throw new Error(e.message);
		}
	},
);

export const getAdvot = createAsyncThunk('contract/all', async (reqParams) => {
	try {
		const data = await getData(`contract/all/`, { reqParams });
		return data;
	} catch (e) {
		throw new Error(e.message);
	}
});

export const updateContract = createAsyncThunk(
	'contract/update',
	async (contract) => {
		try {
			await editData(`contract/update/${contract.project}`, contract);
		} catch (err) {
			throw err;
		}
	},
);

const initialState = {
	totalHumanMonths: '',
	totalProjectHours: '',
	totalProjectHoursLeft: '',
	projectContractBudget: '',
	projectCost: '',
	insidesOut: '',
	insidesPaid: '',
	baseHours: 180,
	totalFinished: '',
};

export const counterSlice = createSlice({
	name: 'counter',
	initialState,
	reducers: {
		increment: (state) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			state[0].value += 1;
		},
		decrement: (state) => {
			state.value -= 1;
		},
		insidesOut: (state, action) => {
			state.insidesOut = Number(action.payload);
		},
		insidesPaid: (state, action) => {
			state.insidesPaid = Number(action.payload);
		},
		baseHoursPush: (state, action) => {
			state.baseHours = Number(action.payload);
		},
		totalProjectHoursPush: (state, action) => {
			state.totalProjectHours = Number(action.payload);
		},
		totalHumanMonthsPush: (state, action) => {
			state.totalHumanMonths = Number(action.payload);
		},
		projectContractBudgetPush: (state, action) => {
			state.projectContractBudget = Number(action.payload);
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	increment,
	decrement,
	insidesOut,
	insidesPaid,
	baseHoursPush,
	totalProjectHoursPush,
	totalHumanMonthsPush,
	projectContractBudgetPush,
} = counterSlice.actions;

export default counterSlice.reducer;
