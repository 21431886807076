import validtor from 'validator';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/login';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validateEmail = () => email.length > 0 && validtor.isEmail(email);
  const validatePassword = () => password.length > 0;

  const onSubmitForm = async (event) => {
    event.preventDefault();

    if (!validateEmail()) {
      setEmailErrorMessage('Please enter a valid email');
    }
    if (!validatePassword()) {
      setPasswordErrorMessage('Please enter a valid password');
    }

    if (validateEmail() && validatePassword()) {
      dispatch(login({ email, password }))
        .unwrap(unwrapResult)
        .then(() => {
          console.log('Login successfully');
          navigate('/');
        })
        .catch((err) => {
          setErrorMessage('Login failed');
        });
    }
  };

  return (
    <div className="login-form">
      <form onSubmit={onSubmitForm}>
        {errorMessage && <p className="login-error-message">{errorMessage}</p>}
        <div className="form-group">
          <div className="input-control">
            <input
              onChange={(e) => {
                setEmail(e.target.value.trim());
                setEmailErrorMessage('');
                setErrorMessage('');
              }}
              value={email}
              className={emailErrorMessage ? 'error' : ''}
              type="text"
              id="email"
              placeholder="Email"
            />
          </div>

          {emailErrorMessage && (
            <p className="error-message">{emailErrorMessage}</p>
          )}
        </div>

        <div className="form-group">
          <div className="input-control">
            <input
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordErrorMessage('');
                setErrorMessage('');
              }}
              value={password}
              className={passwordErrorMessage ? 'error' : ''}
              type="password"
              id="password"
              placeholder="Password"
            />
          </div>

          {passwordErrorMessage && (
            <p className="error-message">{passwordErrorMessage}</p>
          )}
        </div>

        <button
          className="button"
          type="submit"
          disabled={emailErrorMessage || passwordErrorMessage}
        >
          <p className="btn-text">Login</p>
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
