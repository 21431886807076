import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const Cell = ({
  projectId,
  id,
  disabled,
  text,
  value,
  updateState,
  name,
  style,
  className,
}) => {
  const dispatch = useDispatch();
  const onValueChanged = (e) => {
    dispatch(updateState({ value: e.target.value, name, projectId, id }));
  };

  return (
    <div className={`${className} cell`} style={style}>
      <input
        style={{ textAlign: 'center', color: style?.color }}
        type={text ? 'text' : 'number'}
        value={value || ''}
        onChange={onValueChanged}
        disabled={disabled}
      />
    </div>
  );
};

export default Cell;
