import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { companiesEntities } from '../../redux/companies';
import { projectsEntities } from '../../redux/projects';
import { workersEntities } from '../../redux/workers';
import { Box } from '@mui/system';
import moment from 'moment';

const TasksFilter = ({ setFilters, setFirstLoad, setSkip, setTasks }) => {
	const { users } = useSelector(workersEntities);
	const { companies } = useSelector(companiesEntities);
	const { projects } = useSelector(projectsEntities);
	const [taskType, setTaskType] = useState('');
	const [company, setCompany] = useState('');
	const [project, setProject] = useState('');
	const [user, setUser] = useState('');
	const [dateRange, setDateRange] = useState([null, null]);

	const resetFilters = () => {
		setTaskType('');
		setCompany('');
		setProject('');
		setUser('');
		setDateRange([null, null]);
	};

	const onFiltersSubmit = (e) => {
		e.preventDefault();
		const filters = {
			user,
			project,
			company,
			taskType,
		};
		for (const prop in filters) {
			if (filters[prop] === '') delete filters[prop];
		}
		if (!!dateRange[0] && !!dateRange[1]) {
			filters.date = {
				$gte: new Date(dateRange[0]),
				$lte: moment(dateRange[1]).endOf('day').toDate(),
			};
		}
		setSkip(0);
		setFilters(filters);
		setFirstLoad(true);
		setTasks([]);
	};

	return (
		<>
			<form
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					marginTop: '10px',
					width: '100%',
				}}
				onSubmit={onFiltersSubmit}
			>
				<FormControl sx={{ m: 1, minWidth: 180 }}>
					<InputLabel id="taskType-label">Task Type</InputLabel>
					<Select
						labelId="taskType-label"
						id="taskType"
						label="taskType"
						value={taskType}
						onChange={(e) => setTaskType(e.target.value)}
					>
						<MenuItem value="Development">Development</MenuItem>
						<MenuItem value="design">Design</MenuItem>
					</Select>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 180 }}>
					<InputLabel id="company-label">Company</InputLabel>
					<Select
						labelId="company-label"
						id="company"
						label="Company"
						value={company}
						onChange={(e) => setCompany(e.target.value)}
					>
						{companies.map((company) => (
							<MenuItem key={company._id} value={company._id}>
								{company.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 180 }}>
					<InputLabel id="project-label">Project</InputLabel>
					<Select
						labelId="project-label"
						id="project"
						label="Project"
						value={project}
						onChange={(e) => setProject(e.target.value)}
					>
						{projects.map((project) => (
							<MenuItem key={project._id} value={project._id}>
								{project.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 180 }}>
					<InputLabel id="user-label">User</InputLabel>
					<Select
						labelId="user-label"
						id="user"
						label="user"
						value={user}
						onChange={(e) => setUser(e.target.value)}
					>
						{users.map((user) => (
							<MenuItem key={user._id} value={user._id}>
								{user.username}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 180 }}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateRangePicker
							startText="Start Date"
							endText="End Date"
							value={dateRange}
							onChange={(newValue) => {
								setDateRange(newValue);
							}}
							renderInput={(startProps, endProps) => (
								<React.Fragment>
									<TextField {...startProps} />
									<Box sx={{ mx: 2 }}> to </Box>
									<TextField {...endProps} />
								</React.Fragment>
							)}
						/>
					</LocalizationProvider>
				</FormControl>
				<Button type="submit">Update</Button>
				<Button color="error" onClick={resetFilters}>
					Reset
				</Button>
			</form>
		</>
	);
};

export default TasksFilter;
