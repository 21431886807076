import { Button, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import AddBrand from '../components/settings/AddBrand';
import BrandsTable from "../components/settings/BrandsTable";
import EditBrand from '../components/settings/EditBrand';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { newBrand, updateBrand } from '../redux/settings';
import ModalForm from '../components/settings/ModalForm';
import SnackBar from '../components/SnackBar';

const Settings = () => {
    const settings = useSelector(({ settings }) => settings);
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([settings]);
    const [showForm, setShowForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [currentBrand, setCurrentBrand] = useState();
    const [showLoader, setShowLoader] = useState(false);

    const [brand, setBrand] = useState({
        brandName: '',
        brandID: '',
        brandAddress: '',
        brandEmail: '',
        brandPhoneNumber: '',
        brandWebsite: '',
        brandIsActive: true
    });
    const [selectedLogo, setSelectedLogo] = useState('');
    const [selectedSignature, setSelectedSignature] = useState('');
    const [filePriceOne, setFilePriceOne] = useState('');
    const [filePriceTwo, setFilePriceTwo] = useState('');

    //snackbar
    const [showAlert, setShowAlert] = useState(false);
    const [isError, setIsError] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    //modal
    const [showModal, setShowModal] = useState(false);

    const inputHandler = (e) => {
        const value = e.target.value;
        if (e.target.name === 'brandLogo') {
            setSelectedLogo(e.target.files[0])
        }
        else if (e.target.name === 'brandSignatureUrl') {
            setSelectedSignature(e.target.files[0])
        } else if (e.target.name === 'filePriceOne') {
            setFilePriceOne(e.target.files[0])
        } else if (e.target.name === 'filePriceTwo') {
            setFilePriceTwo(e.target.files[0])
        }
        else {
            setBrand({ ...brand, [e.target.name]: value })
        }
    }

    const addBrandHandler = (e) => {
        setShowLoader(true)
        e.preventDefault();

        dispatch(newBrand({ brand, selectedLogo, selectedSignature, filePriceOne, filePriceTwo }))
            .unwrap(unwrapResult)
            .then(() => {
                setIsError(false);
                setAlertMessage('Success');
                setShowAlert(true);

                cancel();

                setShowLoader(false)
            }).catch(err => {
                console.log(err)
                setIsError(true);
                setAlertMessage('Faild to create a new brand');
                setShowAlert(true);
            }
            )
    };

    const editBrandHandler = (e) => {
        setShowLoader(true)
        e.preventDefault();
        dispatch(updateBrand({ brand: { ...brand, id: currentBrand[0]._id }, selectedLogo, selectedSignature, filePriceOne, filePriceTwo }))
            .unwrap(unwrapResult)
            .then(() => {
                setIsError(false);
                setAlertMessage('Success');
                setShowAlert(true);

                cancel();

                setShowLoader(false)
            }).catch(err => {
                console.log(err)
                setIsError(true);
                setAlertMessage('Faild to edit the brand');
                setShowAlert(true);
            })
    };

    const cancel = () => {
        setShowForm(false)
        setShowEditForm(false)
        setBrand({
            brandName: '',
            brandID: '',
            brandAddress: '',
            brandPhoneNumber: '',
            brandWebsite: '',
        });
        setSelectedLogo('')
        setSelectedSignature('')
        setFilePriceOne('')
        setFilePriceTwo('')
    }

    const editBrandClick = (id) => {
        setShowForm(false)
        setCurrentBrand(id && settings.filter(el => el._id === id))
        setShowEditForm(true)
    }

    const activeClickHandler = (id) => {
        setShowForm(false)
        setShowEditForm(false)
        setCurrentBrand(id && settings.filter(el => el._id === id))
        setShowModal(true)
    }

    const toggleActiveBrand = () => {
        setShowLoader()
        setBrand({ ...brand, brandIsActive: !brand.brandIsActive })
        dispatch(updateBrand({ brand: { ...brand, id: currentBrand[0]._id, brandIsActive: !brand.brandIsActive }, selectedLogo, selectedSignature, filePriceOne, filePriceTwo }))
            .unwrap(unwrapResult)
            .then(() => {
                setIsError(false);
                setAlertMessage('Success');
                setShowAlert(true);

                cancel();

                setShowLoader(false)
            }).catch(err => {
                console.log(err)
                setIsError(true);
                setAlertMessage('Faild to edit the brand');
                setShowAlert(true);
            })
        setShowModal(false)
    }

    useEffect(() => {
        if (currentBrand) {
            setBrand({
                brandName: currentBrand && currentBrand[0].brandName,
                brandID: currentBrand && currentBrand[0].brandID,
                brandAddress: currentBrand && currentBrand[0].brandAddress,
                brandEmail: currentBrand && currentBrand[0].brandEmail,
                brandPhoneNumber: currentBrand && currentBrand[0].brandPhoneNumber,
                brandWebsite: currentBrand && currentBrand[0].brandWebsite,
                brandIsActive: currentBrand && currentBrand[0].brandIsActive,
            })
        }
    }, [currentBrand])

    useEffect(() => {
        setShowLoader(true)
        setTableData(settings)
        setShowLoader(false)
    }, [settings])

    return (
        <Box m={2} pb={4}>
            <h1>Settings</h1>
            <Box mb='2rem'>
                <Button variant='contained' onClick={() => setShowForm(!showForm)}>Add a new brand</Button>
            </Box>
            {showLoader
                ? <Loader />
                : <BrandsTable
                    settings={tableData}
                    editHandler={editBrandClick}
                    activeClickHandler={activeClickHandler}
                />
            }
            {showForm &&
                <AddBrand
                    brand={brand}
                    selectedLogo={selectedLogo}
                    selectedSignature={selectedSignature}
                    filePriceOne={filePriceOne}
                    filePriceTwo={filePriceTwo}
                    inputHandler={inputHandler}
                    addBrandHandler={addBrandHandler}
                    cancel={cancel}
                />
            }
            {showEditForm &&
                <EditBrand
                    brand={brand}
                    selectedLogo={selectedLogo}
                    selectedSignature={selectedSignature}
                    filePriceOne={filePriceOne}
                    filePriceTwo={filePriceTwo}
                    inputHandler={inputHandler}
                    editBrandHandler={editBrandHandler}
                    cancel={cancel}
                    currentBrand={currentBrand[0]}
                />
            }
            {showModal &&
                <ModalForm
                    currentBrand={currentBrand}
                    toggleActiveBrand={toggleActiveBrand}
                    close={() => setShowModal(false)}
                />
            }
            {showAlert && <SnackBar
                open={showAlert}
                setOpen={setShowAlert}
                message={alertMessage}
                severity={isError ? 'error' : 'success'}
            />
            }
        </Box>
    );
};

export default Settings;
