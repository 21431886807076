import { configureStore } from '@reduxjs/toolkit';
import workers from './workers';
import projects from './projects';
import contracts from './contracts';
import advot from './advot';
import mocks from './mocks';
import milestones from './milestones';
import mounth from './mounth';
import companies from './companies';
import login from './login';
import settings from './settings';
import tasks from './tasks';
import fixpricing from './fixpricing';
import insidepricing from './insidepricing';
import extrapayments from './extrapayments';
import pptc from './pptc';
import designdev from './designdev';
import generaldata from './general';
import projecttotal from './projectstotals';

export const store = configureStore({
  reducer: {
    workers,
    projects,
    counter: contracts,
    milestone: milestones,
    fixpricing: fixpricing,
    projecttotal: projecttotal,
    extrapayments: extrapayments,
    designdev: designdev,
    generaldata: generaldata,
    pptc: pptc,
    insidepricing: insidepricing,
    adva: advot,
    jsonElement: mocks,
    mounth,
    companies,
    login,
    settings,
    tasks,
  },
});
