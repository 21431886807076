import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  extraPayments: [
    {
      addonApproveDate: '',
      updatedAt: '',
      approveCustomerName: '',
      approveMailLink: '',
      customerPriceSum: '',
      innerHoursToBeDone: '',
      insidesOut: 'לא',
      insidesPaid: 'לא',
      nameOfAddon: '',
      paymentType: '',
      finished: 'לא',
      invoiceNumber: '',
    },
  ],
};

export const extraPaymentsSlice = createSlice({
  name: 'extrapayments',
  initialState,
  reducers: {
    extrapaymentsPush: (state, action) => {
      state.extraPayments = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { extrapaymentsPush } = extraPaymentsSlice.actions;

export default extraPaymentsSlice.reducer;
