import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { fetchCompanies } from './redux/companies';
import { fetchProjects } from './redux/projects';
import { fetchWorkers } from './redux/workers';
import { fetchSettings } from './redux/settings';
import Layout from './components/Layout';
import routes from './routes';
import Login from './routes/Login';
import { loginFromCookie } from './redux/login';
import { getUserFromCookie } from './cookies/cookies';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(({ login }) => login);

  useEffect(() => {
    const user = getUserFromCookie();
    if (user) {
      dispatch(loginFromCookie(user));
      navigate('/');
    }
  }, []);

  useEffect(() => {
    dispatch(fetchWorkers());
    dispatch(fetchCompanies());
    dispatch(fetchProjects());
    dispatch(fetchSettings());
  }, [dispatch]);

  return (
    <>
      {userData.user ? (
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Layout />}>
            {routes.map(({ path, Element, name, children = null }) => (
              <Route key={name} path={path} element={<Element />}>
                {children &&
                  children.map(({ path, Element, name }) => (
                    <Route key={name} path={path} element={<Element />} />
                  ))}
              </Route>
            ))}
            <Route path="*" element={<h1>404: Page not found</h1>} />
          </Route>
        </Routes>
      )}
    </>
  );
}

export default App;
