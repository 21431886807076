import React from 'react';
import { useSelector } from 'react-redux';
import BookKeeper from '../components/bookkeeping/BookKeeping';

const BookKeeping = () => {
  // const contracts = useSelector(({ contracts }) => contracts);
  // console.log(contracts);
  // if (contracts.length === 0) return <div>Loading...</div>;
  return (
    <>
      {/* <Table properties={Object.keys(contracts[0])} values={contracts} /> */}
      <BookKeeper />
    </>
  );
};

export default BookKeeping;
