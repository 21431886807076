import { Button } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { DateRangePicker } from 'materialui-daterange-picker';
import CsvDownload from 'react-json-to-csv';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AlertDialog from '../components/AlertDialog';
import Dropdown from '../components/Dropdown';
import AddProject from '../components/projects/AddProject';
import Dashboard from '../components/projects/Dashboard';
import ProjectTable from '../components/projects/ProjectTable';
import { deleteProject } from '../redux/projects';
import { parseSecondsToTime, timeSpent, timeEst } from '../utils/projects';
import {
  updateContract,
  getContract,
  newContract,
  projectContractBudgetPush,
} from '../redux/contracts';
import {
  convertTasksToCsvData,
  filterByThis,
  filterByLast,
} from '../utils/projects';
import { editData, getData } from '../api/data';
import SnackBar from '../components/SnackBar';
import { projectsEntities } from '../redux/projects';
import { workersEntities } from '../redux/workers';
import SecondProject from '../components/projects/SecondProject';
import { chainPropTypes } from '@mui/utils';
import { projectTotalsPush } from '../redux/projectstotals';
import ContractCalculator from '../components/projects/ContractCalculator';

const pitangoTableTitles = [
  '#',
  'Username',
  'Date',
  'Task name',
  'Description',
  'Time tracked',
];

const ynaTableTitles = [
  '#',
  'Username',
  'Date',
  'Task name',
  'Description',
  'Time tracked',
  'Estimated',
];
const dropdownList = [
  'This week',
  'Last week',
  'This two weeks',
  'Last two weeks',
  'This month',
  'Last month',
  'This quarter',
  'Last quarter',
  'This year',
  'Last year',
  'All time',
];

const Project = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [designTasks, setDesignTasks] = useState([]);
  const [developmentTasks, setDevelopmentTasks] = useState([]);
  const [designTasksClone, setDesignTasksClone] = useState([]);
  const [developmentTasksClone, setDevelopmentTasksClone] = useState([]);
  const { users } = useSelector(workersEntities);
  const { projects } = useSelector(projectsEntities);

  const project = projects.find((el) => el._id === id);
  const [secondProject, setSecondProject] = useState(null);

  const [filteredTasks, setFilteredTasks] = useState(project?.tasks || []);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isEditProjectOpen, setIsEditProjectOpen] = useState(false);
  const [isDeleteProjectDialogOpen, setIsDeleteProjectDialogOpen] =
    useState(false);

  const [csvData, setCsvData] = useState([]);
  const [designNormalizeFactor, setDesignNormalizeFactor] = useState();
  const [developmentNormalizeFactor, setDevelopmentNormalizeFactor] =
    useState();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertMessageError, setIsAlertMessageError] = useState(false);

  const onClickToggleIsAddProjectOpen = () => {
    setIsEditProjectOpen(!isEditProjectOpen);
  };

  const onClickToggleIsDeleteProjectOpen = () => {
    setIsDeleteProjectDialogOpen(!isDeleteProjectDialogOpen);
  };

  const toggleDatePicker = () => setIsDatePickerOpen(!isDatePickerOpen);

  const handleDatePickerRange = (range) => {
    setIsDatePickerOpen(false);
    setFilteredTasks(
      project.tasks.filter(
        (task) =>
          moment(task.date).isSameOrAfter(moment(range.startDate)) &&
          moment(task.date).isSameOrBefore(moment(range.endDate).add(1, 'days'))
      )
    );
  };

  const handleDeleteProject = () => {
    setIsDeleteProjectDialogOpen(false);

    dispatch(deleteProject({ id: project._id }))
      .unwrap(unwrapResult)
      .then(() => {
        navigate('/projects');
      })
      .catch((err) => console.log(err));
  };

  const handeUpdateNormalizeFactor = (isDesign = false) => {
    editData(
      'settings',
      isDesign ? { designNormalizeFactor } : { developmentNormalizeFactor }
    )
      .then(() => {
        setAlertMessage('Normalize factor updated sucessfuly');
        setIsAlertMessageError(false);
        setShowAlert(true);
      })
      .catch((err) => {
        console.log(err);
        setAlertMessage('Faild to update Normalize factor');
        setIsAlertMessageError(true);
        setShowAlert(true);
      });
  };

  const onSelectFilterTable = (value) => {
    let tasks;
    if (value.includes('This')) {
      tasks = filterByThis(project, value);
    } else {
      tasks = filterByLast(project, value);
    }

    setFilteredTasks(tasks);
  };

  // useEffect(() => {
  //   getData('settings')
  //     .then(({ designNormalizeFactor, developmentNormalizeFactor }) => {
  //       setDesignNormalizeFactor(designNormalizeFactor);
  //       setDevelopmentNormalizeFactor(developmentNormalizeFactor);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  useEffect(() => {
    let design = [];
    let development = [];

    filteredTasks.forEach((task) => {
      if (task.isActive) return;

      if (task.taskType === 'design') {
        design.push(task);
      } else {
        development.push(task);
      }
    });

    design = design.sort(
      (a, b) => moment(b.date).format('x') - moment(a.date).format('x')
    );
    development = development.sort(
      (a, b) => moment(b.date).format('x') - moment(a.date).format('x')
    );

    setDesignTasks(design);
    setDevelopmentTasks(development);
    if (designTasksClone.length === 0) {
      setDesignTasksClone(design);
      setDevelopmentTasksClone(development);
    }

    if (designNormalizeFactor) {
      setCsvData(
        convertTasksToCsvData(
          project,
          users,
          design,
          development,
          designNormalizeFactor,
          developmentNormalizeFactor
        )
      );
    }
  }, [filteredTasks, designNormalizeFactor]);

  if (!project) return <div></div>;
  let projectCompanyName;
  if(project.company !== null){
    projectCompanyName = project.company.name; 
  } else {
    projectCompanyName = 'NoName'; 
  }
  return (
    
    <div className="project container">
      <h1>Project details</h1>
       
      {!!project.company && (
        <h2>Company: {projectCompanyName}</h2>
      )}
      <h3>Project: {project.name}</h3>
      <h4>Type: {project.isFixed ? 'Fixed' : 'Hourly'}</h4>
      {/* <SecondProject
        secondProject={secondProject}
        setSecondProejct={setSecondProject}
      /> */}
      <div className="edit-project">
        <Button
          onClick={onClickToggleIsAddProjectOpen}
          type="button"
          variant="contained"
        >
          Edit project
        </Button>
        <Button
          onClick={onClickToggleIsDeleteProjectOpen}
          type="button"
          variant="contained"
          style={{
            backgroundColor: 'red',
          }}
        >
          Delete project
        </Button>

        <AlertDialog
          isOpen={isDeleteProjectDialogOpen}
          setIsOpen={setIsDeleteProjectDialogOpen}
          title={'Delete project'}
          text={`Are you sure you want to delete project ${project.name}`}
          handleYes={handleDeleteProject}
        />

        {isEditProjectOpen && (
          <AddProject
            setIsOpen={setIsEditProjectOpen}
            isEditMode={true}
            project={project}
          />
        )}
      </div>
      <ContractCalculator
        designTasks={designTasksClone}
        developmentTasks={developmentTasksClone}
      />
      <Dashboard project={project} />
      <div className="tables">
        {!!project.tasks && (
          
          <>
            <Dropdown list={dropdownList} onChange={onSelectFilterTable} />

            <DateRangePicker
              open={isDatePickerOpen}
              toggle={toggleDatePicker}
              closeOnClickOutside={true}
              onChange={(range) => handleDatePickerRange(range)}
            />
            <button onClick={toggleDatePicker}>Pick range</button>

            <button onClick={() => setFilteredTasks(project.tasks)}>
              Reset
            </button>

            <CsvDownload
              target="_blank"
              filename={`${projectCompanyName} - ${project.name}.csv`}
              data={csvData}
            >
              Export csv
            </CsvDownload>
          </>
        )}

        {!!designTasks.length && (
          <ProjectTable
            title="Design"
            titles={pitangoTableTitles}
            tasks={designTasks}
            normalizeFactor={designNormalizeFactor}
            setNormalizeFactor={setDesignNormalizeFactor}
            handeUpdateNormalizeFactor={handeUpdateNormalizeFactor}
          />
        )}
        {!!developmentTasks.length && (
          <ProjectTable
            title="Development"
            titles={ynaTableTitles}
            tasks={developmentTasks}
            normalizeFactor={developmentNormalizeFactor}
            setNormalizeFactor={setDevelopmentNormalizeFactor}
            handeUpdateNormalizeFactor={handeUpdateNormalizeFactor}
          />
        )}
      </div>
      <SnackBar
        open={showAlert}
        setOpen={setShowAlert}
        message={alertMessage}
        severity={isAlertMessageError ? 'error' : 'success'}
      />
    </div>
  );
};

export default Project;
