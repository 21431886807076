import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../components/Table';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import AddProject from '../components/projects/AddProject';
import { projectsEntities } from '../redux/projects';
import Spinner from "../components/Spinner"

const tableTitles = ['Company', 'Project', 'Total Hours', 'Spent', 'Remaining'];

const Projects = () => {
  const { projects, isLoading } = useSelector(projectsEntities);
  const [projectsTableData, setProjectsTableData] = useState([]);

  const params = useParams();
  const navigate = useNavigate();


  const [isAddProjectOpen, setIsAddProjectOpen] = useState(false);

  const onClickToggleIsAddProjectOpen = () => {
    setIsAddProjectOpen(!isAddProjectOpen);
  };

  const onClickNavigate = (id) => {
    navigate(id, { project: projectsTableData });
  };

  useEffect(() => {
    setProjectsTableData(projects.map((project) => project.projectTableData));
  }, [projects.length]);

  if (isLoading) return <Spinner />;
  return !!params.id ? (
    <Outlet projectsData={projectsTableData} />
  ) : (
    <Box m={2} pb={4}>
      <h1>Projects</h1>
      <Box mb={2}>
        <Button
          onClick={onClickToggleIsAddProjectOpen}
          type='button'
          variant='contained'
        >
          + New project
        </Button>
      </Box>
      {isAddProjectOpen && <AddProject setIsOpen={setIsAddProjectOpen} />}
      <Table properties={tableTitles} values={projectsTableData} onRowClicked={onClickNavigate} />
    </Box>
  );
};

export default Projects;
