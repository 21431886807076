import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getData, addData, editData, deleteData } from '../api/data';
import { addProjectTableData } from '../utils/projects';

export const fetchProjects = createAsyncThunk('projects/fetch', async () => {
	try {
		const data = await getData('project/all');

		return data
			.map((project) => addProjectTableData(project))
			.sort((a, b) =>
				a.projectTableData.companyName.toLowerCase() >
				b.projectTableData.companyName.toLowerCase()
					? 1
					: -1,
			);
	} catch (e) {
		console.log(e.message);
		throw new Error(e.message);
	}
});

export const addProjects = createAsyncThunk(
	'projects/add',
	async (projects) => {
		try {
			const DBprojects = [];
			for (let project of projects) {
				const proj = await addData('project/new', { name: project });
				DBprojects.push(proj);
			}
			return DBprojects;
		} catch (err) {
			throw new Error(err.message);
		}
	},
);

export const addProject = createAsyncThunk(
	'projects/addOne',
	async (project) => {
		try {
			const data = await addData('project/new', project);

			return data;
		} catch (err) {
			throw new Error(err.message);
		}
	},
);

export const editProject = createAsyncThunk('project/edit', async (project) => {
	try {
		const data = await editData('project/edit', project);

		return data;
	} catch (err) {
		throw new Error(err.message);
	}
});

export const deleteProject = createAsyncThunk(
	'project/delete',
	async (projectId) => {
		try {
			const data = await deleteData('project/delete', projectId);

			return data;
		} catch (err) {
			throw new Error(err.message);
		}
	},
);

const initialState = {
	loading: 'pending',
	data: [],
};

const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		// addProject: (state, { payload }) => {
		//   return [...state, payload];
		// },
		addProjectsData: (state, { payload }) => {
			return [...payload];
		},
	},
	extraReducers: {
		[fetchProjects.pending]: (state) => {
			state.loading = 'pending';
		},
		[fetchProjects.fulfilled]: (state, { payload }) => {
			state.loading = 'idle';
			state.data = payload;
		},
		[fetchProjects.rejected]: () => {
			console.error('fetch projects rejected!');
		},
		[addProject.fulfilled]: (state, { payload }) => {
			payload = addProjectTableData(payload);
			state.data = [...state.data, payload].sort((a, b) =>
				a.company.name.toLowerCase() > b.company.name.toLowerCase()
					? 1
					: -1,
			);
		},
		[addProject.rejected]: () => {
			console.error('add project rejected!');
		},
		[editProject.fulfilled]: (state, { payload }) => {
			const newState = [...current(state.data)];
			payload = addProjectTableData(payload);

			const projectIndex = current(state.data).findIndex(
				(project) => project._id === payload._id,
			);
			newState[projectIndex] = payload;
			state.data = newState;
		},
		[editProject.rejected]: () => {
			console.error('edit project rejected!');
		},
		[deleteProject.fulfilled]: (state, { payload }) => {
			let newState = [...current(state.data)];
			const projectIndex = current(state.data).findIndex(
				(project) => project._id === payload.id,
			);
			newState.splice(projectIndex, 1);
			state.data = newState;
		},
		[deleteProject.rejected]: () => {
			console.error('delete project rejected!');
		},
	},
});

const { reducer, actions } = projectsSlice;

export const projectsEntities = ({ projects }) => {
	const isLoading = projects.loading === 'pending';
	return { projects: projects.data, isLoading };
};

export const { addProjectsData } = actions;

export default reducer;
