import TableChartIcon from '@mui/icons-material/TableChartOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import Employees from './routes/Employees';
import Companies from './routes/Companies';
import Contracts from './routes/Contracts';
import BookKeeping from './routes/BookKeeping';
import Projects from './routes/Projects';
import Project from './routes/Project';
import Root from './routes/Root';
import Employee from './routes/Employee';
import Settings from './routes/Settings';
import Tasks from './routes/Tasks';

const routes = [
  {
    name: 'Future Months',
    Icon: TableChartIcon,
    path: '/',
    Element: Root,
  },
  {
    name: 'Tasks',
    Icon: AssignmentIcon,
    path: '/tasks',
    Element: Tasks
  },
  {
    name: 'Projects',
    Icon: EventNoteIcon,
    path: '/projects',
    Element: Projects,
    children: [
      {
        name: 'Project',
        Icon: null,
        path: ':id',
        Element: Project,
      },
    ],
  },
  {
    name: 'Employees',
    Icon: GroupOutlinedIcon,
    path: '/employees',
    Element: Employees,
    children: [
      {
        name: 'Employee',
        Icon: null,
        path: ':id',
        Element: Employee,
      },
    ],
  },
  // {
  //   name: 'Companies',
  //   Icon: AccountTreeOutlinedIcon,
  //   path: '/companies',
  //   Element: Companies,
  // },
  {
    name: 'Contracts',
    Icon: GavelIcon,
    path: '/contracts',
    Element: Contracts,
  },
  {
    name: 'BookKeeping',
    Icon: AccountBalanceWalletIcon,
    path: '/bookKeeping',
    Element: BookKeeping,
  },
  {
    name: 'Settings',
    Icon: SettingsIcon,
    path: '/settings',
    Element: Settings,
  },
];

export default routes;
