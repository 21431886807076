import React from 'react';
import { useSelector } from 'react-redux';
import { selectMonth } from '../../../redux/mounth';
import Cell from '../Cell';

const HoursRow = ({ title, value, disabled, updateState, text, cols }) => {
	const { workers } = useSelector(selectMonth);
	return (
		<div className="row" style={{ border: 'none' }}>
			<div
				style={{
					gridColumn: 'span 2',
					borderRight: !!text && '1px solid #ccc',
				}}
			></div>
			<div
				style={{
					gridColumn: 'span 3',
					border: !!text && '1px solid #ccc',
					alignItems: 'flex-end',
				}}
			>
				{!!text && text[0]}
			</div>
			<div
				style={{
					gridColumn: 'span 3',
					border: !!text && '1px solid #ccc',
				}}
			>
				{!!text && text[1]}
			</div>

			<div
				style={{
					gridColumn: 'span 2',
					fontSize: '0.8rem',
					wordWrap: 'wrap',
					border: '1px solid #ccc',
				}}
			>
				{title}
			</div>
			{!!value &&
				!!workers &&
				workers.map((worker, i) => (
					<Cell
						key={worker.worker}
						style={{
							border: '1px solid #ccc',
						}}
						value={value[i] || 0}
						disabled={disabled}
						id={worker.worker}
						updateState={updateState}
					/>
				))}
		</div>
	);
};

export default HoursRow;
