import { FormControl, Select, MenuItem, Chip, Grid } from '@mui/material';
import { useState } from 'react';

const SelectTeam = ({
  users,
  team,
  setTeam,
  teamRole,
  onClickRemoveTeamMember,
}) => {
  const [allUsers] = useState(
    users
      .filter((user) =>
        teamRole === 'Designer'
          ? user.role === 'Designer'
          : user.role !== 'Designer'
      )
      .map((user) => user._id)
  );

  return (
    <div className='team'>
      <Grid container>
        <Grid item xs={12} md={3}>
          <FormControl>
            <h4>{teamRole === 'Designer' ? 'Design' : 'Other'} team members</h4>
            {users.length && (
              <Select
                value={team}
                onChange={(e) => {
                  if (e.target.value.includes('all')) {
                    setTeam(allUsers);
                  } else if (e.target.value.includes('unselectAll')) {
                    setTeam([]);
                  } else {
                    setTeam(e.target.value);
                  }
                }}
                multiple
              >
                <MenuItem value='all'>Select all</MenuItem>
                <MenuItem value='unselectAll'>Unselect all</MenuItem>
                {users
                  .filter((user) =>
                    teamRole === 'Designer'
                      ? user.role === 'Designer'
                      : user.role !== 'Designer'
                  )
                  .map((user) => (
                    <MenuItem selected={true} key={user._id} value={user._id}>
                      {user.username}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={9} display='flex' alignItems='flex-end'>
          <div className='team-member-list'>
            {team &&
              team.map((teamMember) => (
                <Chip
                  key={teamMember}
                  variant='filled'
                  label={users.find((user) => user._id === teamMember).username}
                  onDelete={() =>
                    onClickRemoveTeamMember(teamMember, team, setTeam)
                  }
                  sx={{
                    '& .MuiChip-deleteIcon': {
                      color: '#607d8b',
                    },
                    '&:hover': {
                      "& .MuiChip-deleteIcon": {
                        color: '#455a64'
                      }
                    }
                  }}
                />
              ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectTeam;
